import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { TransactionOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import { useTranslation } from 'react-i18next';

import { getUserDetails, validateCui } from '../services/users';
import useDictionaries from '../hooks/useDictionaries';
import { getCountries } from '../services/taxonomies';

const dictionaries = {
  countries: () =>
    getCountries({
      pageSize: 200,
      sort: ['name', 'asc'],
      criterias: { deleted: 'false' },
    }),
};

const ChoseBillingModal = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const options = [
    { label: t('entity.users.customerTypes.pf'), value: 'PF' },
    { label: t('entity.users.customerTypes.pj'), value: 'PJ' },
  ];
  const [showModal, setShowModal] = useState(false);
  const [vatEntity, setVatEntity] = useState(null);
  const [identityCard, setIdentityCard] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [activeKeys, setActiveKey] = useState(['address2']);
  const [country, setCountry] = useState(null);
  const [{ countries }] = useDictionaries(dictionaries);
  const { Text } = Typography;
  const { setFieldsValue, resetFields } = form;
  const [invoiceType, setInvoiceType] = useState(options[0].value);

  const districtOptions = useMemo(
    () =>
      (
        (countries?.content || []).find((c) => c.id === country)?.districts ||
        []
      ).map((ety) => ({
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries?.content, country],
  );

  const countryOptions = useMemo(
    () =>
      (countries?.content || []).map((ety) => ({
        ...ety,
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries],
  );

  const validateIdentityCard = useCallback(() => {
    const controlNumber = identityCard % 10;
    let cif = Math.floor(identityCard / 10);
    let key = 753217532;
    let sum = 0;
    while (key > 0) {
      sum += (key % 10) * (cif % 10);
      cif = Math.floor(cif / 10);
      key = Math.floor(key / 10);
    }
    const currentControlNumber = ((sum * 10) % 11) % 10;
    return currentControlNumber === controlNumber;
  }, [identityCard]);

  const validate = useCallback(() => {
    if (identityCard) {
      if (
        (countryCode === 'RO' || countryCode === '') &&
        !validateIdentityCard()
      ) {
        message.error({
          content: t('errors.invalidIdentityCard'),
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
        setVatEntity(null);
        resetFields();
        return;
      }
      if (countryCode !== '') {
        validateCui(countryCode, identityCard)
          .then((resp) => {
            const { isValid, companyName } = resp;
            if (isValid) {
              setFieldsValue({ fullname: companyName });
            } else {
              notification.error({
                message: t(`errors.taxDataCouldNotBeVerified`),
              });
            }
            setVatEntity(resp);
          })
          .catch((err) => {
            setVatEntity(null);
            resetFields();
            if (err?.inner) {
              if (err?.inner?.status === 400) {
                notification.error({
                  message: t(`errors.identificationCodeNotFound`),
                });
              }
            } else {
              err
                .json(err)
                .then((res) => notification.error({ message: res?.message }));
            }
          });
      } else {
        setVatEntity({ isValid: false });
      }
    }
  }, [
    identityCard,
    countryCode,
    validateIdentityCard,
    t,
    setFieldsValue,
    resetFields,
  ]);

  return (
    <>
      <Button
        block
        className="ant-btn-success"
        type="primary"
        onClick={() => setShowModal(true)}
        icon={<TransactionOutlined />}
      >
        {t('actions.orders.paid')}
      </Button>
      <Modal
        title={t('entity.stocks.invoice.generateInvoice')}
        visible={showModal}
        closable={false}
        width={500}
        footer={[
          (invoiceType === 'PF' || vatEntity != null) && (
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                if (invoiceType === 'PF') {
                  getUserDetails().then((result) => {
                    if (
                      result.address2 &&
                      result.address2.countryId &&
                      result.address2.districtId &&
                      result.address2.locality
                    ) {
                      onSubmit(null);
                      setShowModal(false);
                    } else {
                      message.error({
                        content: (
                          <>
                            <p>{t('errors.requiredAddress')}</p>
                            <a href="/profile">
                              {t('entity.users.userProfile')}
                            </a>
                          </>
                        ),
                        duration: 5,
                        className: 'card-message',
                      });
                    }
                  });
                } else {
                  form.validateFields().then((res) => {
                    onSubmit(res);
                    setShowModal(false);
                  });
                }
              }}
            >
              {t('actions.toPayment')}
            </Button>
          ),
          <Button
            key="close"
            type="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t(`actions.close`)}
          </Button>,
        ]}
      >
        {t('entity.stocks.invoice.selectInvoiceType')}{' '}
        <div>
          <Radio.Group
            value={invoiceType}
            defaultValue={invoiceType}
            options={options}
            onChange={(e) => {
              setInvoiceType(e?.target.value);
              resetFields();
              setVatEntity(null);
            }}
          />
        </div>
        {invoiceType === 'PJ' && (
          <div style={{ marginTop: '16px' }}>
            {t('entity.stocks.invoice.enterCui')}
            <Form form={form}>
              <Row>
                <Col span={7}>
                  <FormItem name="countryCode">
                    <Select
                      onChange={(val) => {
                        setVatEntity(null);
                        setCountryCode(val);
                      }}
                    >
                      {[{ code: 'Neplatitor TVA' }, ...countryOptions].map(
                        ({ code }) => (
                          <Select.Option
                            key={code}
                            value={code !== 'Neplatitor TVA' ? code : ''}
                          >
                            {code}
                          </Select.Option>
                        ),
                      )}{' '}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={11}>
                  <FormItem
                    name="identityCard"
                    rules={[
                      {
                        required: true,
                        message: t('errors.requiredField'),
                      },
                      {
                        type: 'string',
                        min: 1,
                        message: t('errors.invalidField'),
                      },
                    ]}
                  >
                    <Input
                      onChange={(v) => {
                        const { value } = v.target;
                        setIdentityCard(value);
                        setFieldsValue({ identityCard: value });
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Button
                    style={{
                      width: '100%',
                    }}
                    type="primary"
                    onClick={validate}
                  >
                    {t('actions.validate')}
                  </Button>
                </Col>
              </Row>

              {vatEntity && (
                <>
                  {
                    (vatEntity.isValid && (
                      <Row>
                        <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                          {vatEntity.companyName}
                        </Text>
                      </Row>
                    ),
                    (
                      <Row>
                        <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                          {vatEntity.companyAddress}
                        </Text>
                      </Row>
                    ))
                  }
                  <Row>
                    <Form.Item
                      name="fullname"
                      label={t('entity.users.fullName')}
                      style={{ width: '100%' }}
                      rules={[
                        {
                          required: true,
                          message: t('errors.notEmpty'),
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          setFieldsValue({
                            fullname: e.target.value.toUpperCase(),
                          });
                        }}
                      />
                    </Form.Item>
                  </Row>

                  <Collapse
                    activeKey={activeKeys}
                    onChange={() =>
                      setActiveKey(activeKeys ? null : 'address2')
                    }
                  >
                    <CollapsePanel key="address2">
                      <Form.Item
                        name="countryId"
                        label={t('entity.users.address.country')}
                        rules={[
                          {
                            required: true,
                            message: t('errors.mustSelected'),
                          },
                        ]}
                      >
                        <Select
                          options={countryOptions}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(v1) => {
                            setCountry(v1);
                            const value = countries?.content?.filter(
                              (c) => c.id === country,
                            )[0]?.code;
                            if (value === 'RO') {
                              form.setFieldsValue({
                                district: null,
                              });
                            } else {
                              form.setFieldsValue({
                                districtId: null,
                              });
                            }
                          }}
                        >
                          {countryOptions.map((opt) => (
                            <Select.Option key={opt.id} value={opt.id}>
                              {opt.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {countries?.content?.filter((c) => c.id === country)[0]
                        ?.code === 'RO' ? (
                        <Form.Item
                          name="districtId"
                          label={t('entity.users.address.districtId')}
                          rules={[
                            {
                              required: true,
                              message: t('errors.mustSelected'),
                            },
                          ]}
                        >
                          <Select
                            options={districtOptions}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="district"
                          label={t('entity.users.address.district')}
                          rules={[
                            {
                              required: true,
                              message: t('errors.mustSelected'),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                      <Form.Item
                        name="locality"
                        label={t('entity.users.address.locality')}
                        rules={[
                          {
                            required: true,
                            message: t('errors.notEmpty'),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="street"
                        label={t('entity.users.address.street')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="number"
                        label={t('entity.users.address.number')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="building"
                        label={t('entity.users.address.building')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="apartment"
                        label={t('entity.users.address.apartment')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="details"
                        label={t('entity.users.address.details')}
                      >
                        <Input />
                      </Form.Item>
                    </CollapsePanel>
                  </Collapse>
                </>
              )}
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ChoseBillingModal;
