import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const DeleteItemIcon = ({
  title,
  message,
  item,
  action,
  reload,
  disabled = false,
  size = 'middle',
  type = 'link',
  showIcon = true,
  style = {
    color: disabled ? '#ccc' : '#fafafa',
    background: disabled ? '#f0f0f0' : '#1890ff',
    border: disabled ? '1px solid #ccc' : 'none',
    marginRight: 10,
  },
  okText = 'actions.delete',
  actionName = 'actions.delete',
}) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    action(item)
      .then(() => message && notification.success({ message }))
      .finally(() => reload());
  }, [action, item, message, reload]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      disabled={disabled}
      onConfirm={handleDelete}
      okText={t(okText)}
      cancelText={t('actions.cancel')}
    >
      <Tooltip title={t(actionName)}>
        {showIcon ? (
          <Button
            shape="circle"
            type={type}
            icon={<DeleteOutlined />}
            disabled={disabled}
            size={size}
          />
        ) : (
          <Button
            shape="square"
            type={type}
            disabled={disabled}
            size="middle"
            style={style}
          >
            {t(actionName)}
          </Button>
        )}
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteItemIcon;
