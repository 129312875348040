export const BULK_TYPES = {
  ISSUING_CARDS: 'ISSUING_CARDS',
  RECHARGE_CARDS: 'RECHARGE_CARDS',
  // DISABLE_CARDS: 'DISABLE_CARDS',
};

export const USER_TYPES = {
  STUDENT: 'STUDENT',
  RETIRED_CONVENTION: 'RETIRED_CONVENTION',
  RETIRED_NO_CONVENTION: 'RETIRED_NO_CONVENTION',
  // DISABLE_CARDS: 'DISABLE_CARDS',
};

export const COMMAND_TYPES = [{ id: 'INTERNAL' }, { id: 'EXTERNAL' }];

export const ORDER_STATUS = {
  SELECT_PRODUCTS: 'SELECT_PRODUCTS',
  DOC_VALIDATION: 'DOC_VALIDATION',
  PAYMENT: 'PAYMENT',
  PAID: 'PAID',
  CUSTOMIZE_CARD: 'CUSTOMIZE_CARD',
  PRINTING: 'PRINTING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  PICKED_UP: 'PICKED_UP',
  OP_VALIDATION: 'OP_VALIDATION',
  PAYMENT_DUE: 'PAYMENT_DUE',
};

export const CUSTOMER_TYPE = {
  LEGAL: 'LEGAL',
  INDIVIDUAL: 'INDIVIDUAL',
  PAN: 'PAN',
};

export const OrderTypes = {
  content: [
    { id: 'SELECT_PRODUCTS' },
    { id: 'DOC_VALIDATION' },
    { id: 'PAYMENT' },
    { id: 'CUSTOMIZE_CARD' },
    { id: 'PRINTING' },
    { id: 'CONFIRMED' },
    { id: 'REJECTED' },
    { id: 'DELETED' },
    { id: 'PAID' },
    { id: 'PAYMENT_DUE' },
    { id: 'OP_VALIDATION' },
  ],
  loading: false,
  error: false,
};

export const BADGE_COLORS = {
  SELECT_PRODUCTS: 'pink',
  DOC_VALIDATION: 'red',
  PAYMENT: 'yellow',
  CONFIRMED: 'green',
  CUSTOMIZE_CARD: 'blue',
  PRINTING: 'aqua',
  ACTIVE: 'green',
  DELETED: '#999',
  REJECTED: '#999',
  PICKED_UP: '#f19205',
  PAID: '#be0ed2',
  OP_VALIDATION: '#e70a65',
  PAYMENT_DUE: '#8b4cf8',
};

export const ERP_INVOICE_STATUSES = {
  INITIAL: 'INITIAL',
  RETRY: 'RETRY',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  CANCELED: 'CANCELED',
};
