import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const ReInrolareButton = ({ user }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('actions.changeCard')} key="changeCard">
      <div className="card-button">
        <form
          method="POST"
          action={`${window._env_.API_BACKEND_URL}/portal/api/v1/enroll-card/${user.id}`}
        >
          <input
            type="hidden"
            name="x-tenant-key"
            value={window._env_.API_TENANT_KEY}
          />
          <button type="submit">
            <EditOutlined
              style={{
                fontSize: '3rem',
                color: '#d6f665',
                marginTop: '35%',
              }}
            />
          </button>
        </form>
      </div>
    </Tooltip>
  );
};
export default ReInrolareButton;
