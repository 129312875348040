/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Descriptions, Form, Select, Typography } from 'antd';
import { getRouteGroups } from '../services/accounts';

const LineGroupsRestriction = ({ form, name, line, whereIsRestriction }) => {
  const { t } = useTranslation();
  const [routeGroups, setRouteGroups] = useState([]);
  const [routesAvailability, setRoutesAvailability] = useState(false);
  useEffect(() => {
    getRouteGroups().then((response) => {
      setRouteGroups(
        response.reduce((acc, val) => ({ ...acc, [val.id]: val }), {}),
      );
      setRoutesAvailability(true);
    });
  }, [form, name]);
  const [productIdx, _rest, restrictionIdx] = name;
  const values = form.getFieldsValue([
    ['product', 'payload', productIdx, 'restrictions', restrictionIdx],
  ]);
  const res = values.product.payload[productIdx].restrictions[restrictionIdx];
  const { value1, value2, value3, value4, value5, value6, value7, value8 } =
    res;
  useEffect(() => {
    if (whereIsRestriction.length > 0) {
      const flatRestArr = whereIsRestriction.flat();
      if (flatRestArr.some((val) => val === 'value1')) {
        res.option1 = routeGroups[value2]?.routes.find(
          (el) => el.id === Number(line),
        )?.id;
      } else if (flatRestArr.some((val) => val === 'value3')) {
        res.option2 = routeGroups[value4]?.routes.find(
          (el) => el.id === Number(line),
        )?.id;
      } else if (flatRestArr.some((val) => val === 'value5')) {
        res.option3 = routeGroups[value6]?.routes.find(
          (el) => el.id === Number(line),
        )?.id;
      } else if (flatRestArr.some((val) => val === 'value7')) {
        res.option4 = routeGroups[value8]?.routes.find(
          (el) => el.id === Number(line),
        )?.id;
      }
    }
  }, [
    res,
    line,
    whereIsRestriction,
    routeGroups,
    value2,
    value4,
    value6,
    value8,
    routesAvailability,
  ]);
  return (
    setRoutesAvailability && (
      <Descriptions bordered column={1}>
        {value2 && (
          <Descriptions.Item label={routeGroups[value2]?.name}>
            {value1 === 1 ? (
              <Form.Item
                name={[restrictionIdx, 'option1']}
                rules={[
                  {
                    required: true,
                    message: t('errors.selectLine'),
                  },
                ]}
                noStyle
              >
                <Select
                  disabled={whereIsRestriction
                    .flat()
                    .some((val) => val === 'value1')}
                >
                  {(routeGroups[value2]?.routes || []).map((route) => (
                    <Select.Option value={route.id} key={route.id}>
                      {route.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              'Toate liniile'
            )}
          </Descriptions.Item>
        )}

        {value4 && (
          <Descriptions.Item label={routeGroups[value4]?.name}>
            {value3 === 1 ? (
              <Form.Item
                name={[restrictionIdx, 'option2']}
                rules={[
                  {
                    required: true,
                    message: t('errors.selectLine'),
                  },
                ]}
                noStyle
              >
                <Select
                  disabled={whereIsRestriction
                    .flat()
                    .some((val) => val === 'value3')}
                >
                  {(routeGroups[value4]?.routes || []).map((route) => (
                    <Select.Option value={route.id} key={route.id}>
                      {route.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              'Toate liniile'
            )}
          </Descriptions.Item>
        )}

        {value6 && (
          <Descriptions.Item label={routeGroups[value6]?.name}>
            {value5 === 1 ? (
              <Form.Item
                name={[restrictionIdx, 'option3']}
                rules={[
                  {
                    required: true,
                    message: t('errors.selectLine'),
                  },
                ]}
                noStyle
              >
                <Select
                  disabled={whereIsRestriction
                    .flat()
                    .some((val) => val === 'value5')}
                >
                  {(routeGroups[value6]?.routes || []).map((route) => (
                    <Select.Option value={route.id} key={route.id}>
                      {route.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              'Toate liniile'
            )}
          </Descriptions.Item>
        )}

        {value8 && (
          <Descriptions.Item label={routeGroups[value8]?.name}>
            {value7 === 1 ? (
              <Form.Item
                name={[restrictionIdx, 'option4']}
                rules={[
                  {
                    required: true,
                    message: t('errors.selectLine'),
                  },
                ]}
                noStyle
              >
                <Select
                  disabled={whereIsRestriction
                    .flat()
                    .some((val) => val === 'value7')}
                >
                  {(routeGroups[value8]?.routes || []).map((route) => (
                    <Select.Option value={route.id} key={route.id}>
                      {route.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              'Toate liniile'
            )}
          </Descriptions.Item>
        )}
      </Descriptions>
    )
  );
};

const Restrictions = ({ form, name, line, whereIsRestriction }) => {
  const { t } = useTranslation();
  const [productIdx, _rest, restrictionIdx] = name;

  const values = form.getFieldsValue([
    ['product', 'payload', productIdx, 'restrictions', restrictionIdx],
  ]);

  const res = values.product.payload[productIdx].restrictions[restrictionIdx];
  const { restriction } = res;

  const renderRestrictionComponent = useCallback(
    (r) => {
      switch (r) {
        case 'LINE_GROUPS':
          return (
            <>
              <Typography.Paragraph>
                {t(`entity.offers.restrictions.restriction.${r}._`)}
              </Typography.Paragraph>
              <LineGroupsRestriction
                form={form}
                name={name}
                line={line}
                whereIsRestriction={whereIsRestriction}
              />
            </>
          );
        default:
          return null;
      }
    },
    [form, name, t, line, whereIsRestriction],
  );

  return <>{renderRestrictionComponent(restriction)}</>;
};

export default Restrictions;
