import React, { useEffect, useMemo, useState } from 'react';
import { getPosesByRole } from '../../services/taxonomies';
import FormWrapper from '../FormWrapper';
import { UserTypesEnum } from '../../services/constants';

function PosSelector({ props }) {
  const {
    t,
    select,
    isNew,
    disabled,
    setValue,
    userType,
    userTypes,
    errorMessagePosId: errorMessageStatic,
  } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    getPosesByRole().then((data) => setOptions(data.content));
  }, []);

  const data = useMemo(() => {
    const userTypeCode = userTypes.content.find(
      (ut) => ut.id === userType,
    )?.code;
    if (UserTypesEnum.ELEV !== userTypeCode) {
      return options.filter(
        (pos) => pos.name !== 'Secretariat institutie de invatamant',
      );
    }
    return options;
  }, [userType, userTypes, options]);

  return (
    <FormWrapper.Select
      label={t('entity.orders.pos')}
      props={{
        errorMessageStatic,
        options: data.map((o) => ({
          id: o.id,
          name: o.name,
        })),
        onSelect: (value) => {
          const selectedPos = data.filter((pos) => pos.id === value);
          if (selectedPos.length > 0) {
            setValue('posId', selectedPos[0].id);
            setValue('posName', selectedPos[0].name);
          }
        },
        allowClear: false,
        labelProp: (obj) => obj.name,
        disabled: !isNew && disabled,
        ...select((!isNew && 'posId').toString()),
      }}
    />
  );
}

export default PosSelector;
