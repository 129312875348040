import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Table,
  PageHeader,
  Button,
  Col,
  Row,
  Form,
  message,
  Popover,
} from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { Default } from '../../layouts';
import useDatasource from '../../hooks/useDatasource';
import { downloadReceipe, findAll } from '../../services/receipts';
import PageTitle from '../../components/PageTitle';
import Column from '../../helpers/columns';
import { getUserCardsDetails } from '../../services/user-cards';

const ReceiptsList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, pagination, content, handleChange } = useDatasource(findAll);
  const [userCardDetails, setUserCardDetails] = useState(null);
  const [panMaskForm] = Form.useForm();

  const cardsContent = (
    <div className="receipe-card">
      <div className="receipe-card-number">
        <span className="receipe-hidden-digits">**** **** **** </span>
        <span className="receipe-last-four-digits">
          {userCardDetails?.lastDigits}
        </span>
      </div>
      <div className="receipe-expiration-date">
        <span>Exp: {userCardDetails?.cardExp}</span>
      </div>
    </div>
  );

  useEffect(() => {
    getUserCardsDetails().then((result) => {
      if (result != null) {
        setUserCardDetails(result);
        panMaskForm.setFieldsValue({
          panMask: result?.panMask,
        });
      }
    });
  }, [panMaskForm]);

  const handleDownloadReceipe = useCallback(
    (url, rec, reportTitile) => {
      downloadReceipe(url, rec, reportTitile)
        .then((r) => console.log(r))
        .catch((err) => {
          message.error({
            content: err?.inner?._
              ? t(err?.inner?._)
              : t('errors.receipeDownloadErr'),
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [t],
  );

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.orders.receipts.columns.id'), {
        width: 100,
        filter: true,
      }),
      Column.text('tid', t('entity.orders.receipts.columns.tid'), {
        width: 250,
      }),
      Column.text('aid', t('entity.orders.receipts.columns.aid'), {
        width: 100,
        filter: true,
      }),
      Column.text('mid', t('entity.orders.receipts.columns.mid'), {
        width: 100,
        filter: true,
      }),
      Column.text('batch', t('entity.orders.receipts.columns.batch'), {
        width: 250,
      }),
      Column.text(
        'receiptNumber',
        t('entity.orders.receipts.columns.receiptNumber'),
        {
          width: 100,
          filter: true,
        },
      ),
      Column.text(
        'authorizationCode',
        t('entity.orders.receipts.columns.authorizationCode'),
        {
          width: 100,
          filter: true,
        },
      ),
      Column.text('panMask', t('entity.orders.receipts.columns.panMask'), {
        width: 100,
        filter: true,
      }),
      Column.date(
        'paymentDate',
        t('entity.orders.receipts.columns.paymentDate'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.text('line', t('entity.orders.receipts.columns.line'), {
        width: 250,
      }),
      Column.text('sumPaid', t('entity.orders.receipts.columns.sumPaid'), {
        width: 100,
        filter: true,
      }),
      Column.text('vehicle', t('entity.orders.receipts.columns.vehicle'), {
        width: 100,
        filter: true,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Button
            shape="circle"
            type="link"
            icon={<DownloadOutlined />}
            onClick={() =>
              handleDownloadReceipe('/receipe/download', record, 'chitanta.pdf')
            }
          />
        </span>
      )),
    ],
    [t, handleDownloadReceipe],
  );

  return (
    <Default>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/home')}
        title={t('actions.backToHomePage')}
      >
        <Row>
          <Col flex={4}>
            <PageTitle title={t('entity.orders.receipts.title')} />
          </Col>
          <Col style={{ padding: 5 }}>
            <div className="ant-typography" />
            {userCardDetails?.id ? (
              <Popover placement="bottom" content={cardsContent}>
                <InfoCircleOutlined />
              </Popover>
            ) : (
              <span />
            )}
          </Col>
          <Col flex={1}>
            {userCardDetails !== null && (
              <>
                <div className="ant-typography" />
                <form
                  method="POST"
                  action={`${window._env_.API_BACKEND_URL}/portal/api/v1/user-cards/updateHashPan/${userCardDetails.userId}`}
                >
                  <input
                    type="hidden"
                    name="x-tenant-key"
                    value={window._env_.API_TENANT_KEY}
                  />
                  <Button type="primary" block htmlType="submit">
                    {userCardDetails?.hashPan != null
                      ? t(`entity.orders.receipts.panMapChange`)
                      : t(`entity.orders.receipts.panMapRegister`)}
                  </Button>
                </form>
              </>
            )}
          </Col>
        </Row>

        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          scroll={{ y: 700 }}
        />
      </PageHeader>
    </Default>
  );
};

export default ReceiptsList;
