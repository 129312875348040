import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  notification,
  Row,
  Skeleton,
  Tooltip,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import { addAccount, getUserCurrentAccounts } from '../../services/accounts';

import { getUserDetails } from '../../services/users';
import AuthContext from '../auth';
import Card from '../Card';
import { AUTH_BASE_URI, AUTH_SERVICE_URI } from '../../services/auth';
import AccountDescendantsList from './AccountDescendantsList';
import { CUSTOMER_TYPE as CustomerType } from '../../pages/orders/constants';
import BankCard from '../BankCard';

const AccountsForm = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  const [accounts, setAccounts] = useState(undefined);
  const [showDescendantsModal, setShowDescendantsModal] = useState(undefined);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [, setUserDetails] = useState(undefined);
  const [userType, setUserType] = useState(null);
  // cicd
  const canEnrollBankAccount = useMemo(
    () => window._env_.CAN_ENROLL_BANK_CARD,
    [],
  );

  const redirectToLogout = () => {
    setTimeout(() => {
      window.location.href = `${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/logout`;
    }, 2000);
  };

  const checkAccounts = useCallback(() => {
    const isIndividualUser = userType === CustomerType.INDIVIDUAL;
    const filteredIndividualAccounts = accounts?.filter(
      (acc) => acc.customerType === CustomerType.INDIVIDUAL,
    );
    const isIndividualAccount = filteredIndividualAccounts?.length > 0;
    const displayCard =
      filteredIndividualAccounts
        ?.flatMap((acc) => acc.cards)
        .filter(
          (card) =>
            card.deleted === false &&
            (card.displayAccounts === false || card.displayAccounts === null),
        ).length > 0;
    return isIndividualUser && isIndividualAccount && displayCard;
  }, [accounts, userType]);

  const checkBankAccounts = useCallback(() => {
    const isIndividualUser = userType === CustomerType.INDIVIDUAL;
    const filteredPanAccounts = accounts?.filter(
      (acc) => acc.customerType === CustomerType.PAN,
    );
    const isPanAccount = filteredPanAccounts?.length > 0;
    const displayCard =
      filteredPanAccounts
        ?.flatMap((acc) => acc.cards)
        ?.filter((card) => card.deleted === false).length > 0;
    return (
      isIndividualUser && (!isPanAccount || (isPanAccount && !displayCard))
    );
  }, [accounts, userType]);

  useEffect(() => {
    getUserCurrentAccounts().then((result) => {
      setAccounts(result);
    });
    getUserDetails().then((result) => {
      setUserDetails(result);
      setUserType(result?.customerType);
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramToDelete = 'checkDuplicatePanMessage';

    if (searchParams.has(paramToDelete)) {
      searchParams.delete(paramToDelete);
      history.replace({
        search: searchParams.toString(),
      });
      notification.warn({
        message: t('warnings.checkDuplicatePanMessage'),
        duration: 5,
      });
    }
  }, [t, location.search, history]);

  // cicd
  return (
    <>
      <Skeleton loading={accounts === undefined} active>
        <Row gutter={[16, 16]}>
          {(
            accounts?.filter(
              (account) =>
                account?.customerType === 'LEGAL' ||
                (account?.customerType === 'INDIVIDUAL' &&
                  account?.cards?.filter(
                    (card) =>
                      card.deleted === false && card.displayAccounts === true,
                  ).length > 0),
            ) || []
          ).map((account) => {
            return (
              <Card
                key={account.id}
                account={account}
                setShowModalFor={setShowDescendantsModal}
              />
            );
          })}
          {(
            accounts?.filter(
              (account) =>
                account?.customerType === 'PAN' &&
                account?.cards?.filter(
                  (card) =>
                    card.deleted === false && card.displayAccounts === true,
                ).length > 0,
            ) || []
          ).map((account) => {
            return (
              <BankCard
                key={account.id}
                account={account}
                user={user}
                setShowModalFor={setShowDescendantsModal}
              />
            );
          })}
          {checkAccounts() && (
            <Col
              span={6}
              key="addCard"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Tooltip title={t('actions.cards.addCard')} key="add">
                <Button
                  shape="round"
                  icon={<PlusOutlined />}
                  size="large"
                  onClick={() => setShowAddCardModal(true)}
                >
                  {t(`actions.addCard`)}
                </Button>
              </Tooltip>
            </Col>
          )}
          {checkBankAccounts() && canEnrollBankAccount === 'true' && (
            <Col
              span={6}
              key="addBankCard"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Tooltip title={t('actions.cards.addBankCard')} key="add">
                <form
                  method="POST"
                  action={`${window._env_.API_BACKEND_URL}/portal/api/v1/enroll-card/${user.id}`}
                >
                  <input
                    type="hidden"
                    name="x-tenant-key"
                    value={window._env_.API_TENANT_KEY}
                  />
                  <Button
                    block
                    htmlType="submit"
                    shape="round"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    {t(`actions.addBankCard`)}
                  </Button>
                </form>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Skeleton>
      <Modal
        keyboard
        title={t('entity.account.descendants._')}
        visible={showDescendantsModal !== undefined}
        width={960}
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setShowDescendantsModal(undefined)}
          >
            Inchide
          </Button>,
        ]}
      >
        {showDescendantsModal && (
          <AccountDescendantsList id={showDescendantsModal} span={8} />
        )}
      </Modal>
      <Modal
        keyboard
        title={t('entity.account.cardNumber')}
        visible={showAddCardModal}
        width={260}
        closable={false}
        footer={[
          <Button
            key="submitCard"
            htmlType="submit"
            onClick={() =>
              form
                .validateFields()
                .then(({ logicId }) => {
                  setShowAddCardModal(false);
                  form.resetFields();
                  addAccount(logicId)
                    .then(() =>
                      getUserCurrentAccounts().then((acts) => {
                        setAccounts(
                          acts.filter((act) =>
                            [
                              CustomerType.PAN,
                              CustomerType.INDIVIDUAL,
                            ].includes(act.customerType),
                          ),
                        );
                        notification.success({
                          message: t(`actions.saveSuccessful`),
                        });
                      }),
                    )
                    .catch((err) => {
                      notification.error({
                        message: t(err?.inner?._),
                      });
                      if (err?.inner?.action === 'logout') {
                        redirectToLogout();
                      }
                    });
                })
                .catch(() =>
                  notification.error({
                    message: t(`errors.invalidData`),
                  }),
                )
            }
          >
            Ok
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowAddCardModal(false);
              form.resetFields();
            }}
          >
            {t(`actions.close`)}
          </Button>,
        ]}
      >
        {showAddCardModal && (
          <Form form={form} name="logic-id">
            <FormItem
              name="logicId"
              rules={[
                {
                  required: true,
                  message: t('errors.requiredField'),
                },
                {
                  type: 'number',
                  min: 1,
                  message: t('errors.invalidField'),
                },
              ]}
            >
              <InputNumber controls={false} type="number" />
            </FormItem>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AccountsForm;
