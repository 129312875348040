import { CUSTOMER_TYPE } from '../pages/orders/constants';

export const getAccountType = (customerType, t) => {
  switch (customerType) {
    case CUSTOMER_TYPE.LEGAL:
      return t('pages.accountView.accountType.LEGAL');
    case CUSTOMER_TYPE.INDIVIDUAL:
      return t('pages.accountView.accountType.INDIVIDUAL');
    case CUSTOMER_TYPE.PAN:
      return t('pages.accountView.accountType.PAN');
    default:
      return '';
  }
};
