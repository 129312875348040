import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Descriptions, Typography } from 'antd';
import { FormContext } from '../../hooks/useForm';

export const getProductsWithCurrentPrice = (products) =>
  products.map((row) => ({
    ...row,
    price:
      row.prices
        .map((productPrice) => ({
          ...productPrice,
          dateStart: new Date(productPrice.dateStart),
        }))
        .filter((productPrice) => productPrice.dateStart < new Date())
        .sort((a, b) => b.dateStart - a.dateStart)?.[0]?.price || 0,
  }));

const TotalPrice = ({ setPrice }) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const { value } = form;
  const { products } = value;

  const totalPrice = useMemo(() => {
    const total = products.reduce((acc, product) => {
      const productPersonPrice = product.productPersons.reduce(
        (personAcc, productPerson) => personAcc + productPerson.price / 100,
        0,
      );
      return acc + productPersonPrice;
    }, 0.0);

    setPrice(total / 100);
    return total;
  }, [products, setPrice]);

  return (
    !Number.isNaN(totalPrice) && (
      <Descriptions bordered style={{ padding: 40 }}>
        <Descriptions.Item label={t('entity.orders.totalPrice')}>
          <Typography.Title
            style={{ textAlign: 'right', padding: 0, margin: 0 }}
          >
            {totalPrice.toFixed(2)}
          </Typography.Title>
        </Descriptions.Item>
      </Descriptions>
    )
  );
};

export default TotalPrice;
