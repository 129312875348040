import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import FormWrapper from '../FormWrapper';
import { getUserAccounts } from '../../services/accounts';

const DropDownCards = ({ label, props }) => {
  const {
    invalid: errorMessage,
    disabled,
    getValue,
    name,
    field,
    setValue,
    labelProp = 'name',
  } = props;
  const [cards, setCards] = useState([]);

  useEffect(() => {
    getUserAccounts({
      pageSize: 1000,
      criterias: { customerType: 'LEGAL', userName: '' },
    }).then((res) => {
      if (res != null) {
        setCards(
          res.flatMap((acc) =>
            acc?.cards?.filter(
              (card) => card.deleted === false && card.displayAccounts === true,
            ),
          ),
        );
      } else {
        setCards([]);
      }
    });
  }, []);

  useEffect(() => {
    if (cards.length === 1 && !getValue(name)) {
      setValue(name, cards[0].logicIdSerial);
    }
  }, [cards, getValue, name, setValue, labelProp]);

  return (
    <FormWrapper.FieldWrapper label={label} errorMessage={errorMessage}>
      <Select
        allowClear
        showSearch
        disabled={disabled}
        value={getValue(name)}
        style={{ width: '100%' }}
        onChange={(val) => setValue(name, val)}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input?.toLowerCase()) !== -1
        }
      >
        {cards.map((el) => (
          <Select.Option value={el[labelProp]} key={el[field]}>
            {el[labelProp]}
          </Select.Option>
        ))}
      </Select>
    </FormWrapper.FieldWrapper>
  );
};
export default DropDownCards;
