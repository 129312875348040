import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Form, Input, notification, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

// import useFormErrors from '../../hooks/useFormErrors';
import { isEmpty } from 'lodash';
import CancelButton from '../buttons/BackButton';

const ForgotPasswordForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // useFormErrors(form, errors);

  const handleOnSubmit = async (values) => {
    if (isEmpty(form.getFieldValue('email'))) {
      notification.error({
        message: t('errors.emptyEmail'),
      });
      return;
    }
    setLoading(true);
    onSubmit(values);
  };

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '960px',
        margin: '0 auto',
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleOnSubmit}>
        <Form.Item label={t('entity.admin.user.email')} name="email">
          <Input type="email" />
        </Form.Item>

        <Form.Item
          name="recaptcha"
          style={{ transform: `scale(${0.9})`, transformOrigin: '0 0' }}
        >
          <ReCAPTCHA
            name="recaptcha"
            sitekey={window._env_.RECAPTCHA_SITE_KEY}
            hl="ro"
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              icon={<SaveOutlined />}
            >
              {t('actions.resetPassword')}
            </Button>

            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
