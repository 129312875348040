import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { Col, notification, PageHeader, Row, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import CancelButton from '../buttons/CancelButton';
import { BULK_TYPES, ORDER_STATUS } from '../../pages/orders/constants';
import Products from './Products';
import { useRouteParams } from '../../hooks/useRouteParams';
import IssuingOrderButtons from './IssuingOrderButtons';
import CustomSaveButton from '../buttons/CustomSaveButton';
import TotalPrice from './TotalPrice';

import BulkSelector from './BulkSelector';
import UserTypeSelector from './UserTypeSelector';
import PosSelector from './PosSelector';
import useDictionaries from '../../hooks/useDictionaries';
import { getUserTypes } from '../../services/taxonomies';
import { getUserDetails } from '../../services/users';
import { ShowColumns, UserTypesEnum } from '../../services/constants';

const dictionaries = {
  userTypes: getUserTypes,
};

const EditIndividualOrderForm = ({ order, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();
  const { search } = useLocation();
  const history = useHistory();
  const [userType, setUserType] = useState(null);
  const [myUserType, setMyUserType] = useState(null);
  const [requiresOrganization, setRequiresOrganization] = useState(false);
  const [{ userTypes }] = useDictionaries(dictionaries);
  const [user, setUser] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [newUserTypes, setNewUserTypes] = useState([]);
  const [currentUserShowColumns, setCurrentUserShowColumns] = useState(null);
  const [userTypeShowColumns, setUserTypeShowColumns] = useState([]);

  useEffect(() => {
    if (isNew) {
      setUserType(null);
    }
  }, [isNew]);

  useEffect(() => {
    const status = new URLSearchParams(search).get('status');
    // eslint-disable-next-line no-unused-expressions
    status &&
      notification[status]({
        message:
          status === 'success'
            ? t('entity.orders.successfullPayment')
            : t('entity.orders.errorPayment'),
      });
  }, [search, t]);

  const userTypeCode = userTypes.content?.find(
    (ut) => ut.id === userType,
  )?.code;

  useEffect(() => {
    getUserDetails()
      .then((o) => {
        if (o !== null) {
          setUser(o);
          if (isNew) {
            const isInList = userTypes.content.filter(
              (ut) => ut.hasDiscountOrFree === true && ut.id === o?.userTypeId,
            ).length;

            const usrType = userTypes.content.filter(
              (ut) => ut.id === o?.userTypeId,
            )[0];
            const isDiscountOrFreeList =
              userTypes.content.filter(
                (ut) => ut?.hasDiscountOrFree && ut.code === usrType?.code,
              ).length > 0;

            if (isDiscountOrFreeList) {
              const isRetiredCategory = (code) =>
                [
                  UserTypesEnum.PENSIONAR,
                  UserTypesEnum.PENSIONAR_CONVENTIE,
                ].includes(code);
              const retiredUsersTypes = userTypes.content.filter((type) =>
                isRetiredCategory(type.code),
              );
              const isPensionar =
                retiredUsersTypes?.filter((ut) => ut.id === usrType?.id)
                  .length > 0;
              const currentUserType = isInList > 0 ? usrType?.id : null;
              setUserType(isPensionar ? -1 : currentUserType);
            }
          }
        }
      })
      .catch((err) => {
        notification.error({
          message: err.message,
        });
      });
  }, [isNew, userTypes]);

  const formRef = useRef(0);

  // useEffect(() => {
  //   setUserTypeShowColumns(
  //     userTypes.content?.find((ut) => ut.id === userType)?.showColumns || [],
  //   );
  // }, [userTypes, userType]);

  useEffect(() => {
    setUserTypeShowColumns([]);
    const currentUserType =
      userTypes.content?.find((ut) => ut.id === userType) || null;
    if (currentUserType !== null) {
      const currentUserTypeCode = currentUserType?.code;
      if (
        currentUserTypeCode === UserTypesEnum.PENSIONAR_GENERIC &&
        currentUserShowColumns !== null
      ) {
        setUserTypeShowColumns(currentUserShowColumns || []);
      } else {
        setUserTypeShowColumns(currentUserType?.showColumns || []);
      }
    }
  }, [userTypes, userType, currentUserShowColumns]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        bulkType: yup.string().required(t('errors.mustSelected')),
        commandType: yup.string().required(t('errors.mustSelected')),
        posId: yup
          .number()
          .nullable()
          .when('$bulkType', {
            is: (bulkType) => [BULK_TYPES.ISSUING_CARDS].includes(bulkType),
            then: yup.number().required(t('errors.requiredField')),
          }),
        products: yup.array().of(
          yup.object().shape({
            productId: yup
              .number()
              .nullable()
              .when('$bulkType', {
                is: (bulkType) =>
                  [
                    BULK_TYPES.ISSUING_CARDS,
                    BULK_TYPES.RECHARGE_CARDS,
                  ].includes(bulkType),
                then: yup.number().required(t('errors.requiredField')),
              }),
            institutionId: yup
              .number()
              .nullable()
              .when('requiresOrganization', {
                is: requiresOrganization,
                then: () => yup.number().typeError(t('errors.mustSelected')),
              }),
            count: yup.number().nullable(),
            productPersons: yup.array().of(
              yup.object().shape({
                person: yup.object().shape({
                  fullname: yup
                    .string()
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.ISSUING_CARDS,
                      then: yup.string().required(t('errors.notEmpty')),
                    }),
                  email: yup
                    .string()
                    .email()
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.ISSUING_CARDS,
                      then: yup.string().required(t('errors.notEmpty')),
                    }),
                  identityCard: yup
                    .number()
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.ISSUING_CARDS,
                      then: yup.number().required(t('errors.notEmpty')),
                    }),
                  cardLogicalId: yup
                    .number()
                    .nullable()
                    .when('$bulkType', {
                      is: BULK_TYPES.RECHARGE_CARDS,
                      then: yup
                        .number()
                        .required(t('errors.logicalIdNotEmpty')),
                    }),
                  subdivisionId: yup
                    .string()
                    .nullable()
                    .when('$bulkType', {
                      is: (bulkType) =>
                        [
                          BULK_TYPES.ISSUING_CARDS,
                          BULK_TYPES.RECHARGE_CARDS,
                        ].includes(bulkType),
                      then: yup
                        .string()
                        .nullable()
                        .test(
                          'subdivisionId',
                          `${t('errors.subdivisionNotEmpty')}\n`,
                          (value) => {
                            if (
                              userTypeShowColumns.includes(
                                ShowColumns.SUB_INSTITUTION_ID,
                              )
                            ) {
                              return !(!value || value.trim().length === 0);
                            }
                            return true;
                          },
                        ),
                    }),
                  metaData1: yup
                    .string()
                    .nullable()
                    .when('$bulkType', {
                      is: (bulkType) =>
                        [
                          BULK_TYPES.ISSUING_CARDS,
                          BULK_TYPES.RECHARGE_CARDS,
                        ].includes(bulkType),
                      then: yup
                        .string()
                        .nullable()
                        .test(
                          'metaData1',
                          myUserType != null &&
                            myUserType.length > 0 &&
                            myUserType[0].code === 'DONATORI'
                            ? `${t('errors.metadataNotEmptyBloodDonor')}\n`
                            : `${t('errors.metadataNotEmpty')}\n`,
                          (value) => {
                            if (
                              userTypeShowColumns.includes(
                                ShowColumns.META_DATA_1,
                              )
                            ) {
                              return !(!value || value.trim().length === 0);
                            }
                            return true;
                          },
                        ),
                    }),
                  employeeNumber: yup
                    .string()
                    .nullable()
                    .matches(/^(\d+)?$/, t('errors.incorrectField')),
                  phone: yup
                    .string()
                    .nullable()
                    .matches(/^(\+?\d+)?$/, t('errors.incorrectField')),
                  documents: yup.array().of(
                    yup.object().shape({
                      documentTypeId: yup.number(),
                      fileId: yup.number(),
                    }),
                  ),
                }),
              }),
            ),
          }),
        ),
      }),
    [t, requiresOrganization, userTypeShowColumns, myUserType],
  );

  const form = useForm({
    initialValue: order,
    schema,
    onSubmit,
    onCancel,
  });

  const { handleSubmit, setFormValue, select, input, value, setValue, custom } =
    form;
  const { status, bulkType, disabled, products } = value;
  const [isTypeChange, setIsTypeChange] = useState(null);

  // if it uses 'custom' method directly to selector it wouldn't work for this field
  const { invalid: errorMessagePosId } = custom('posId');

  useEffect(() => {
    if (userTypes.content.length > 0) {
      const userTps = userTypes;
      const isRetiredCategory = (code) =>
        [UserTypesEnum.PENSIONAR, UserTypesEnum.PENSIONAR_CONVENTIE].includes(
          code,
        );
      const retiredUsersTypes = userTps.content.filter((type) =>
        isRetiredCategory(type.code),
      );
      const institutions = retiredUsersTypes.flatMap(
        (retiredType) => retiredType.institutions,
      );
      const uniqueInstitutions = institutions.filter(
        (institution, index) => institutions.indexOf(institution) === index,
      );

      // TODO: de sortat alfabetic
      const newUserType = {
        name: 'Pensionar',
        code: 'PENSIONAR_GENERIC',
        details: null,
        docTypes: retiredUsersTypes[0]?.docTypes,
        id: -1,
        institutions: uniqueInstitutions,
      };
      const newContent = [...userTps.content, newUserType];
      userTps.content = newContent;
      setNewUserTypes(userTps);
    }
  }, [userTypes]);

  const handleUserTypeChange = (val) => {
    setUserType(val[0]?.id);
    setRequiresOrganization(val[0]?.institutions?.length > 0);
    setValue('userId', null);
    setMyUserType(val);
    // setValue('posId', null);
    if (isNew) {
      setValue('products', []);
    }
    if (!isNew) {
      setIsTypeChange(true);
    }
  };

  useEffect(() => {
    if (!isNew) {
      const userTypeId =
        userType == null
          ? value?.products[0]?.productPersons[0]?.person?.userTypeId
          : userType;
      setUserType(userTypeId);
    }
  }, [isNew, value, userType]);

  // useEffect(() => {
  //   getUserAccounts()
  //     .then((data) => data.filter((a) => a.customerType === 'INDIVIDUAL'))
  //     .then((res) => setValue('userId', res[0]?.id));
  // }, [setValue]);

  useEffect(() => {
    setFormValue(order);
  }, [setFormValue, order]);

  return (
    <FormContext.Provider value={form}>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/home')}
        title={t('actions.backToHomePage')}
      >
        <form
          onSubmit={handleSubmit}
          ref={(r) => {
            formRef.current = r;
          }}
        >
          <FormWrapper>
            <FormWrapper.Single>
              <BulkSelector props={{ t, isNew, select }} />
              <UserTypeSelector
                props={{
                  t,
                  setValue,
                  isNew,
                  disabled,
                  handleUserTypeChange,
                  userType,
                  products,
                }}
              />
              {[BULK_TYPES.ISSUING_CARDS].includes(bulkType) && (
                <PosSelector
                  props={{
                    t,
                    select,
                    isNew,
                    disabled,
                    setValue,
                    userType,
                    userTypes,
                    errorMessagePosId,
                  }}
                />
              )}
              {[ORDER_STATUS.REJECTED].includes(status) && (
                <FormWrapper.Input
                  label={t('entity.orders.cancelReason')}
                  props={{ ...input('cancelReason'), disabled: true }}
                />
              )}
              {products[0]?.startDate != null && (
                <FormWrapper.DateTimePicker
                  label={t('entity.orders.subscriptionStartDate')}
                  props={{ ...custom('products[0].startDate'), disabled: true }}
                />
              )}
            </FormWrapper.Single>
          </FormWrapper>

          {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
            bulkType,
          ) &&
            userType && (
              <Row>
                <Col span={24}>
                  <Products
                    props={{
                      userType,
                      isTypeChange,
                      userTypes: newUserTypes,
                      setNewUserTypes,
                      user,
                      isNew,
                      userTypeShowColumns,
                      setCurrentUserShowColumns,
                    }}
                  />
                </Col>
              </Row>
            )}

          {[ORDER_STATUS.PAYMENT, ORDER_STATUS.PAID].includes(status) && (
            <TotalPrice setPrice={(price) => setTotalPrice(price)} />
          )}
          <Space>
            <CancelButton onCancel={onCancel} />
            {[ORDER_STATUS.SELECT_PRODUCTS].includes(status) &&
              products.length > 0 &&
              products[0].productId !== null &&
              form?.value?.userId && (
                <CustomSaveButton form={form} formRef={formRef} />
              )}
            {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
              bulkType,
            ) &&
              !isNew &&
              form?.value?.userId && (
                <IssuingOrderButtons
                  form={form}
                  formRef={formRef}
                  bulkType={bulkType}
                  userType={userType}
                  userTypes={userTypes}
                  totalPrice={totalPrice}
                  userTypeCode={userTypeCode}
                />
              )}
            {/* {bulkType === BULK_TYPES.DISABLE_CARDS && (
            <DisablingOrderButtons form={form} formRef={formRef} />
          )} */}
          </Space>
        </form>
      </PageHeader>
    </FormContext.Provider>
  );
};

export default EditIndividualOrderForm;
