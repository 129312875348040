import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import App from './App';

import './index.scss';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  window._env_ = window._env_ || {};

  // eslint-disable-next-line no-undef
  window._env_.API_BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;
  window._env_.API_TENANT_KEY = process.env.REACT_APP_API_TENANT_KEY;
  window._env_.API_TENANT_SECRET = process.env.REACT_APP_API_TENANT_SECRET;
  window._env_.RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  window._env_.CAN_ENROLL_BANK_CARD =
    process.env.REACT_APP_CAN_ENROLL_BANK_CARD;
  window._env_.DISABLE_ReCAPTCHA =
    process.env.REACT_APP_DISABLE_ReCAPTCHA || 'false';
  window._env_.ENVIROMENT = process.env.REACT_APP_ENVIROMENT;
  window._env_.MAP_URL = process.env.REACT_APP_MAP_URL;
  window._env_.ENABLE_ERP = process.env.REACT_APP_ENABLE_ERP || 'true';
  window._env_.ENABLE_PF_DESCENDENTS =
    process.env.REACT_APP_ENABLE_PF_DESCENDENTS || 'false';
  window._env_.ENABLE_GRATUITIES_REPORTS =
    process.env.REACT_APP_ENABLE_GRATUITIES_REPORTS || 'false';
}

const initializeI18n = async () => {
  return i18n
    .use(backend)
    .use(initReactI18next)
    .init({
      lng: sessionStorage.getItem('language')
        ? sessionStorage.getItem('language')
        : 'ro',
      fallbackLng: 'ro',
      ns: ['translation'],
      defaultNS: 'translation',
      backend: {
        loadPath: '/locales/{{lng}}.json',
      },
      react: {
        useSuspense: false,
      },
    });
};

initializeI18n().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
