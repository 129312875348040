export const AUTH_SERVICE_URI = '/auth';
export const AUTH_BASE_URI = '/api/v1/auth';

export const getCurrentUserDetails = () =>
  fetch(`${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'X-Tenant-Key': window._env_.API_TENANT_KEY,
      'Content-Type': 'application/json',
    },
  }).then((resp) => (resp.ok ? resp.json() : Promise.reject(resp)));
