import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification, Space, Table, Tooltip } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import Column from '../../helpers/columns';
import { getDocTypes } from '../../services/taxonomies';
import useDictionaries from '../../hooks/useDictionaries';
import { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import {
  downloadFiles,
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../../services/files';
import { getProducts } from '../../services/products';
import SearchSubdivisions from './SearchSubdivisions';
import ProductModalDocUpload from './ProductModalDocUpload';
import { ShowColumns, UserTypesEnum } from '../../services/constants';
import DropDownCards from './DropDownCards';

const dictionaries = {
  docTypesTaxonomy: getDocTypes,
  productTypes: getProducts,
};

const ProductUsersList = ({
  index,
  disabled,
  fields,
  institutionId,
  userTypes,
  userTypeCode,
}) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);
  const [documentTypes, setDocTypes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [{ docTypesTaxonomy, productTypes }] = useDictionaries(dictionaries);

  const { input, value, setValue, custom, checkbox } = form;

  const { products = [], id: orderId } = value;
  const { productPersons = [], productId } = products[index];

  useEffect(() => {
    const { userTypeId } =
      // eslint-disable-next-line eqeqeq
      productTypes.content.find((pt) => pt.id == productId) || {};
    // eslint-disable-next-line eqeqeq
    const type = userTypes.content?.find((c) => c.id == userTypeId);
    const docs = type?.docTypes || [];

    const res = docTypesTaxonomy.content.filter((dt) => docs.includes(dt.id));
    setDocTypes(res.sort((a, b) => a.id - b.id));
  }, [docTypesTaxonomy.content, productId, productTypes, userTypes.content]);

  const onUploadFailed = useCallback(
    (msg) => {
      const messageCode = msg?.inner?._;
      notification.error({
        message: messageCode ? t(messageCode) : t('actions.saveFailed'),
      });
    },
    [t],
  );

  const getDocColumns = useCallback(() => {
    return documentTypes.map((dt) =>
      Column.actions(
        t(`entity.orders.docType.${dt.code}`),
        (record, rowIndex) => {
          const { docRequired, hasExpirationDate } = dt;
          const currentDoc = record?.person?.documents?.find(
            (savedDoc) => savedDoc && savedDoc.documentTypeId === dt.id,
          );
          const idx = record?.person?.documents?.findIndex(
            (savedDoc) => savedDoc && savedDoc.documentTypeId === dt.id,
          );
          const docsLength = record?.person?.documents
            ? record?.person?.documents?.length
            : 0;
          return (
            <>
              {hasExpirationDate === true ? (
                <ProductModalDocUpload
                  props={{
                    dt,
                    orderId,
                    disabled,
                    currentDoc,
                    userTypeCode,
                    docRequired,
                    ...custom(
                      `products[${index}].productPersons[${rowIndex}].person.documents[${
                        currentDoc ? idx : docsLength
                      }]`,
                    ),
                    setValue: (name, uploadedFiles, expDate) => {
                      setValue(name, {
                        fileId: uploadedFiles[0].id,
                        documentTypeId: dt.id,
                        expirationDate: expDate,
                      });
                    },
                    setFormValue: setValue,
                    getValue: () =>
                      form.value.docMap !== undefined ? form.value.docMap : [],
                  }}
                />
              ) : (
                <FormWrapper.FileUploader
                  props={{
                    // eslint-disable-next-line no-nested-ternary
                    type: disabled
                      ? 'upload-disabled'
                      : // eslint-disable-next-line no-nested-ternary
                      docRequired
                      ? docRequired && currentDoc == null
                        ? 'danger'
                        : 'primary'
                      : '',
                    disabled,
                    multiple: false,
                    uploadUrl: `${FILE_SERVICE_URI}${FILE_BASE_URI}/${orderId}/files`,
                    ...custom(
                      `products[${index}].productPersons[${rowIndex}].person.documents[${
                        currentDoc ? idx : docsLength
                      }]`,
                    ),
                    setValue: (name, uploadedFiles) => {
                      setValue(name, {
                        fileId: uploadedFiles[0].id,
                        documentTypeId: dt.id,
                      });
                    },
                    setFieldValue: setValue,
                    getValue: () =>
                      form.value.docMap !== undefined ? form.value.docMap : [],
                    onUploadFailed,
                  }}
                />
              )}
              {currentDoc && (
                <Space style={{ paddingLeft: '1rem' }}>
                  <Tooltip title={t('actions.orders.viewDoc')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<FileTextOutlined />}
                      style={{ color: '#24ad09' }}
                      onClick={() => {
                        if (record?.id) {
                          downloadFiles(currentDoc.fileId, orderId);
                        } else if (form.value.docMap !== undefined) {
                          const url = window.URL.createObjectURL(
                            form.value.docMap[currentDoc.fileId],
                          );
                          const a = document.createElement('a');
                          a.href = url;
                          a.target = '_blank';
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                        }
                      }}
                    />
                  </Tooltip>
                </Space>
              )}
            </>
          );
        },
      ),
    );
  }, [
    custom,
    disabled,
    documentTypes,
    index,
    orderId,
    setValue,
    t,
    form,
    userTypeCode,
    onUploadFailed,
  ]);

  const columns = useMemo(
    () => [
      ...fields
        .filter(
          (it) =>
            it !== ShowColumns.SUB_INSTITUTION_ID &&
            it !== 'cardLogicalId' &&
            it !== ShowColumns.META_DATA_1,
        )
        .map((name) =>
          name !== 'foreignCitizen'
            ? {
                title: t(`entity.admin.user.${name}`),
                dataIndex: name,
                key: name,
                sorter: true,
                width: 150,
                render: (_, record, idx) => (
                  <FormWrapper.Input
                    props={{
                      ...input(
                        `products[${index}].productPersons[${idx}].person.${name}`,
                      ),
                      disabled: true,
                    }}
                  />
                ),
              }
            : {
                title: t('entity.admin.user.foreignCitizen'),
                dataIndex: 'foreignCitizen',
                key: 'foreignCitizen',
                sorter: true,
                width: 150,
                render: (_, record, idx) => (
                  <FormWrapper.Switch
                    props={{
                      disabled: true,
                      field: 'id',
                      ...checkbox(
                        `products[${index}].productPersons[${idx}].person.foreignCitizen`,
                      ),
                    }}
                  />
                ),
              },
        ),
      ...(fields.includes(ShowColumns.SUB_INSTITUTION_ID)
        ? [
            {
              title: t('entity.admin.user.subdivisionId'),
              dataIndex: 'subdivisionId',
              key: 'subdivisionId',
              sorter: true,
              width: 150,
              render: (_, record, idx) => (
                <SearchSubdivisions
                  props={{
                    disabled,
                    searchField: 'name',
                    field: 'id',
                    institutionId,
                    ...custom(
                      `products[${index}].productPersons[${idx}].person.subdivisionId`,
                    ),
                  }}
                />
              ),
            },
          ]
        : []),
      ...(fields.includes('cardLogicalId')
        ? [
            {
              title: t('entity.admin.user.cardLogicalId'),
              dataIndex: 'cardLogicalId',
              key: 'cardLogicalId',
              sorter: true,
              width: 150,
              render: (_, record, idx) => (
                <DropDownCards
                  props={{
                    disabled,
                    field: 'logicIdSerial',
                    ...custom(
                      `products[${index}].productPersons[${idx}].person.cardLogicalId`,
                    ),
                  }}
                />
              ),
            },
          ]
        : []),
      ...(fields.includes(ShowColumns.META_DATA_1)
        ? [
            {
              title:
                UserTypesEnum.PENSIONAR === userTypeCode
                  ? t('entity.admin.user.metaData1Pensioner')
                  : t('entity.admin.user.metaData1New'),
              dataIndex: 'metaData1',
              key: 'metaData1',
              sorter: true,
              width: 150,
              render: (_, record, idx) => (
                <FormWrapper.Input
                  props={{
                    ...input(
                      `products[${index}].productPersons[${idx}].person.metaData1`,
                    ),
                    disabled,
                  }}
                />
              ),
            },
          ]
        : []),
      ...getDocColumns(),
    ],
    [
      disabled,
      fields,
      getDocColumns,
      index,
      input,
      t,
      custom,
      institutionId,
      userTypeCode,
      checkbox,
    ],
  );

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.key || record.email}
      pagination={false}
      dataSource={productPersons}
    />
  );
};

export default ProductUsersList;
