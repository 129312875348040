import React, { useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Row, Col, Tabs, Divider, Space } from 'antd';

import { useTranslation } from 'react-i18next';
import { Empty } from '../layouts';

import LoginForm from '../components/auth/LoginForm';
import TitleLogo from '../components/TitleLogo';
import RegisterForm from '../components/RegisterForm';
// import GoogleButton from '../components/auth/GoogleButton';
// import FacebookButton from '../components/auth/FacebookButton';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const currentTab = useMemo(() => location.hash || '#login', [location.hash]);
  const mapLink = useMemo(() => window._env_.MAP_URL, []);

  const handleTabChange = useCallback(
    (tab) => {
      history.push({
        ...location,
        hash: tab,
      });
    },
    [history, location],
  );

  const { t } = useTranslation();

  const tabExtraContent = useMemo(
    () => (
      <a
        href={mapLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ float: 'right' }}
      >
        {t('actions.planRoute')}
      </a>
    ),
    [mapLink, t],
  );

  return (
    <Empty>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <TitleLogo />
        </Col>
      </Row>

      <Row>
        <Col
          style={{
            backgroundColor: 'rgba(255, 255, 255, .85)',
            padding: '1rem',
          }}
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <Tabs
            activeKey={currentTab}
            onChange={handleTabChange}
            tabBarExtraContent={tabExtraContent}
          >
            <Tabs.TabPane tab={t('startPage.login')} key="#login">
              <Divider style={{ marginTop: 0 }} />
              <LoginForm />
              {/* <Divider orientation="center">sau</Divider> */}
              <Space direction="vertical" style={{ display: 'flex' }}>
                {/* <GoogleButton /> */}
                {/* <FacebookButton /> */}
              </Space>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('startPage.register')} key="#register">
              <Divider style={{ marginTop: 0 }} />
              <RegisterForm />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Empty>
  );
};

export default Login;
