import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Modal,
  notification,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from 'antd';
import {
  ArrowRightOutlined,
  CloseOutlined,
  RollbackOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { disableCard } from '../services/accounts';
import FormWrapper from './FormWrapper';
import { getBlackReasons } from '../services/blacklist-reason';
import ReInrolareButton from './ReInrolareButton';

const BankCard = ({
  account,
  user,
  // setShowModalFor
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blockCardForm] = Form.useForm();
  const [options, setOptions] = useState([]);
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const contentStyle = {
    color: '#fff',
    fontSize: '20px',
  };

  useEffect(() => {
    getBlackReasons().then((data) => setOptions(data));
  }, []);

  const handleDisableCard = useCallback(
    (reasonId) => {
      disableCard(account.id, reasonId)
        .then(() => notification.success({ message: 'Cardul a fost blocat' }))
        .catch((err) => {
          console.error(err);
          notification.error({
            message: err.inner ? t(err.inner._) : 'Cardul nu a fost blocat',
          });
        });
    },
    [account.id, t],
  );
  return (
    <>
      <Skeleton loading={account === undefined} active>
        <Col span={6}>
          <div className="custom-bank-card">
            <div
              className="custom-bank-card-stripe"
              style={{ backgroundColor: '#524e4e' }}
            >
              <span>
                Exp:{' '}
                {
                  account?.cards?.filter(
                    (card) => card.displayAccounts && !card.deleted,
                  )[0]?.cardBankDetails[0]?.cardExp
                }
              </span>
            </div>
            <div className="button-container">
              {account.customerType !== 'LEGAL' && (
                <Tooltip title={t('actions.block')} key={account?.id}>
                  <button
                    type="button"
                    className="card-button"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <StopOutlined
                      style={{
                        fontSize: '3rem',
                        color: '#e67e22',
                        marginTop: '35%',
                      }}
                    />
                  </button>
                </Tooltip>
              )}
              <ReInrolareButton user={user} />
              <Tooltip title={t('actions.viewAccount')} key="view">
                <button
                  type="button"
                  className="card-button"
                  onClick={() => history.push(`/accounts/${account.id}`)}
                >
                  <ArrowRightOutlined
                    style={{
                      fontSize: '3rem',
                      color: '#2ecc71',
                      marginTop: '35%',
                    }}
                  />
                </button>
              </Tooltip>
            </div>
            <div className="custom-bank-card-body">
              <Row gutter={[0, 0]}>
                <Col span={24} gutter={[0, 0]}>
                  <Descriptions
                    style={{
                      padding: '.5rem',
                      paddingTop: '1rem',
                      paddingLeft: '2rem',
                      height: '12rem',
                    }}
                    bordered={false}
                    column={1}
                    size="medium"
                  >
                    <Descriptions.Item span={1} />
                    <Descriptions.Item span={1} />
                    <Descriptions.Item span={1} />
                    <Descriptions.Item
                      span={1}
                      style={style}
                      contentStyle={contentStyle}
                    >
                      <span>
                        **** **** ****{' '}
                        {
                          account?.cards?.filter(
                            (card) => card.displayAccounts && !card.deleted,
                          )[0]?.cardBankDetails[0]?.lastDigits
                        }
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={11}> </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Skeleton>

      <Modal
        title={t(`actions.cards.blockingConfirmation`)}
        visible={isModalVisible}
        closable={false}
        footer={
          <Space>
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="submit"
              icon={<CloseOutlined />}
              onClick={() => {
                const reasonId = blockCardForm.getFieldValue('blockReason');
                blockCardForm
                  .validateFields()
                  .then(() => handleDisableCard(reasonId))
                  .then(() => {
                    setIsModalVisible(false);
                    blockCardForm.resetFields(['blockReason']);
                  })
                  .catch((info) => {
                    console.error('Validate Failed:', info);
                  });
              }}
            >
              {t(`actions.confirm`)}
            </Button>
            <Button
              className="ant-btn-warning"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t(`actions.cards.cancel`)}
            </Button>
          </Space>
        }
      >
        <Form form={blockCardForm} layout="vertical">
          <Form.Item
            name={['blockReason']}
            label={t('entity.account.cards.blockCardReason')}
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <FormWrapper.Select
              props={{
                options,
                allowClear: true,
                onSelect: (value) => {
                  const selectedBlackReason = options?.filter(
                    (opt) => opt.id === value,
                  );
                  if (selectedBlackReason.length > 0) {
                    blockCardForm.setFieldsValue({ blockReason: value });
                  }
                },
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default BankCard;
