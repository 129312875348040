import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Popconfirm, Tooltip } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const PopupConfirmButton = ({
  title,
  item,
  action,
  disabled = false,
  size = 'middle',
  okText = 'actions.confirm',
  cancelText = 'actions.cancel',
  buttonName = 'actions.orders.docValidation',
  icon = <SaveOutlined />,
  tooltip = 'actions.confirm',
  buttonStyle,
}) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    action(item);
  }, [action, item]);

  return (
    <Popconfirm
      placement="topLeft"
      title={t(title)}
      disabled={disabled}
      onConfirm={handleConfirm}
      okText={t(okText)}
      cancelText={t(cancelText)}
      okButtonProps={{ htmlType: 'submit', type: 'primary' }}
    >
      <Tooltip title={t(tooltip)}>
        <Button
          type="primary"
          icon={icon}
          disabled={disabled}
          size={size}
          style={buttonStyle}
        >
          {t(buttonName)}
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default PopupConfirmButton;
