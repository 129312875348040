import React from 'react';

import { Badge, Spin, Typography } from 'antd';
import moment from 'moment';

import {
  filterBool,
  filterDate,
  filterDictionary,
  filterText,
} from '../hooks/useDatasource';

const Column = {
  text: (
    key,
    title,
    { filter = false, sort = true, width = 200, fixed } = {},
  ) => ({
    title,
    key,
    width,
    dataIndex: key.split('.'),
    sorter: sort,
    fixed,
    ...(filter ? filterText({ title }) : {}),
  }),

  label: (key, title, { sort = true, width = 200, fixed } = {}) => ({
    title,
    key,
    width,
    dataIndex: key,
    sorter: sort,
    fixed,
    render: (value) => <Typography.Text>{value}</Typography.Text>,
  }),

  bool: (
    key,
    title,
    {
      filter = false,
      sort = true,
      width = 100,
      inverted = false,
      labels = ['All', 'Yes', 'No', 'Unknown'],
    } = {},
  ) => ({
    title,
    key,
    width,
    dataIndex: key,
    sorter: sort,
    ...(filter ? filterBool({ title, labels }) : {}),
    render: (value) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {
          // eslint-disable-next-line eqeqeq
          value == undefined ? (
            <Badge color="grey" text={labels[3]} />
          ) : (
            <Badge
              color={!(value === inverted) ? 'green' : 'red'}
              text={value ? labels[1] : labels[2]}
            />
          )
        }
      </>
    ),
  }),

  date: (
    key,
    title,
    {
      filter = false,
      sort = true,
      width = 200,
      fixed,
      format = 'YYYY-MM-DD HH:mm',
    } = {},
  ) => ({
    title,
    key,
    width,
    dataIndex: key,
    sorter: sort,
    fixed,
    ...(filter ? filterDate({ title }) : {}),
    render: (value) => (value ? moment(value).format(format) : ''),
  }),

  dictionary: (
    key,
    title,
    dictionary,
    {
      filter = false,
      sort = false,
      width = 100,
      dictKey = 'id',
      dictLabel = 'name',
      badgeColor,
    } = {},
  ) => ({
    title,
    key,
    width,
    dataIndex: key.split('.'),
    sorter: sort,
    ...(filter
      ? filterDictionary({ title, dictionary, dictKey, dictLabel })
      : {}),
    render: (value, row) => {
      if (dictionary.loading) {
        return <Spin size="small" />;
      }

      const record = dictionary.content.find(
        // eslint-disable-next-line eqeqeq
        (entry) => entry[dictKey] == value,
      );
      if (!record) return value;
      if (typeof dictLabel === 'function' && typeof badgeColor === 'function')
        return (
          <Badge color={badgeColor(record.id)} text={dictLabel(record, row)} />
        );
      if (typeof dictLabel === 'function') return dictLabel(record, row);

      return (
        <Typography.Text type={record.deleted ? 'secondary' : null}>
          {record[dictLabel]}
        </Typography.Text>
      );
    },
  }),

  actions: (title, actions, { width = 100 } = {}) => ({
    title,
    key: '__actions',
    width,
    dataIndex: '__actions',
    fixed: 'right',
    render: (_, record, index) => (
      <span style={{ textAlign: 'right' }}>{actions(record, index)}</span>
    ),
  }),
};

export default Column;
