import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import {
  downloadFiles,
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../../services/files';
import './OrderFileList.scss';
import { getDocTypes } from '../../services/taxonomies';
import useDatasource from '../../hooks/useDatasource';

const OrderFileList = ({ props }) => {
  const { t } = useTranslation();

  const { content } = useDatasource((data) =>
    getDocTypes({ ...data, pageSize: 100 }),
  );

  const { name, setValue, getValue, id: entityId, status } = props;

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.user.tabs.files.name'), {
        sort: false,
      }),
      {
        title: 'Data încărcare',
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        width: 300,
        render: (inputString) => {
          return moment(inputString).format('yyyy-MM-DD HH:mm:ss');
        },
      },
      {
        title: 'Tip document',
        dataIndex: 'docTypeId',
        key: 'docTypeId2',
        width: 300,
        render: (docTypeId) => {
          const docs = content.filter((docType) => docType.id === docTypeId);
          if (docs?.length > 0) {
            return docs[0].name;
          }
          return null;
        },
      },
      Column.actions(t('table.actions'), (file) => (
        <Button
          shape="circle"
          type="link"
          icon={<DownloadOutlined />}
          onClick={() => downloadFiles(file.id, entityId)}
        />
      )),
    ],
    [entityId, t, content],
  );

  const tableProps = useMemo(
    () => ({
      rowKey: 'id',
      scroll: { x: 1000 },
      rowClassName: () => 'order-file-list__table-row',
      columns,
    }),
    [columns],
  );

  return (
    <FormWrapper.FileList
      props={{
        uploadUrl: `${FILE_SERVICE_URI}${FILE_BASE_URI}/${entityId}/files`,
        entityId,
        name,
        setValue,
        getValue,
        tableProps,
        status,
      }}
    />
  );
};

export default OrderFileList;
