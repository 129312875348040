import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import useFormErrors from '../../hooks/useFormErrors';

import CancelButton from '../buttons/BackButton';

const ResetPasswordForm = ({ onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '960px',
        margin: '0 auto',
      }}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label={t('entity.admin.user.password')}
          name="password"
          rules={[
            { required: true, message: t('errors.requiredField') },
            () => ({
              validator(_, value) {
                if (
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{8,}$/.test(
                    value,
                  )
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    'Minimum 8 caractere, cel putin o litera majuscula, o litera minuscula, un numar si un caracter special',
                  ),
                );
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.repeatPassword')}
          name="repeatPassword"
          rules={[
            { required: true, message: t('errors.requiredField') },

            () => ({
              validator(_, value) {
                if (value === form.getFieldValue('password')) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Parolele introduse nu coincid!'),
                );
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              {t('actions.resetPassword')}
            </Button>

            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
