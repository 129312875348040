import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import { Button, Checkbox, Form, Input, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { registerUser } from '../services/users';

const RegisterForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const disableReCAPTCHA = useMemo(() => window._env_.DISABLE_ReCAPTCHA, []);

  const env = useMemo(() => window._env_.ENVIROMENT, []);
  let transportClient;
  let officeEmailAddress;
  switch (env) {
    case 'sibiu':
      transportClient = 'Tursib';
      officeEmailAddress = t('entity.office.infoSibiu');
      break;
    default:
      transportClient = '';
      officeEmailAddress = t('entity.office.info');
  }

  const [isForeignIdentityCard, setIsForeignIdentityCard] = useState(false);
  function isValid(cnp) {
    // Check if CNP has 13 digits
    if (cnp.length !== 13 || !/^\d+$/.test(cnp)) {
      return false;
    }
    // Extract individual digits from the CNP
    const digits = cnp.split('').map(Number);
    // Perform CNP validation logic
    const controlNumber = digits[12];
    const weights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    let sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 12; i++) {
      sum += digits[i] * weights[i];
    }
    const remainder = sum % 11;
    const calculatedControlNumber = remainder === 10 ? 1 : remainder;
    // Check if the calculated control number matches the actual control number
    return controlNumber === calculatedControlNumber;
  }

  // eslint-disable-next-line no-unused-vars
  const handleFinish = (values) => {
    const { identityCard, foreignIdentityCard } = values;
    if (
      foreignIdentityCard === true ||
      ((foreignIdentityCard === undefined || foreignIdentityCard === false) &&
        isValid(identityCard))
    ) {
      registerUser(values)
        .then(() => {
          notification.success({ message: t('entity.users.userCreated') });
          form.resetFields();
          history.push('/#login');
        })
        .catch((err) => {
          if (err?.inner) {
            const otherProperties = Object.keys(err.inner || {});

            otherProperties.forEach((property) => {
              const errorMessage =
                t(err.inner[property]) ?? t('errors.generic');

              notification.error({
                message: errorMessage,
              });
            });
          } else {
            notification.error({
              message: t('errors.generic'),
            });
          }
        });
    } else {
      notification.error({ message: t('errors.invalidCNP') });
    }
  };

  const [newPassword, setNewPassword] = useState('');

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="profile"
      name="userForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: t('errors.requiredField'),
          },
          {
            type: 'email',
            message: 'Adresa de email nu este validă',
          },
        ]}
        name="email"
      >
        <Input placeholder="Posta electronica" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t('errors.requiredField'),
          },
        ]}
        name="fullname"
        normalize={(val) => val.toUpperCase()}
      >
        <Input placeholder="Nume Prenume" id="fullname" />
      </Form.Item>
      <h5 style={{ textAlign: 'center', color: 'red' }}>
        {t('startPage.info_1', { transportClient })}
      </h5>
      <h5 style={{ textAlign: 'center', color: 'red' }}>
        {t('startPage.info_2', { officeEmailAddress })}
      </h5>
      <Form.Item
        label={t('entity.users.hasNoIdentityCard')}
        valuePropName="checked"
        name="foreignIdentityCard"
        initialValue={false}
      >
        <Checkbox
          onChange={(evt) => {
            setIsForeignIdentityCard(evt.target.checked);
            form.validateFields(['identityCard']);
          }}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: `${t('errors.requiredField')}`,
          },
          () => ({
            validator(_, value) {
              if (isForeignIdentityCard) {
                if (value.length > 40) {
                  return Promise.reject(
                    new Error('Limita maxima de caractere a fost atinsa!'),
                  );
                }

                return Promise.resolve();
              }

              if (value.length > 13) {
                return Promise.reject(
                  new Error('CNP-ul nu trebuie sa depaseasca 13 cifre!'),
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
        name="identityCard"
      >
        <Input
          placeholder="CNP/ CNP Cetatean Strain"
          autocomplete="identity-card"
          maxLength={40}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: `${t('errors.requiredField')}`,
          },
        ]}
        style={{ height: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Input style={{ height: 0 }} />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: t('errors.requiredField') },
          () => ({
            validator(_, value) {
              if (
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{8,}$/.test(
                  value,
                )
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  'Minimum 8 caractere, cel putin o litera majuscula, o litera minuscula, un numar si un caracter special',
                ),
              );
            },
          }),
        ]}
        name="password"
      >
        <Input.Password
          autocomplete="new-password"
          placeholder="Parola"
          // eslint-disable-next-line react/no-unstable-nested-components
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={(evt) => setNewPassword(evt.target.value)}
        />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: t('errors.requiredField') },

          () => ({
            validator(_, value) {
              if (value === newPassword) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Parolele introduse nu coincid!'),
              );
            },
          }),
        ]}
        name="confirmNewPassword"
      >
        <Input.Password
          autocomplete="new-password"
          placeholder="Repeta parola"
          // eslint-disable-next-line react/no-unstable-nested-components
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      {disableReCAPTCHA === 'true' ? (
        ''
      ) : (
        <Form.Item
          name="recaptcha"
          style={{ transform: `scale(${0.9})`, transformOrigin: '0 0' }}
        >
          <ReCAPTCHA
            name="recaptcha"
            sitekey={window._env_.RECAPTCHA_SITE_KEY}
            hl="ro"
          />
        </Form.Item>
      )}
      <div>
        <p /> Prin crearea contului sunt de acord cu{' '}
        <a href="https://tursib.ro/page/nota-info-gdpr">
          Termenii si Conditiile privind prelucrarea datelor cu caracter
          personal
        </a>
      </div>
      <br />
      <Form.Item>
        <Button type="primary" block htmlType="submit">
          {t('startPage.signUp')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
