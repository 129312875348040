import React, { useState } from 'react';

import { Form, Input } from 'antd';
import './Uploader.scss';
import { useTranslation } from 'react-i18next';

const Uploader = ({ props }) => {
  const { names, handleFileUploaded, currentFile, docTypeCode } = props;
  const [selectedFile, setSelectedFile] = useState(currentFile);

  const { t } = useTranslation();
  const onFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    handleFileUploaded(e.target.files[0]);
  };

  return (
    <Form.Item name={names}>
      <div className="row" style={{ display: 'grid', placeItems: 'center' }}>
        <label
          htmlFor={docTypeCode}
          style={{
            background: '#dedcdc',
            padding: '5px 20px',
          }}
        >
          {t(`actions.chooseFile`)}
        </label>
        {selectedFile && (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
            }}
          >
            {selectedFile.name}
          </div>
        )}
      </div>
      <Input
        id={docTypeCode}
        className="input_container"
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => onFileUpload(e)}
      />
    </Form.Item>
  );
};

export default Uploader;
