import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const TitleLogo = () => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center', padding: '2rem 0' }}>
      <Typography.Title level={2}>UrbanGO! - Portal</Typography.Title>
      <Typography.Text type="secondary">
        {t('startPage.titleLogo')}{' '}
      </Typography.Text>
    </div>
  );
};

export default TitleLogo;
