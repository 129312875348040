import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Tooltip,
} from 'antd';
import {
  UploadOutlined,
  RollbackOutlined,
  CheckOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import Uploader from '../Uploader';
import {
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../../services/files';
import { postFiles } from '../../helpers/api';
import { DocTypesEnum } from '../../services/constants';
import { sendOPNotification } from '../../services/orders';
import { ORDER_STATUS } from '../../pages/orders/constants';

const OPModal = ({ props }) => {
  const { t } = useTranslation();
  const [opForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { orderId, setFormValue } = props;

  const [file, setFile] = useState(null);

  return (
    <Space>
      <Button
        htmlFor="filePicker"
        className="ant-btn-warning"
        type="primary"
        htmlType="button"
        onClick={() => setIsModalVisible(true)}
        icon={<UploadOutlined />}
      >
        {t('actions.orders.addOP')}
      </Button>

      <Modal
        title={t('actions.orders.addOP')}
        visible={isModalVisible}
        closable={false}
        width={400}
        footer={
          <Space>
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="button"
              icon={<CheckOutlined />}
              onClick={() =>
                opForm
                  .validateFields()
                  .then(() => {
                    const files = [];
                    files.push(file);
                    postFiles(
                      `${FILE_SERVICE_URI}${FILE_BASE_URI}/${orderId}/files`,
                      files,
                      DocTypesEnum.OP,
                    )
                      .then(() =>
                        sendOPNotification(orderId).then((order) => {
                          setFormValue({
                            ...order,
                            disabled:
                              order.status === ORDER_STATUS.OP_VALIDATION,
                          });
                          setIsModalVisible(false);
                        }),
                      )
                      .catch((msg) => {
                        const { inner } = msg || {};
                        const { _: messageCode } = inner || {};

                        notification.error({
                          message: messageCode
                            ? t(messageCode, inner)
                            : t('errors.errorOccurred'),
                        });
                      });
                  })
                  .catch(() =>
                    notification.error({
                      message: t('errors.invalidData'),
                    }),
                  )
              }
            >
              {t('actions.orders.sendOP')}
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={opForm} layout="vertical">
          <Row justify="space-around" align="top">
            <Col span={22} push={2}>
              <Form.Item
                name={['uploadFile', 'file']}
                rules={[
                  {
                    required: true,
                    message: t('entity.orders.selectFile'),
                  },
                ]}
              >
                <Uploader
                  props={{
                    names: ['uploadFile', 'file'],
                    currentFile: file,
                    docTypeCode: 'OP',
                    handleFileUploaded: (selectedFile) => {
                      setFile(selectedFile);
                      const formVal = opForm.getFieldsValue();
                      opForm.setFieldsValue({
                        ...formVal,
                        uploadFile: {
                          file: selectedFile,
                        },
                      });
                    },
                  }}
                />
              </Form.Item>
            </Col>
            {file && (
              <Col span={2} pull={20} style={{ top: 10 }}>
                <Space style={{ paddingLeft: '1rem' }}>
                  <Tooltip title={t('actions.orders.viewDoc')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<FileTextOutlined />}
                      style={{ color: '#24ad09' }}
                      onClick={() => {
                        const url = window.URL.createObjectURL(file);
                        const a = document.createElement('a');
                        a.href = url;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                      }}
                    />
                  </Tooltip>
                </Space>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </Space>
  );
};
export default OPModal;
