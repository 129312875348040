import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from 'antd';

import { useTranslation } from 'react-i18next';
import { Default } from '../layouts';
import ProfileForm from '../components/ProfileForm';

// import { Space, Tabs, Button, Tooltip, Popconfirm } from 'antd';
// import { FileSyncOutlined, SaveOutlined } from '@ant-design/icons';

const Profile = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Default>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/home')}
        title={t(`entity.users.userProfile`)}
      >
        <ProfileForm />
      </PageHeader>
    </Default>
  );
};

export default Profile;
