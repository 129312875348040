import React, { useEffect } from 'react';
import { Select } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { getSubdivisions } from '../../services/taxonomies';
import FormWrapper from '../FormWrapper';

const SearchSubdivisions = ({ label, props }) => {
  const {
    invalid: errorMessage,
    getValue,
    name,
    disabled,
    field,
    searchField,
    setValue,
    labelProp = 'name',
    institutionId,
  } = props;

  const { loading, content, handleChange, reload } = useDatasource(
    React.useMemo(() => {
      return (data) =>
        institutionId
          ? getSubdivisions({
              ...data,
              pageSize: 1000,
              criterias: {
                ...data.criterias,
                institutionId,
              },
              sort: ['name', 'asc'],
            })
          : Promise.resolve({
              content: [],
              loading: false,
              last: true,
              pageNumber: 1,
              pageSize: 10,
              totalElements: 0,
            });
    }, [institutionId]),
  );
  useEffect(() => reload(), [institutionId, reload]);

  useEffect(() => {
    if (
      content !== null &&
      content?.length > 0 &&
      getValue(name) &&
      //   getValue(name) returns the name the id of institution, not the actual name
      content?.filter((subInstitution) => subInstitution.id === getValue(name))
        .length === 0
    ) {
      setValue(name, null);
    }
  }, [content, getValue, name, setValue]);

  return (
    <FormWrapper.FieldWrapper label={label} errorMessage={errorMessage}>
      <Select
        allowClear
        showSearch
        loading={loading}
        disabled={disabled}
        value={getValue(name)}
        style={{ width: '100%' }}
        onChange={(val) => setValue(name, val)}
        onSearch={(filter) =>
          handleChange(null, { [searchField]: [filter] }, {})
        }
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }
      >
        {content.map((el) => (
          <Select.Option value={el.id} key={el[field]}>
            {typeof labelProp === 'string' ? el[labelProp] : labelProp(el)}
          </Select.Option>
        ))}
      </Select>
    </FormWrapper.FieldWrapper>
  );
};
export default SearchSubdivisions;
