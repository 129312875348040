import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageHeader } from 'antd';

import { Default } from '../../layouts';
import { useRouteParams } from '../../hooks/useRouteParams';
import { getAccountById } from '../../services/accounts';
import Products from '../../components/accounts/Products';

const ViewAccountProducts = () => {
  const { id } = useRouteParams();
  const history = useHistory();
  const location = useLocation();

  const [account, setAccount] = useState(null);
  useEffect(() => {
    getAccountById(id).then(setAccount);
  }, [id]);

  const isDescendent = useMemo(() => {
    return location?.state?.isDescendent;
  }, [location.state]);

  const fromCustomerType = useMemo(() => {
    return location?.state?.fromCustomerType;
  }, [location.state]);

  return (
    <Default>
      {account && (
        <>
          <PageHeader
            onBack={() =>
              history.push({
                pathname: `/accounts/${id}`,
                state: {
                  isDescendent,
                  fromCustomerType,
                },
              })
            }
            title={`${account.userName}`}
          />
          <Products id={account.id} />
        </>
      )}
    </Default>
  );
};

export default ViewAccountProducts;
