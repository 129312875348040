import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Menu } from 'antd';

import AuthContext from '../components/auth';
import { AUTH_BASE_URI, AUTH_SERVICE_URI } from '../services/auth';

const Clean = ({ children }) => {
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const handleLogout = () => {
    window.location.href = `${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/logout`;
  };

  const menuItems = [
    {
      key: 'account',
      label: user.email,
      style: { float: 'right' },
      children: [
        {
          key: 'logout',
          label: t('navigation.logout'),
          onClick: handleLogout,
        },
      ],
    },
  ];

  return (
    <>
      <Layout>
        <Layout.Header
          style={{
            position: 'fixed',
            zIndex: 1000,
            width: '100%',
            display: 'flex',
          }}
        >
          <div style={{ color: 'white', flexGrow: 1 }}>UrbanGO! - Portal</div>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ minWidth: '200px' }}
            items={menuItems}
          />
        </Layout.Header>

        <Layout>
          <Layout
            style={{
              height: '100vh',
              minHeight: '400px',
              borderRight: 0,
              paddingTop: '64px',
            }}
          >
            <div
              style={{
                padding: 24,
                margin: 0,
                height: '100%',
                display: 'flex',
                alignItems: 'stretch',
              }}
            >
              <Layout.Content
                style={{ background: 'white', minHeight: '100%', padding: 24 }}
              >
                {children}
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
      {window._env_.VERSION && (
        <div className="app-version">
          {new Date(
            (parseInt(window._env_.VERSION, 10) || 0) * 1000,
          ).toISOString()}
        </div>
      )}
    </>
  );
};

export default Clean;
