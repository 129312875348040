import { search, get } from '../helpers/api';

export const AUTH_SERVICE_URI = '/portal';
export const AUTH_BASE_URI = '/api/v1/offers';

export const getProducts = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/products`, data);

export const getProductById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/products/${id}`);
