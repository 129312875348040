import React, { useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Row, Col, Tabs, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import { Empty } from '../layouts';

import TitleLogo from '../components/TitleLogo';
import LoginSocialForm from '../components/auth/LoginSocialForm';

const LoginSocial = () => {
  const history = useHistory();
  const location = useLocation();
  const currentTab = useMemo(
    () => location.hash || '#socialRegister',
    [location.hash],
  );

  const handleTabChange = useCallback(
    (tab) => {
      history.push({
        ...location,
        hash: tab,
      });
    },
    [history, location],
  );
  const { t } = useTranslation();
  return (
    <Empty>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <TitleLogo />
        </Col>
      </Row>

      <Row>
        <Col
          style={{
            backgroundColor: 'rgba(255, 255, 255, .85)',
            padding: '1rem',
          }}
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <Tabs activeKey={currentTab} onChange={handleTabChange}>
            <Tabs.TabPane
              tab={t('startPage.socialRegister')}
              key="#socialRegister"
            >
              <Divider style={{ marginTop: 0 }} />
              <LoginSocialForm />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Empty>
  );
};

export default LoginSocial;
