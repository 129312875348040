import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  message,
  Modal,
  notification,
  Row,
  Skeleton,
  Tooltip,
} from 'antd';

import {
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

import dayjs from 'dayjs';
import i18n from 'i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment/moment';
import CartContext from '../CartContextWrapper';
import {
  getOfferTypes,
  getProductsByOfferTypeReq,
  getTitlesByAccountId,
} from '../../services/accounts';
import {
  addProduct,
  rechargeProduct,
  verifyProduct,
} from '../../services/orders';
import { getProductById } from '../../services/products';
import DatePickerLocale from '../DatePickerLocale';
import Restrictions from '../Restrictions';

const TransportTitles = ({
  id,
  cardBlocked,
  isDescendent,
  canAddTitles,
  fromCustomerType,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const { orders, refreshCart } = useContext(CartContext);

  const history = useHistory();
  const [currentProduct, setCurrentProduct] = useState();
  const [productForm] = Form.useForm();
  const [maxValue, setMaxValue] = useState(50);

  const [titles, setTitles] = useState(undefined);
  const [showTitles, setShowTitles] = useState(undefined);
  const [currentOffers, setCurrentOffers] = useState([]);
  const [line, setLine] = useState(null);
  const [whereIsRestriction, setWhereIsRestriction] = useState([]);
  const renderTitle = (title) => {
    if (title.linesName && !title.lineGroupsName) {
      return title.linesName;
    }
    if (title.lineGroupsName && !title.linesName) {
      return title.lineGroupsName;
    }
    if (title.lineGroupsName && title.linesName) {
      return `${title.lineGroupsName}, ${title.linesName}`;
    }
    return null;
  };
  useEffect(() => {
    getTitlesByAccountId(id).then((result) => {
      setTitles(result || []);
      setShowTitles(result.map(renderTitle));
    });
    getOfferTypes().then((result) => {
      const subscriptionOffers = result.find(
        (offer) => offer.code === 'ABONAMENT',
      );
      getProductsByOfferTypeReq(id, subscriptionOffers).then((res) => {
        const updatedProducts = (res || []).map((product) => {
          return {
            ...product,
            payload: product.payload.map((item) => ({
              ...item,
              restrictions: product.productRestrictions || [],
            })),
          };
        });
        setCurrentOffers(updatedProducts);
      });
    });
  }, [id]);

  dayjs.extend(customParseFormat);
  const manageCount = (operationType) => {
    const { count } = productForm.getFieldsValue();
    if (operationType === 'addition' && (!maxValue || maxValue > count)) {
      productForm.setFieldsValue({
        count: productForm.getFieldsValue().count + 1,
      });
    }
    if (operationType === 'subtraction') {
      productForm.setFieldsValue({
        count:
          productForm.getFieldsValue().count <= 0
            ? 1
            : productForm.getFieldsValue().count - 1,
      });
    }
  };

  const handleRechargeProduct = useCallback(
    (value) => {
      const currentSelectedLine = productForm
        .getFieldValue(['product', 'payload'])
        .map((item) => {
          const results = [];
          const foundRestrictions = item.restrictions.find(
            (el) => el.restriction === 'LINE_GROUPS',
          );
          results.push({
            restrictions: foundRestrictions,
            startDate: item.startDate,
          });
          return results;
        })
        .flat();
      const currentTitle = titles.find(
        (el) => el.productId === currentProduct.id,
      );
      const now = new Date();
      const isDateAOutsideInterval =
        currentTitle?.endDateA === null ||
        (currentTitle?.endDateA != null &&
          new Date(currentTitle?.endDateA) < now);
      const isDateBOutsideInterval =
        currentTitle?.endDateB === null ||
        (currentTitle?.endDateB != null &&
          new Date(currentTitle?.endDateB) < now);
      currentSelectedLine.forEach((selectedLine) => {
        if (
          currentTitle &&
          isDateAOutsideInterval === false &&
          isDateBOutsideInterval
        ) {
          const productToRecharge = value;
          delete productToRecharge.product;
          const req = {
            ...productToRecharge,
            count: 1,
            restriction: selectedLine.restrictions,
            startDate: selectedLine.startDate,
            localizedName: currentProduct.localizedName?.[language],
          };
          rechargeProduct(id, req)
            .then(() => {
              refreshCart();
              setCurrentProduct();
              productForm.resetFields();
              message.success({
                content: t('errors.productAddedToCart'),
                key: 'pos',
                duration: 1,
                className: 'card-message',
              });
            })
            .catch((err) => {
              console.error(err);
              notification.error({
                message: t('errors.productNotAddedToCart'),
                description: err.inner._
                  ? t(err.inner._)
                  : t('errors.productNotAddedToCart'),
                duration: 5,
                className: 'card-message',
                style: {
                  width: 1000,
                  marginRight: 200,
                },
              });
            });
        } else {
          const productBody = {
            id: currentProduct.id,
            category: currentProduct.category,
            payload: productForm.getFieldValue(['product', 'payload']),
          };
          addProduct(id, productBody)
            .then(() => {
              refreshCart();
              setCurrentProduct();
              message.success({
                content: t('errors.productAddedToCart'),
                key: 'pos',
                duration: 1,
                className: 'card-message',
              });
            })
            .catch((err) => {
              console.error(err);
              message.error({
                content: err?.inner?._
                  ? t(err?.inner?._)
                  : t('errors.productNotAddedToCart'),
                key: 'pos',
                duration: 1,
                className: 'card-message',
              });
            });
        }
      });
    },
    [currentProduct, productForm, titles, language, id, refreshCart, t],
  );

  const handleExtendProduct = useCallback(
    async (value) => {
      if (value.count < 1) {
        message.error({
          content: t('errors.countError'),
          duration: 2,
          className: 'card-message',
        });
        return;
      }

      const req = {
        id: value.id,
        localizedName: currentProduct.localizedName?.[language],
      };

      if (currentProduct.category === 'PURSE') {
        if (
          currentProduct?.maxValue &&
          value.count * 100 > currentProduct.maxValue
        ) {
          return;
        }
        req.count = value.count * 100;
      } else {
        req.count = value.count;
      }

      verifyProduct(
        currentProduct.id,
        currentProduct.qty ? currentProduct.qty + req.count : req.count,
      )
        .then(() => rechargeProduct(id, req))
        .then(() => {
          refreshCart();
          setCurrentProduct();
          productForm.resetFields();
          message.success({
            content: t('errors.productAddedToCart'),
            key: 'pos',
            duration: 1,
            className: 'card-message',
          });
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: t('errors.productNotAddedToCart'),
            description: err.inner._
              ? t(err.inner._)
              : t('errors.productNotAddedToCart'),
            duration: 5,
            className: 'card-message',
            style: {
              width: 1000,
              marginRight: 200,
            },
          });
        });
    },
    [currentProduct, productForm, id, language, refreshCart, t],
  );

  const getDate = (value, julianStartDate) => {
    // eslint-disable-next-line react/destructuring-assignment
    const date = new Date(
      new Date(julianStartDate).toLocaleDateString('en-US', {
        timeZone: 'Europe/Bucharest',
      }),
    );
    // eslint-disable-next-line react/destructuring-assignment
    date.setDate(date.getDate() + value);
    // eslint-disable-next-line react/destructuring-assignment
    return date;
  };

  const getDefaultDate = useCallback(
    (productId) => {
      if (titles && titles.length > 0) {
        const choosenTitles = titles.filter((it) => it.productId === productId);
        if (choosenTitles.length > 0) {
          return moment(
            new Date(
              Math.max.apply(
                null,
                choosenTitles.map((it) => {
                  const endDateA = getDate(it.valEndDateA, it.julianStartDate);
                  const endDateB = getDate(it.valEndDateB, it.julianStartDate);
                  if (
                    it.productType !== 'PASS' ||
                    (new Date() > endDateA && new Date() > endDateB)
                  ) {
                    return new Date().getTime();
                  }
                  return endDateA > endDateB
                    ? endDateA.getTime()
                    : endDateB.getTime();
                }),
              ),
            ),
          );
        }
      }
      return null;
    },
    [titles],
  );

  const handleChangeProduct = useCallback(
    (product) => {
      const currentProductToAdd =
        orders && orders.length > 0
          ? orders[0].products.find((p) => p.id === product.productId)
          : undefined;

      verifyProduct(
        product.productId,
        currentProductToAdd ? currentProductToAdd.qty + 1 : 0,
      )
        .then(() => {
          getProductById(product.productId)
            .then((value) => {
              const selectedOffer = currentOffers.find(
                (el) => el.id === value.id,
              );
              setCurrentProduct({
                ...value,
                qty: currentProductToAdd ? currentProductToAdd.qty : 0,
              });
              if (product.maxValue) {
                setMaxValue(product.maxValue);
              }

              if (selectedOffer) {
                selectedOffer.payload.forEach((selectedLine) => {
                  // eslint-disable-next-line no-param-reassign
                  selectedLine.startDate = selectedLine.startDate
                    ? moment(selectedLine.startDate)
                    : getDefaultDate(value.id);
                });
                productForm.setFieldsValue({ product: selectedOffer });
              }
              if (value.validityStartType === 'FUTURE') {
                const hasRestriction = selectedOffer.productRestrictions.some(
                  (item) =>
                    item.restriction === 'LINE_GROUPS' &&
                    (item.value1 === 1 ||
                      item.value3 === 1 ||
                      item.value5 === 1 ||
                      item.value7 === 1),
                );
                if (hasRestriction) {
                  const whereIsRestrictionData =
                    selectedOffer.productRestrictions
                      .map((item) => {
                        if (
                          item.restriction === 'LINE_GROUPS' &&
                          (item.value1 === 1 ||
                            item.value3 === 1 ||
                            item.value5 === 1 ||
                            item.value7 === 1)
                        ) {
                          const results = [];
                          if (item.value1 === 1) {
                            results.push('value1');
                          }
                          if (item.value3 === 1) {
                            results.push('value3');
                          }
                          if (item.value5 === 1) {
                            results.push('value5');
                          }
                          if (item.value7 === 1) {
                            results.push('value7');
                          }
                          return results;
                        }
                        return [];
                      })
                      .filter((list) => list.length > 0);

                  const ownedTitle = product !== undefined ? product : null;
                  const now = new Date();
                  const isDateAOutsideInterval =
                    ownedTitle?.endDateA === null ||
                    (ownedTitle?.endDateA != null &&
                      new Date(ownedTitle?.endDateA) < now);
                  const isDateBOutsideInterval =
                    ownedTitle?.endDateB === null ||
                    (ownedTitle?.endDateB != null &&
                      new Date(ownedTitle?.endDateB) < now);
                  const canAddTitleOnEveryLine =
                    ownedTitle === null ||
                    (isDateBOutsideInterval === true &&
                      isDateAOutsideInterval === true);
                  if (
                    selectedOffer?.category === 'PASS' &&
                    hasRestriction &&
                    canAddTitleOnEveryLine === false
                  ) {
                    setLine(ownedTitle?.lines);
                    setWhereIsRestriction(whereIsRestrictionData);
                  }
                }
                productForm.setFieldsValue({
                  id: product.id,
                  count: value.category === 'PASS' ? null : 1,
                  localizedName: product?.productName,
                });
              } else {
                productForm.setFieldsValue({
                  id: product.id,
                  count: 1,
                  localizedName: product?.productName,
                });
              }
            })
            .catch((err) => {
              console.error(err);
              message.error({
                content: err.inner._
                  ? t(err.inner._)
                  : t('errors.productNotAddedToCart'),
                key: 'pos',
                duration: 5,
                className: 'card-message',
              });
            });
        })
        .catch((err) => {
          notification.error({
            message: t('errors.productNotAddedToCart'),
            description: err.inner._
              ? t(err.inner._)
              : t('errors.productNotAddedToCart'),
            duration: 5,
            className: 'card-message',
            style: {
              width: 1000,
              marginRight: 200,
            },
          });
        });
    },
    [orders, currentOffers, productForm, getDefaultDate, t],
  );

  return (
    <>
      <Divider style={{ fontWeight: 500, fontSize: '1.5rem' }}>
        {t('entity.account.transportTitle._plural')}
      </Divider>

      <Skeleton loading={titles === undefined} active>
        <Row gutter={[100, 100]}>
          {(titles || []).map((title, idx) => {
            return (
              <Col span={6} key={title.id}>
                <Badge.Ribbon text={title.productType}>
                  <Card
                    style={{
                      boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.24)',
                      WebkitBoxShadow: '5px 5px 15px 5px rgba(0,0,0,0.24)',
                    }}
                    title={
                      <Tooltip
                        title={
                          title?.localizedName?.[language] ===
                            'Do not defined' ||
                          title?.localizedName?.[language] === null
                            ? title?.localizedName?.ro
                            : title?.localizedName?.[language]
                        }
                      >
                        <span>
                          {title?.localizedName?.[language] ===
                            'Do not defined' ||
                          title?.localizedName?.[language] === null
                            ? title?.localizedName?.ro
                            : title?.localizedName?.[language]}{' '}
                          <Badge count={title.tripQuantity} />{' '}
                        </span>
                      </Tooltip>
                    }
                    bordered
                    actions={[
                      // title.active && (
                      //   <Tooltip title={t('actions.disable')} key="disable">
                      //     <PauseCircleOutlined />
                      //   </Tooltip>
                      // ),
                      // !title.active && (
                      //   <Tooltip title={t('actions.enable')} key="enable">
                      //     <PlayCircleOutlined />
                      //   </Tooltip>
                      // ),
                      <Tooltip title={t('actions.extend')} key="extend">
                        {isDescendent === undefined && !isDescendent ? (
                          <ShoppingCartOutlined
                            onClick={() =>
                              !cardBlocked
                                ? handleChangeProduct(title)
                                : notification.error({
                                    message: t(`errors.cardBlocked`),
                                  })
                            }
                          />
                        ) : (
                          <ShoppingCartOutlined
                            style={{
                              pointerEvents: 'none',
                              color: 'rgba(0, 0, 0, 0.25)',
                            }}
                          />
                        )}
                      </Tooltip>,
                    ].filter((v) => !!v)}
                  >
                    <Descriptions column={1} style={{ height: '96px' }}>
                      {title.productType === 'PURSE' ? (
                        <Descriptions.Item
                          label={t(`entity.account.transportTitle.purseValue`)}
                        >
                          {`${parseFloat(title.purseValue / 100).toFixed(
                            2,
                          )} RON` || ''}
                        </Descriptions.Item>
                      ) : (
                        <Descriptions.Item
                          label={t(`entity.account.transportTitle.validity`)}
                        >
                          {(title.validPeriod || '').split(',').map((str) => (
                            <React.Fragment key={str}>
                              {str}
                              <br />
                            </React.Fragment>
                          ))}
                        </Descriptions.Item>
                      )}
                      {showTitles && showTitles[idx] ? (
                        <Descriptions.Item
                          label={t(`entity.account.transportTitle.lines`)}
                        >
                          {showTitles[idx]}
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                  </Card>
                </Badge.Ribbon>
              </Col>
            );
          })}
          {((isDescendent === undefined && !isDescendent) || canAddTitles) && (
            <Col
              span={6}
              key="purchase"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div style={{ height: '96px' }}>
                <Tooltip title={t('actions.buy')} key="buy">
                  <Button
                    shape="round"
                    disabled={cardBlocked}
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={() =>
                      history.push({
                        pathname: `/accounts/${id}/products`,
                        state: {
                          isDescendent,
                          fromCustomerType,
                        },
                      })
                    }
                  >
                    {t(`actions.buyTitle`)}
                  </Button>
                </Tooltip>
              </div>
            </Col>
          )}
        </Row>
      </Skeleton>
      <Modal
        title={t(`entity.orders.configuration`)}
        visible={currentProduct}
        width={650}
        onCancel={() => {
          setCurrentProduct();
          productForm.resetFields();
        }}
        footer={[
          <div key="modal-footer" className="shopping-modal-footer">
            <Button
              key="cancel"
              onClick={() => {
                setCurrentProduct();
                productForm.resetFields();
              }}
            >
              {t('actions.cancelShopping')}
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                productForm
                  .validateFields()
                  .then((formValue) => {
                    if (currentProduct.category === 'PASS') {
                      handleRechargeProduct(formValue);
                    } else {
                      handleExtendProduct(formValue);
                    }
                  })
                  .catch((info) => {
                    console.error('Validate Failed:', info);
                  });
              }}
            >
              {t('actions.continueShopping')}
            </Button>
            <Button
              key="shoppingCart"
              type="primary"
              onClick={() => {
                productForm
                  .validateFields()
                  .then((formValue) => {
                    if (currentProduct.category === 'PASS') {
                      handleRechargeProduct(formValue);
                    } else {
                      handleExtendProduct(formValue);
                    }
                  })
                  .then(() => history.push(`/checkout`))
                  .catch((info) => {
                    console.error('Validate Failed:', info);
                  });
              }}
            >
              {t('actions.goToShoppingCart')}
            </Button>
          </div>,
        ]}
      >
        <Form form={productForm} layout="vertical">
          <Form.Item hidden name={['id']} />
          {currentProduct &&
            currentProduct.validityStartType === 'FUTURE' &&
            ((<Form.Item hidden name={['count']} />),
            (
              <Form.List name={['product', 'payload']}>
                {(fields) => {
                  return fields.map((field) => (
                    <React.Fragment key={field.key}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.product?.payload[field.key]
                            ?.validityDateStart !==
                          next.product?.payload[field.key]?.validityDateStart
                        }
                      >
                        {currentProduct &&
                          currentProduct.validityStartType === 'FUTURE' && (
                            <Form.Item noStyle name={[field.name, 'startDate']}>
                              <DatePickerLocale
                                showTime
                                format="DD.MM.YYYY HH:mm"
                                shouldNotBeDisabled={false}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          )}
                      </Form.Item>

                      <Form.List name={[field.name, 'restrictions']}>
                        {(fields2) =>
                          fields2.map((field2) => (
                            <Restrictions
                              key={field2.key}
                              form={productForm}
                              line={line}
                              whereIsRestriction={whereIsRestriction}
                              name={[field.name, 'restrictions', field2.name]}
                            />
                          ))
                        }
                      </Form.List>
                    </React.Fragment>
                  ));
                }}
              </Form.List>
            ))}
          {currentProduct && currentProduct.category !== 'PASS' && (
            <Row gutter={16}>
              <Col span={3}>
                <Button
                  type="default"
                  shape="circle"
                  icon={<MinusSquareOutlined />}
                  onClick={() => manageCount('subtraction')}
                />
              </Col>
              {currentProduct.category !== 'PURSE' && (
                <Col span={18}>
                  <Form.Item noStyle name={['count']}>
                    <InputNumber min={1} max={maxValue} />
                  </Form.Item>
                </Col>
              )}
              {currentProduct.category === 'PURSE' && (
                <Col span={18}>
                  <Form.Item noStyle name={['count']}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={1}
                      max={maxValue || Infinity}
                      step={0.01}
                      addonAfter="RON"
                      parser={(purseVal) => purseVal.replace(',', '.')}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={3}>
                <Button
                  type="default"
                  shape="circle"
                  icon={<PlusSquareOutlined />}
                  onClick={() => manageCount('addition')}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default TransportTitles;
