import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Table, PageHeader } from 'antd';

import { Default } from '../../layouts';
import useDatasource from '../../hooks/useDatasource';
import { findAll, remove } from '../../services/orders';
import PageTitle from '../../components/PageTitle';
import AddEntityButton from '../../components/buttons/AddEntityButton';
import EditItemIcon from '../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../components/buttons/DeleteItemIcon';
import Column from '../../helpers/columns';
import { BADGE_COLORS, OrderTypes, ORDER_STATUS } from './constants';

const OrdersList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.orders.id'), {
        width: 100,
        filter: true,
      }),
      {
        title: t('entity.orders.bulkTypes'),
        dataIndex: 'bulkType',
        key: 'bulkType',
        sorter: true,
        width: 150,
        render: (value) => t(`entity.orders.bulkType.${value}`),
      },
      Column.date('createdAt', t('entity.orders.createdAt'), {
        width: 250,
      }),
      Column.dictionary('status', t('entity.orders.status.title'), OrderTypes, {
        filter: true,
        width: 150,
        badgeColor: (value) => BADGE_COLORS[value] || '#fff',
        dictLabel: (entry) => t(`entity.orders.status.${entry.id}`),
      }),
      Column.text('cancelReason', t('entity.orders.cancelReason'), {
        width: 100,
        filter: true,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon path={`/orders/${record.id}`} />
          {record.status === ORDER_STATUS.SELECT_PRODUCTS && (
            <DeleteItemIcon
              title={t('entity.orders._delete', record)}
              message={t('entity.orders._deleted', record)}
              item={record}
              action={remove}
              reload={reload}
            />
          )}
        </span>
      )),
    ],
    [t, reload],
  );

  return (
    <Default>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/home')}
        title={t('actions.backToHomePage')}
      >
        <PageTitle title={t('entity.orders._plural')}>
          <AddEntityButton
            entity={t('entity.orders._singular')}
            path="/orders/new"
          />
        </PageTitle>
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
        />
      </PageHeader>
    </Default>
  );
};

export default OrdersList;
