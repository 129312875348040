import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import {
  getUserByAccountId,
  getUserDetails,
  updateUserByAccountId,
  updateUserDetails,
} from '../services/users';
import {
  getCountries,
  getInstitutions,
  getSubdivisions,
  getUserTypes,
} from '../services/taxonomies';
import useDatasource from '../hooks/useDatasource';
import useDictionaries from '../hooks/useDictionaries';
import { UserTypesEnum } from '../services/constants';
import { getInactiveCardsByUserId } from '../services/accounts';
import Column from '../helpers/columns';
import { useRouteParams } from '../hooks/useRouteParams';

const dictionaries = {
  userTypes: getUserTypes,
  countries: () =>
    getCountries({ pageSize: 200, criterias: { deleted: 'false' } }),
};

const ProfileForm = () => {
  const { id } = useRouteParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [identityCard, setIdentityCard] = useState();
  const [customerType, setCustomerType] = useState();
  const [countryId, setCountryId] = useState();
  const [activeKeys, setActiveKey] = useState(['address2']);
  const { t } = useTranslation();
  const [{ userTypes, countries }] = useDictionaries(dictionaries);
  const [userTypeId, setUserTypeId] = useState(null);
  const [institutionId, setInstitutionId] = useState(null);
  const [subdivisions, setSubdivisions] = useState([]);
  const [showInactiveCardsModal, setShowInactiveCardsModal] = useState(false);
  const [inactiveCardsList, setInactiveCardsList] = useState([]);
  const env = useMemo(() => window._env_.ENVIROMENT, []);
  const columns = useMemo(
    () => [
      Column.text('accountId', t('entity.inactiveCards.accountId'), {
        filter: false,
        sort: false,
      }),
      Column.text('cardTypeCode', t(`entity.inactiveCards.cardType`), {
        filter: false,
        sort: false,
      }),
      {
        title: t(`entity.inactiveCards.dateAdded`),
        dataIndex: 'dateAdded',
        key: 'dateAdded',
        render: (text) => {
          const formattedDate = moment(text).format('DD/MM/YYYY');
          return <span>{formattedDate}</span>;
        },
        filter: false,
        sort: false,
      },
      {
        key: 'physicalId',
        dataIndex: 'physicalId',
        title: t(`entity.inactiveCards.physicalId`),
        render: (text) => {
          const trimmedId = text.replace(/^0+/, '');
          return <span>{trimmedId}</span>;
        },
        filter: false,
        sort: false,
      },
      {
        title: t(`entity.inactiveCards.cardNumber`),
        dataIndex: 'lastDigits',
        key: 'lastDigits',
        render: (text, record) => {
          const content =
            text !== null ? `************${text}` : record.logicIdSerial;
          return <span>{content}</span>;
        },
        filter: false,
        sort: false,
      },
      Column.text('cardExp', t(`entity.inactiveCards.expirationMonth`), {
        filter: false,
        sort: false,
      }),
      Column.text(
        'blacklistReason',
        t(`entity.inactiveCards.blacklistReason`),
        {
          filter: false,
          sort: false,
        },
      ),
    ],
    [t],
  );
  const countryOptions = useMemo(
    () =>
      (countries?.content || []).map((ety) => ({
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries],
  );

  const userTypeCalator = useMemo(
    () => userTypes.content.find((ut) => ut.code === UserTypesEnum.CALATOR),
    [userTypes?.content],
  );

  const districtOptions = useMemo(
    () =>
      (
        (countries?.content || []).find((c) => c.id === countryId)?.districts ||
        []
      ).map((ety) => ({
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries?.content, countryId],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let user;
        if (id) {
          user = await getUserByAccountId(id).catch((err) => {
            notification.error({
              message: err.inner ? t(err.inner._) : t('errors.generic'),
            });

            if (err?.inner?._?.includes('forbidden')) {
              history.push('/forbidden');
            }
          });
        } else {
          user = await getUserDetails();
        }

        form.resetFields();
        form.setFieldsValue(user);
        setIdentityCard(user.identityCard);
        if (user.userTypeId !== null) {
          setUserTypeId(user.userTypeId);
        } else {
          form.setFieldsValue({ userTypeId: userTypeCalator?.id });
          setUserTypeId(userTypeCalator?.id);
        }
        setInstitutionId(user.institutionId);
        setCustomerType(user.customerType);
        setCountryId(user.address2?.countryId);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [form, history, id, t, userTypeCalator]);

  const ids = userTypes.content.find(
    (ut) => ut.id === userTypeId,
  )?.institutions;

  const userTypeCode = userTypes.content.find(
    (ut) => ut.id === userTypeId,
  )?.code;

  const { content, reload } = useDatasource((data) =>
    (ids || []).length > 0
      ? getInstitutions({
          ...data,
          pageSize: 1000,
          criterias: {
            ...data.criterias,
            ids,
          },
        })
      : Promise.resolve({
          content: [],
          loading: false,
          last: true,
          pageNumber: 1,
          pageSize: 10,
          totalElements: 0,
        }),
  );
  useEffect(() => reload(), [ids, userTypeId, reload]);

  useEffect(
    () =>
      getSubdivisions({ pageSize: 1000, criterias: { institutionId } }).then(
        (res) => {
          setSubdivisions(res.content);
        },
      ),
    [institutionId],
  );

  const handleFinish = useCallback(
    (data) => {
      const updateUserPromise = id
        ? updateUserByAccountId(id, data)
        : updateUserDetails(data);

      updateUserPromise
        .then((res) => {
          setIdentityCard(res.identityCard);
          notification.success({ message: 'Datele s-au salvat' });
          history.push('/home');
        })
        .catch(() => {
          notification.error({ message: 'Eroare la salvare date' });
        });
    },
    [history, id],
  );

  const handleChangeUserType = useCallback(
    (newUserType) => {
      setUserTypeId(newUserType);
      form.resetFields(['institutionId']);
      form.resetFields(['subdivisionId']);
    },
    [form],
  );

  const handleChangeInstitution = useCallback(
    (newInstitution) => {
      setInstitutionId(newInstitution);
      form.resetFields(['subdivisionId']);
    },
    [form],
  );

  const handleFinishFailed = () => {
    setActiveKey('address2');
  };
  const handleOk = () => {
    setShowInactiveCardsModal(false);
  };

  const openInactiveCardsModal = () => {
    setShowInactiveCardsModal(true);
    getInactiveCardsByUserId().then((res) => setInactiveCardsList(res));
  };
  let infoText = '';
  switch (env) {
    case 'sibiu':
      infoText = t('entity.users.infoSibiu');
      break;
    case 'tgmures':
      infoText = t('entity.users.infoTgMures');
      break;
    case 'slobozia':
      infoText = t('entity.users.infoSlobozia');
      break;
    case 'carges':
      infoText = t('entity.users.infoCArges');
      break;
    default:
      infoText = t('entity.users.info');
  }

  return (
    <>
      <Form
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        form={form}
        id="profile"
        name="userForm"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="email" label={t(`entity.users.email`)}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="fullname" label={t(`entity.users.fullName`)}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => {
            return prev?.identityCard !== next?.identityCard;
          }}
        >
          {() => {
            return customerType === 'INDIVIDUAL' ? (
              <Form.Item
                name="identityCard"
                label={t(`entity.users.identityCard`)}
              >
                <Input disabled={identityCard} />
              </Form.Item>
            ) : (
              <Form.Item
                name="identityCard"
                label={t(`entity.users.legalIdentityCard`)}
              >
                <Input disabled />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label={t(`entity.users.address.address`)}>
          <Collapse
            activeKey={activeKeys}
            onChange={() => setActiveKey(activeKeys ? null : 'address2')}
          >
            <CollapsePanel key="address2">
              <Form.Item
                name={['address2', 'countryId']}
                label={t(`entity.users.address.country`)}
                rules={[
                  {
                    required: true,
                    message: `${t('errors.requiredField')}`,
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  options={countryOptions}
                  onChange={(key) => {
                    setCountryId(key);
                    const formVal = form.getFieldsValue();
                    form.setFieldsValue({
                      ...formVal,
                      address2: { ...formVal.address2, districtId: null },
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['address2', 'districtId']}
                label={t(`entity.users.address.district`)}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        countryId &&
                        !value &&
                        (districtOptions || []).length > 0 &&
                        (countries?.content || []).find(
                          (item) => item.id === countryId,
                        )?.code === 'RO'
                      ) {
                        setActiveKey('address2');
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  }),
                  {
                    required:
                      (countries?.content || []).find(
                        (item) => item.id === countryId,
                      )?.code === 'RO',
                    message: `${t('errors.requiredField')}`,
                  },
                ]}
              >
                <Select
                  options={districtOptions}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                name={['address2', 'locality']}
                label={t(`entity.users.address.locality`)}
                rules={[
                  {
                    required:
                      (countries?.content || []).find(
                        (item) => item.id === countryId,
                      )?.code === 'RO',
                    message: `${t('errors.requiredField')}`,
                  },
                ]}
              >
                <Input maxLength={32} />
              </Form.Item>
              <Form.Item
                name={['address2', 'street']}
                label={t(`entity.users.address.street`)}
              >
                <Input maxLength={32} />
              </Form.Item>
              <Form.Item
                name={['address2', 'number']}
                label={t(`entity.users.address.number`)}
              >
                <Input maxLength={8} />
              </Form.Item>
              <Form.Item
                name={['address2', 'building']}
                label={t(`entity.users.address.building`)}
              >
                <Input maxLength={8} />
              </Form.Item>
              <Form.Item
                name={['address2', 'apartment']}
                label={t(`entity.users.address.apartment`)}
              >
                <Input maxLength={8} />
              </Form.Item>
              <Form.Item
                name={['address2', 'details']}
                label={t(`entity.users.address.details`)}
              >
                <Input maxLength={64} />
              </Form.Item>
            </CollapsePanel>
          </Collapse>
        </Form.Item>
        <Form.Item
          name="phone"
          label={t(`entity.users.phone`)}
          rules={[
            {
              required: true,
              message: `${t('errors.requiredField')}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="customerType" label={t(`entity.users.customerType`)}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.customerType !== next.customerType ||
            prev.billingAddressEqualsDeliveryAddress !==
              next.billingAddressEqualsDeliveryAddress
          }
        >
          {({ getFieldValue }) => {
            return customerType === 'LEGAL' ? (
              <>
                <Form.Item
                  label={t('entity.users.billingAddressEqualsDeliveryAddress')}
                  valuePropName="checked"
                  name="billingAddressEqualsDeliveryAddress"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="deliveryAddress"
                  label={t(`entity.users.deliveryAddress`)}
                >
                  <Input />
                </Form.Item>

                {getFieldValue('billingAddressEqualsDeliveryAddress') ? null : (
                  <Form.Item
                    name="billingAddress"
                    label={t(`entity.users.billingAddress`)}
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            ) : null;
          }}
        </Form.Item>
        <Form.Item
          name="userTypeId"
          label={t(`entity.users.userType`)}
          rules={[
            {
              required: true,
              message: `${t('errors.requiredField')}`,
            },
          ]}
        >
          <Select onChange={handleChangeUserType} disabled>
            {userTypes.content.map((ut) => (
              <Select.Option key={ut.id} value={ut.id}>
                {t(`entity.orders.userType.${ut.code}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {UserTypesEnum.ELEV === userTypeCode ? (
          <h4 style={{ marginLeft: '25%' }}>{t(`entity.users.studentInfo`)}</h4>
        ) : (
          ''
        )}
        {UserTypesEnum.CALATOR !== userTypeCode ||
        (UserTypesEnum.CALATOR === userTypeCode && institutionId != null) ? (
          <Form.Item name="institutionId" label={t(`entity.users.institution`)}>
            <Select onChange={handleChangeInstitution} disabled>
              {content.map((act) => (
                <Select.Option key={act.id} value={act.id}>
                  {act.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          ''
        )}
        {(UserTypesEnum.CALATOR !== userTypeCode ||
          (UserTypesEnum.CALATOR === userTypeCode && institutionId != null)) &&
        [UserTypesEnum.PENSIONAR_CONVENTIE, UserTypesEnum.PENSIONAR].includes(
          userTypeCode,
        ) ? (
          <Form.Item name="metaData1" label={t(`entity.users.subdivision`)}>
            <Input disabled />
          </Form.Item>
        ) : (
          ''
        )}
        {(UserTypesEnum.CALATOR !== userTypeCode ||
          (UserTypesEnum.CALATOR === userTypeCode && institutionId != null)) &&
        ![UserTypesEnum.PENSIONAR_CONVENTIE, UserTypesEnum.PENSIONAR].includes(
          userTypeCode,
        ) ? (
          <Form.Item name="subdivisionId" label={t(`entity.users.subdivision`)}>
            <Select disabled>
              {subdivisions.map((sd) => (
                <Select.Option key={sd.id} value={sd.id}>
                  {sd.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          ''
        )}

        <Form.Item name="inactiveCards" label="Carduri inactive">
          <Button type="primary" onClick={() => openInactiveCardsModal()}>
            {t('entity.inactiveCards.modalAccessButton')}
          </Button>
        </Form.Item>
        <h3 style={{ textAlign: 'center', color: 'red' }}>{infoText}</h3>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal
        style={{ minWidth: 'fit-content' }}
        title={t('entity.inactiveCards.inactiveCardsListTitle')}
        visible={showInactiveCardsModal}
        closable={false}
        open={showInactiveCardsModal}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        {!inactiveCardsList.length && (
          <h2>{t('entity.inactiveCards.noCards')}</h2>
        )}
        {inactiveCardsList.length > 0 && (
          <Table
            dataSource={inactiveCardsList}
            columns={columns}
            pagination={false}
          />
        )}
      </Modal>
    </>
  );
};

export default ProfileForm;
