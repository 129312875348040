import React, { useState, useEffect, useMemo, useContext } from 'react';

import { getActiveOrders } from '../services/orders';
import AuthContext from './auth';

const CartContext = React.createContext(null);

export const CartContextWrapper = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (user) {
      getActiveOrders()
        .then((res) => {
          setOrders(res);
        })
        .catch((err) => {
          console.log(err);
          setOrders([]);
        });
    } else {
      setOrders([]);
    }
  }, [user]);

  const contextValue = useMemo(
    () => ({
      orders,
      setOrders,
      refreshCart: () => {
        if (user) {
          getActiveOrders()
            .then((res) => {
              setOrders(res);
            })
            .catch((err) => {
              console.log(err);
              setOrders([]);
            });
        } else {
          setOrders([]);
        }
      },
    }),
    [orders, user],
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};

export default CartContext;
