import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GB, RO } from 'country-flag-icons/react/1x1';
import {
  Layout,
  Menu,
  Popover,
  Button,
  Divider,
  Table,
  Typography,
} from 'antd';
import {
  ShoppingCartOutlined,
  DeleteOutlined,
  OrderedListOutlined,
  HomeOutlined,
  CarOutlined,
  ContainerOutlined,
} from '@ant-design/icons';

import i18n from 'i18next';
import AuthContext from '../components/auth';
import CartContext from '../components/CartContextWrapper';
import { AUTH_SERVICE_URI, AUTH_BASE_URI } from '../services/auth';
import Column from '../helpers/columns';
import { removeCartItem } from '../services/orders';
import { CUSTOMER_TYPE } from '../pages/orders/constants';
import { getAccountType } from '../helpers/util';

const { Text } = Typography;

const Default = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { orders, setOrders } = useContext(CartContext);

  const mapLink = useMemo(() => window._env_.MAP_URL, []);

  const history = useHistory();
  const { t } = useTranslation();
  const languages = {
    ro: <RO style={{ width: `70%`, height: `50%`, paddingTop: '50%' }} />,
    en: <GB style={{ width: `70%`, height: `50%`, paddingTop: '50%' }} />,
  };
  const [languageTitle, setLanguageTitle] = useState(() => {
    if (
      sessionStorage.getItem('language') &&
      sessionStorage.getItem('language') !== 'ro'
    ) {
      i18n.changeLanguage(sessionStorage.getItem('language'));
      return languages[sessionStorage.getItem('language')];
    }
    return languages.ro;
  });

  const handleLogout = () => {
    sessionStorage.removeItem('language');
    window.location.href = `${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/logout`;
  };

  const handleOnclick = (e) => {
    if (e === 'en') {
      sessionStorage.setItem('language', 'en');
      setLanguageTitle(languages.en);
    } else if (e === 'ro') {
      sessionStorage.setItem('language', 'ro');
      setLanguageTitle(languages.ro);
    }
    i18n.changeLanguage(e);
  };

  const columns = useMemo(
    () => [
      Column.text(
        'selectedProductName',
        t('entity.account.transportTitle.name'),
        {
          filter: false,
          sort: false,
        },
      ),
      {
        title: t(`entity.account.transportTitle.quantity`),
        dataIndex: 'qty',
        key: 'qty',
        sorter: false,
        width: 200,
        render: (value, row) => (row.category === 'PURSE' ? 1 : value),
      },
      Column.text(
        'validPeriod',
        t(`entity.account.transportTitle.validPeriod`),
        {
          filter: false,
          sort: false,
        },
      ),
      {
        title: `${t(`entity.offers.product.price`)} (RON)`,
        dataIndex: 'price',
        key: 'price',
        sorter: false,
        width: 200,
        render: (value, row) =>
          row.category === 'PURSE'
            ? parseFloat((value * row.qty) / 100).toFixed(2)
            : parseFloat(value / 100).toFixed(2),
      },
      Column.actions(t(`actions.delete`), (record) => (
        <Button
          type="default"
          onClick={() =>
            removeCartItem(record.accountId, record.id).then((res) => {
              setOrders(res);
            })
          }
          shape="circle"
          icon={<DeleteOutlined />}
        />
      )),
    ],
    [setOrders, t],
  );

  const totalAmount =
    orders
      .map((order) => order.products)
      .flat()
      .reduce((accumulator, object) => {
        return accumulator + object.price * object.qty;
      }, 0) / 100;
  const text = <span>{t('entity.orders.shoppingCart')}</span>;
  const content = (
    <div style={{ width: 700, maxHeight: '450px', overflowY: 'auto' }}>
      {orders.map((cartElement) => (
        <div key={cartElement.id}>
          <Divider>
            {getAccountType(cartElement.account.userCustomerType, t)} -{' '}
            {cartElement.account.userFullname}
          </Divider>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={cartElement.products.map((product) => ({
              ...product,
              accountId: cartElement.account.accountId,
            }))}
            pagination={false}
          />
        </div>
      ))}
      <div
        style={{
          marginTop: '.5rem',
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          key="submit"
          type="primary"
          onClick={() => history.push(`/checkout`)}
        >
          {t('actions.toShoppingCart')}
        </Button>
      </div>
    </div>
  );

  const menuItems = [
    {
      key: 'tour-planing',
      icon: <CarOutlined />,
      label: t('actions.planRoute'),
      style: { color: 'white', float: 'right' },
      onClick: () => window.open(mapLink),
    },
    ...([CUSTOMER_TYPE.INDIVIDUAL, CUSTOMER_TYPE.PAN].includes(
      user.customerType,
    )
      ? [
          {
            key: 'shopping-cart',
            label: (
              <Popover
                placement="bottom"
                title={text}
                content={content}
                trigger="click"
              >
                <Button
                  style={{ backgroundColor: '#1890ff', color: 'white' }}
                  type="default"
                  shape="circle"
                  icon={<ShoppingCartOutlined />}
                />
                <Text style={{ color: 'white', marginLeft: '.5rem' }}>
                  {`${totalAmount} RON`}
                </Text>
              </Popover>
            ),
          },
        ]
      : []),
    ...(user.customerType === 'LEGAL'
      ? [
          {
            key: 'orders',
            icon: <OrderedListOutlined />,
            label: t('entity.orders._plural'),
            style: { color: 'white', float: 'right' },
            children: [
              {
                key: 'new-order',
                icon: <ShoppingCartOutlined />,
                label: t('entity.orders.new'),
                onClick: () => history.push('/orders/new'),
              },
              {
                key: 'orders-list',
                icon: <OrderedListOutlined />,
                label: t('entity.orders.all'),
                onClick: () => history.push('/orders'),
              },
            ],
          },
        ]
      : []),
    ...(user.customerType === 'INDIVIDUAL'
      ? [
          {
            key: 'indivdual-orders',
            icon: <OrderedListOutlined />,
            label: t('actions.issue_reload'),
            style: { color: 'white', float: 'right' },
            children: [
              {
                key: 'new-individual-order',
                icon: <ShoppingCartOutlined />,
                label: t('entity.orders.new'),
                onClick: () => history.push('/individual-orders/new'),
              },
              {
                key: 'individual-orders-list',
                icon: <OrderedListOutlined />,
                label: t('entity.orders.all'),
                onClick: () => history.push('/individual-orders'),
              },
            ],
          },
          {
            key: 'receipts',
            icon: <ContainerOutlined />,
            label: t('entity.orders.receipts.title'),
            style: { color: 'white', float: 'right' },
            onClick: () => history.push('/receipts'),
          },
        ]
      : []),
    {
      key: 'language',
      label: languageTitle,
      style: { display: 'flex' },
      children: [
        {
          key: 'english',
          label: (
            <>
              <GB style={{ width: `15%`, height: `30%` }} /> English
            </>
          ),
          style: { color: 'white' },
          onClick: () => handleOnclick('en'),
        },
        {
          key: 'romanian',
          label: (
            <>
              <RO style={{ width: `15%`, height: `30%` }} /> Română
            </>
          ),
          style: { color: 'white' },
          onClick: () => handleOnclick('ro'),
        },
      ],
    },
    {
      key: 'user',
      label: user.email,
      style: {
        color: 'white',
        float: 'right',
      },
      children: [
        {
          key: 'account',
          label: t(`entity.users.userProfile`),
          style: { color: 'white' },
          onClick: () => history.push('/profile'),
        },
        {
          key: 'logout',
          label: t('navigation.logout'),
          style: { color: 'white' },
          onClick: () => handleLogout(),
        },
      ],
    },
  ];

  return (
    <>
      <Layout>
        <Layout.Header
          style={{
            position: 'fixed',
            zIndex: 1000,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#1890ff',
          }}
        >
          <div style={{ color: 'white', flexGrow: 1 }}>
            <div className="logo-container">
              <HomeOutlined
                onClick={() => history.push('/home')}
                style={{
                  fontSize: '1.8rem',

                  marginTop: '1rem',
                }}
              />
              <Button
                style={{
                  backgroundColor: '#1890ff',
                  color: 'white',
                  textAlign: 'left',
                }}
                type="link"
                onClick={() => history.push('/home')}
              >
                <div>Tursib</div>
                <div>UrbanGO! - Portal</div>
              </Button>
            </div>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{
              width: '100%',
              minWidth: '80%',
              backgroundColor: '#1890ff',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
            items={menuItems}
          />
        </Layout.Header>

        <div
          style={{
            // padding: 25,
            marginTop: 65,
            height: '100vh',
          }}
        >
          <Layout.Content
            style={{ background: 'white', minHeight: '100%', padding: 24 }}
          >
            {children}
          </Layout.Content>
        </div>
        {/* <Content>{children}</Content> */}
        {/* <Footer>Footer</Footer> */}
      </Layout>

      {window._env_.VERSION && (
        <div className="app-version">
          {new Date(
            (parseInt(window._env_.VERSION, 10) || 0) * 1000,
          ).toISOString()}
        </div>
      )}
    </>
  );
};

export default Default;
