import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  PageHeader,
  Radio,
  Result,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import FormItem from 'antd/lib/form/FormItem';
import { Default } from '../layouts';

import CartContext from '../components/CartContextWrapper';

import Column from '../helpers/columns';

import {
  addInvoiceType,
  addLegalFieldsWithOrderId,
  removeCartItem,
} from '../services/orders';
import {
  getUserByAccountId,
  getUserDetails,
  validateCui,
} from '../services/users';

import useDictionaries from '../hooks/useDictionaries';
import { getCountries } from '../services/taxonomies';
import AuthContext from '../components/auth';
import { getAccountType } from '../helpers/util';
import { CUSTOMER_TYPE } from './orders/constants';

const dictionaries = {
  countries: () =>
    getCountries({
      pageSize: 200,
      sort: ['name', 'asc'],
      criterias: { deleted: 'false' },
    }),
};

const Checkout = () => {
  const { user } = useContext(AuthContext);
  const { orders, setOrders } = useContext(CartContext);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [vatEntity, setVatEntity] = useState(null);
  const [identityCard, setIdentityCard] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [activeKeys, setActiveKey] = useState(['address2']);
  const [country, setCountry] = useState(null);
  const [{ countries }] = useDictionaries(dictionaries);
  const { Text } = Typography;

  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { setFieldsValue, resetFields } = form;

  const countryOptions = useMemo(
    () =>
      (countries?.content || []).map((ety) => ({
        ...ety,
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries],
  );

  const districtOptions = useMemo(
    () =>
      (
        (countries?.content || []).find((c) => c.id === country)?.districts ||
        []
      ).map((ety) => ({
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries?.content, country],
  );

  const { search } = useLocation();
  const [isPfi, setIsPfi] = useState(false);
  const [orderAccount, setOrderAccount] = useState(false);
  const [userIdentityCard, setUserIdentityCard] = useState(null);

  const options = useMemo(() => {
    const baseOptions = [
      { label: t('entity.users.customerTypes.pf'), value: 'PF' },
      { label: t('entity.users.customerTypes.pj'), value: 'PJ' },
    ];

    if (isPfi) {
      baseOptions.splice(1, 0, {
        label: t('entity.users.customerTypes.pfi'),
        value: 'PFI',
      });
    }

    return baseOptions;
  }, [isPfi, t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isPfi) {
          const result = await getUserDetails();
          setUserIdentityCard(result.identityCard);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchData();
  }, [isPfi]);

  const [invoiceType, setInvoiceType] = useState(options[0].value);

  const { status, msg, messageDetails } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      // eslint-disable-next-line no-nested-ternary
      status: params.get('status') || null,
      msg: params.get('message') || null,
      messageDetails: params.get('messageDetails') || null,
    };
  }, [search]);

  const subTtitleMsg = useMemo(() => {
    const subTitleMessage = msg != null ? t(msg) : '';
    const subTitileMsgDetails = messageDetails !== null ? messageDetails : '';
    return `${subTitleMessage} \n ${subTitileMsgDetails}`;
  }, [t, msg, messageDetails]);

  const history = useHistory();
  const columns = useMemo(
    () => [
      Column.text(
        'selectedProductName',
        t('entity.account.transportTitle.name'),
        {
          filter: false,
          sort: false,
        },
      ),
      {
        title: t(`entity.account.transportTitle.quantity`),
        dataIndex: 'qty',
        key: 'qty',
        sorter: false,
        width: 200,
        render: (value, row) => (row.category === 'PURSE' ? 1 : value),
      },
      Column.text(
        'validPeriod',
        t(`entity.account.transportTitle.validPeriod`),
        {
          filter: false,
          sort: false,
        },
      ),
      {
        title: `${t(`entity.offers.product.price`)} (RON)`,
        dataIndex: 'price',
        key: 'price',
        sorter: false,
        width: 200,
        render: (value, row) =>
          row.category === 'PURSE'
            ? parseFloat((value * row.qty) / 100).toFixed(2)
            : parseFloat(value / 100).toFixed(2),
      },
      Column.actions(t(`actions.delete`), (record) => {
        return (
          <Button
            type="default"
            onClick={() =>
              removeCartItem(record.accountId, record.id).then((res) => {
                setOrders(res);
              })
            }
            shape="circle"
            icon={<DeleteOutlined />}
          />
        );
      }),
    ],
    [setOrders, t],
  );

  const totalAmount = useMemo(
    () =>
      orders
        .map((order) => order.products)
        .flat()
        .reduce((accumulator, object) => {
          return accumulator + object.price * object.qty;
        }, 0) / 100,
    [orders],
  );

  const subTotal = (currentOrder) => {
    return (
      currentOrder.products.reduce((accumulator, object) => {
        return accumulator + object.price * object.qty;
      }, 0) / 100
    );
  };

  const validateIdentityCard = useCallback(() => {
    const controlNumber = identityCard % 10;
    let cif = Math.floor(identityCard / 10);
    let key = 753217532;
    let sum = 0;
    while (key > 0) {
      sum += (key % 10) * (cif % 10);
      cif = Math.floor(cif / 10);
      key = Math.floor(key / 10);
    }
    const currentControlNumber = ((sum * 10) % 11) % 10;
    return currentControlNumber === controlNumber;
  }, [identityCard]);

  const validate = useCallback(() => {
    if (identityCard) {
      if (
        (countryCode === 'RO' || countryCode === '') &&
        !validateIdentityCard()
      ) {
        message.error({
          content: t('errors.invalidIdentityCard'),
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
        setVatEntity(null);
        resetFields();
        return;
      }
      if (countryCode !== '') {
        validateCui(countryCode, identityCard)
          .then((resp) => {
            const { isValid, companyName } = resp;
            if (isValid) {
              setFieldsValue({ fullname: companyName });
            } else {
              notification.error({
                message: t(`errors.taxDataCouldNotBeVerified`),
              });
            }
            setVatEntity(resp);
          })
          .catch((err) => {
            setVatEntity(null);
            resetFields();
            if (err?.inner) {
              if (err?.inner?.status === 400) {
                notification.error({
                  message: t(`errors.identificationCodeNotFound`),
                });
              }
            } else {
              err
                .json(err)
                .then((res) => notification.error({ message: res?.message }));
            }
          });
      } else {
        setVatEntity({ isValid: false });
      }
    }
  }, [
    identityCard,
    countryCode,
    validateIdentityCard,
    t,
    setFieldsValue,
    resetFields,
  ]);

  return (
    <Default>
      <>
        <PageHeader
          className="site-page-header"
          onBack={() => history.push('/home')}
          title={t(`entity.shoppingCart._`)}
        />
        {status !== null && (
          <Result
            status={status}
            title={status === 'success' ? 'Payment complete' : 'Payment Failed'}
            subTitle={status === 'success' ? '' : subTtitleMsg}
          />
        )}
        {orders.map((cartElement) => (
          <div key={cartElement.id}>
            <Divider>
              {getAccountType(cartElement.account.userCustomerType, t)} -{' '}
              {cartElement.account.userFullname}
            </Divider>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={cartElement.products.map((product) => ({
                ...product,
                accountId: cartElement.account.accountId,
              }))}
              pagination={false}
            />
            <div>
              <h3>Subtotal: {subTotal(cartElement)} RON</h3>
            </div>
            <div
              style={{
                marginTop: '.5rem',
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <form ref={formRef} method="POST" key={cartElement.id}>
                <input
                  type="hidden"
                  name="x-tenant-key"
                  value={window._env_.API_TENANT_KEY}
                />
                {cartElement.products.length > 0 && (
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      formRef.current.action = `${window._env_.API_BACKEND_URL}/portal/api/v1/orders/checkout/${user.id}/order/${cartElement.id}`;
                      setShowModal(true);
                      setOrderId(cartElement.id);
                      setIsPfi(
                        cartElement.account.userCustomerType !==
                          CUSTOMER_TYPE.INDIVIDUAL
                          ? false
                          : !(
                              cartElement.account.userCustomerType ===
                                user.customerType &&
                              cartElement.account.userFullname === user.fullname
                            ),
                      );
                      setOrderAccount(cartElement.account);
                    }}
                  >
                    {t(`entity.shoppingCart.finish`)}
                  </Button>
                )}
              </form>
            </div>
          </div>
        ))}
        <div>
          <h1>Total: {totalAmount} RON</h1>
        </div>
        <Modal
          title={t('entity.stocks.invoice.generateInvoice')}
          visible={showModal}
          closable={false}
          width={isPfi ? 600 : 500}
          footer={[
            (invoiceType === 'PF' ||
              invoiceType === 'PFI' ||
              vatEntity != null) && (
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  if (invoiceType === 'PF') {
                    getUserDetails().then((result) => {
                      if (
                        result.address2 &&
                        result.address2.countryId &&
                        result.address2.districtId &&
                        result.address2.locality
                      ) {
                        addInvoiceType({
                          invoiceType,
                          orderId,
                          orderAccountId: orderAccount.accountId,
                        }).then(() => {
                          formRef.current.submit();
                        });
                      } else {
                        message.error({
                          content: (
                            <>
                              <p>{t('errors.requiredAddress')}</p>
                              <a href="/profile">
                                {t('entity.users.userProfile')}
                              </a>
                            </>
                          ),
                          duration: 5,
                          className: 'card-message',
                        });
                      }
                    });
                  } else if (invoiceType === 'PFI') {
                    getUserByAccountId(orderAccount.accountId).then(
                      (result) => {
                        if (
                          result.address2 &&
                          result.address2.countryId &&
                          result.address2.districtId &&
                          result.address2.locality
                        ) {
                          addInvoiceType({
                            invoiceType,
                            orderId,
                            orderAccountId: orderAccount.accountId,
                          }).then(() => {
                            formRef.current.submit();
                          });
                        } else {
                          message.error({
                            content: (
                              <>
                                <p>{t('errors.requiredAddress')}</p>
                                <a href={`/profile/${orderAccount.accountId}`}>
                                  {t('entity.users.userProfile')}
                                </a>
                              </>
                            ),
                            duration: 5,
                            className: 'card-message',
                          });
                        }
                      },
                    );
                  } else {
                    form
                      .validateFields()
                      .then((res) =>
                        addLegalFieldsWithOrderId(res, orderId).then(
                          formRef.current.submit(),
                        ),
                      );
                  }
                }}
              >
                {t('actions.toPayment')}
              </Button>
            ),
            <Button
              key="close"
              type="primary"
              onClick={() => {
                setShowModal(false);
                setOrderId(null);
              }}
            >
              {t(`actions.close`)}
            </Button>,
          ]}
        >
          {t('entity.stocks.invoice.selectInvoiceType')}{' '}
          <div>
            <Radio.Group
              value={invoiceType}
              defaultValue={invoiceType}
              options={options}
              onChange={(e) => {
                setInvoiceType(e?.target.value);
                resetFields();
                setVatEntity(null);
              }}
            />
          </div>
          {isPfi && (
            <div style={{ marginTop: '16px' }}>
              <Row>
                <Col span={6}>{user.fullname}</Col>
                <Col span={1} />
                <Col span={6}>{orderAccount.userFullname}</Col>
              </Row>
              <Row>
                <Col span={6}>{userIdentityCard}</Col>
                <Col span={1} />
                <Col span={6}>{orderAccount.userIdentityCard}</Col>
              </Row>
            </div>
          )}
          {invoiceType === 'PJ' && (
            <div style={{ marginTop: '16px' }}>
              {t('entity.stocks.invoice.enterCui')}
              <Form form={form}>
                <Row>
                  <Col span={7}>
                    <FormItem name="countryCode">
                      <Select
                        onChange={(val) => {
                          setVatEntity(null);
                          setCountryCode(val);
                        }}
                      >
                        {[{ code: 'Neplatitor TVA' }, ...countryOptions].map(
                          ({ code }) => (
                            <Select.Option
                              key={code}
                              value={code !== 'Neplatitor TVA' ? code : ''}
                            >
                              {code}
                            </Select.Option>
                          ),
                        )}{' '}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={11}>
                    <FormItem
                      name="identityCard"
                      rules={[
                        {
                          required: true,
                          message: t('errors.requiredField'),
                        },
                        {
                          type: 'string',
                          min: 1,
                          message: t('errors.invalidField'),
                        },
                      ]}
                    >
                      <Input
                        onChange={(v) => {
                          const { value } = v.target;
                          setIdentityCard(value);
                          setFieldsValue({ identityCard: value });
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <Button
                      style={{
                        width: '100%',
                      }}
                      type="primary"
                      onClick={validate}
                    >
                      {t('actions.validate')}
                    </Button>
                  </Col>
                </Row>

                {vatEntity && (
                  <>
                    {
                      (vatEntity.isValid && (
                        <Row>
                          <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                            {vatEntity.companyName}
                          </Text>
                        </Row>
                      ),
                      (
                        <Row>
                          <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                            {vatEntity.companyAddress}
                          </Text>
                        </Row>
                      ))
                    }
                    <Form.Item
                      name="fullname"
                      label={t('entity.users.fullName')}
                      rules={[
                        {
                          required: true,
                          message: t('errors.notEmpty'),
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          setFieldsValue({
                            fullname: e.target.value.toUpperCase(),
                          });
                        }}
                      />
                    </Form.Item>
                    <Collapse
                      activeKey={activeKeys}
                      onChange={() =>
                        setActiveKey(activeKeys ? null : 'address2')
                      }
                    >
                      <CollapsePanel key="address2">
                        <Form.Item
                          name="countryId"
                          label={t('entity.users.address.country')}
                          rules={[
                            {
                              required: true,
                              message: t('errors.mustSelected'),
                            },
                          ]}
                        >
                          <Select
                            options={countryOptions}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(v1) => {
                              setCountry(v1);
                              const value = countries?.content?.filter(
                                (c) => c.id === country,
                              )[0]?.code;
                              if (value === 'RO') {
                                form.setFieldsValue({
                                  district: null,
                                });
                              } else {
                                form.setFieldsValue({
                                  districtId: null,
                                });
                              }
                            }}
                          >
                            {countryOptions.map((opt) => (
                              <Select.Option key={opt.id} value={opt.id}>
                                {opt.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {countries?.content?.filter((c) => c.id === country)[0]
                          ?.code === 'RO' ? (
                          <Form.Item
                            name="districtId"
                            label={t('entity.users.address.districtId')}
                            rules={[
                              {
                                required: true,
                                message: t('errors.mustSelected'),
                              },
                            ]}
                          >
                            <Select
                              options={districtOptions}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.label
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name="district"
                            label={t('entity.users.address.district')}
                            rules={[
                              {
                                required: true,
                                message: t('errors.mustSelected'),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        )}
                        <Form.Item
                          name="locality"
                          label={t('entity.users.address.locality')}
                          rules={[
                            {
                              required: true,
                              message: t('errors.notEmpty'),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="street"
                          label={t('entity.users.address.street')}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="number"
                          label={t('entity.users.address.number')}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="building"
                          label={t('entity.users.address.building')}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="apartment"
                          label={t('entity.users.address.apartment')}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="details"
                          label={t('entity.users.address.details')}
                        >
                          <Input />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>
                  </>
                )}
              </Form>
            </div>
          )}
        </Modal>
      </>
    </Default>
  );
};

export default Checkout;
