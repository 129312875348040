import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Descriptions, Divider, Form, Select, Typography } from 'antd';
import { getRouteGroups } from '../services/restrictions';

const Restriction = ({ form, restrictions, line, whereIsRestriction }) => {
  const { t } = useTranslation();
  const [routeGroups, setRouteGroups] = useState([]);
  const {
    restriction,
    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
    value8,
  } = restrictions;

  useEffect(() => {
    const fetchRouteGroups = async () => {
      try {
        const resp = await getRouteGroups();
        setRouteGroups(
          resp.reduce((acc, val) => ({ ...acc, [val.id]: val }), {}),
        );
      } catch (error) {
        console.error('Error fetching route groups:', error);
      }
    };

    fetchRouteGroups();
  }, [form]);

  const values = form.getFieldsValue([['product', 'lines']]);
  useEffect(() => {
    if (whereIsRestriction != null && whereIsRestriction.length > 0 && line) {
      const { lines } = values.product;
      const flatRestArr = whereIsRestriction.flat();
      if (flatRestArr.some((val) => val === 'value1')) {
        lines.option1 = line;
      } else if (flatRestArr.some((val) => val === 'value3')) {
        lines.option2 = line;
      } else if (flatRestArr.some((val) => val === 'value5')) {
        lines.option3 = line;
      } else if (flatRestArr.some((val) => val === 'value7')) {
        lines.option4 = line;
      }
    }
  }, [line, whereIsRestriction, values.product]);

  return (
    <>
      <Divider dashed />
      <Typography.Paragraph>
        {t(`entity.offers.restrictions.restriction.${restriction}._`)}
      </Typography.Paragraph>
      <Descriptions bordered column={1}>
        {value2 && (
          <Descriptions.Item label={routeGroups[value2]?.name}>
            {value1 === 1 ? (
              <>
                <Form.Item hidden name={['product', 'lines', 'productId']} />
                <Form.Item
                  name={['product', 'lines', 'option1']}
                  rules={[
                    {
                      required: true,
                      message: t('errors.selectLine'),
                    },
                  ]}
                >
                  <Select
                    disabled={
                      whereIsRestriction !== null &&
                      whereIsRestriction.flat().some((val) => val === 'value1')
                    }
                  >
                    {(routeGroups[value2]?.routes || []).map((route) => (
                      <Select.Option value={route.id} key={route.id}>
                        {route.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  hidden
                  name={['product', 'lineGroups', 'productId']}
                />
                <Form.Item
                  name={['product', 'lineGroups', 'option1']}
                  noStyle
                  initialValue={value2}
                >
                  <div>Toate liniile</div>
                </Form.Item>
              </>
            )}
          </Descriptions.Item>
        )}

        {value4 && (
          <Descriptions.Item label={routeGroups[value4]?.name}>
            {value3 === 1 ? (
              <>
                <Form.Item hidden name={['product', 'lines', 'productId']} />
                <Form.Item
                  name={['product', 'lines', 'option2']}
                  rules={[
                    {
                      required: true,
                      message: t('errors.selectLine'),
                    },
                  ]}
                >
                  <Select
                    disabled={
                      whereIsRestriction !== null &&
                      whereIsRestriction.flat().some((val) => val === 'value3')
                    }
                  >
                    {(routeGroups[value4]?.routes || []).map((route) => (
                      <Select.Option value={route.id} key={route.id}>
                        {route.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  hidden
                  name={['product', 'lineGroups', 'productId']}
                />
                <Form.Item
                  name={['product', 'lineGroups', 'option2']}
                  noStyle
                  initialValue={value4}
                >
                  <div>Toate liniile</div>
                </Form.Item>
              </>
            )}
          </Descriptions.Item>
        )}

        {value6 && (
          <Descriptions.Item label={routeGroups[value6]?.name}>
            {value5 === 1 ? (
              <>
                <Form.Item hidden name={['product', 'lines', 'productId']} />
                <Form.Item
                  name={['product', 'lines', 'option3']}
                  rules={[
                    {
                      required: true,
                      message: t('errors.selectLine'),
                    },
                  ]}
                >
                  <Select
                    disabled={
                      whereIsRestriction !== null &&
                      whereIsRestriction.flat().some((val) => val === 'value5')
                    }
                  >
                    {(routeGroups[value6]?.routes || []).map((route) => (
                      <Select.Option value={route.id} key={route.id}>
                        {route.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  hidden
                  name={['product', 'lineGroups', 'productId']}
                />
                <Form.Item
                  name={['product', 'lineGroups', 'option3']}
                  noStyle
                  initialValue={value6}
                >
                  <div>Toate liniile</div>
                </Form.Item>
              </>
            )}
          </Descriptions.Item>
        )}

        {value8 && (
          <Descriptions.Item label={routeGroups[value8]?.name}>
            {value7 === 1 ? (
              <>
                <Form.Item hidden name={['product', 'lines', 'productId']} />
                <Form.Item
                  name={['product', 'lines', 'option4']}
                  rules={[
                    {
                      required: true,
                      message: t('errors.selectLine'),
                    },
                  ]}
                >
                  <Select
                    disabled={
                      whereIsRestriction !== null &&
                      whereIsRestriction.flat().some((val) => val === 'value7')
                    }
                  >
                    {(routeGroups[value8]?.routes || []).map((route) => (
                      <Select.Option value={route.id} key={route.id}>
                        {route.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  hidden
                  name={['product', 'lineGroups', 'productId']}
                />
                <Form.Item
                  name={['product', 'lineGroups', 'option4']}
                  noStyle
                  initialValue={value8}
                >
                  <div>Toate liniile</div>
                </Form.Item>
              </>
            )}
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};

export default Restriction;
