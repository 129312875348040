import React, { useMemo, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Table, Divider, Button, PageHeader } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Column from '../../helpers/columns';
import {
  getAbonomentsByParentId,
  downloadDescendantAbonomentsByAccountId,
} from '../../services/accounts';
import { useRouteParams } from '../../hooks/useRouteParams';
import useDatasource, { filterDate } from '../../hooks/useDatasource';
import { Default } from '../../layouts';

const ActivationType = {
  content: [{ id: '0' }, { id: '1' }, { id: '2' }],
  loading: false,
  error: false,
};

const ViewAbonomentsDescendantsList = () => {
  const { id } = useRouteParams();
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, pagination, content, handleChange, sort, criterias } =
    useDatasource((arg) => getAbonomentsByParentId(id, arg));

  useEffect(() => {
    if (content && content.length > 0) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text('productId', t('entity.account.transportTitle.product_id'), {
        filter: true,
        width: 100,
      }),
      Column.text(
        'productName',
        t('entity.account.transportTitle.product_name'),
        {
          filter: true,
        },
      ),
      Column.text('userName', t('entity.account.descendants.name'), {
        filter: true,
        sort: false,
      }),
      Column.text(
        'userIdentifier',
        t('entity.account.descendants.userIdentifier'),
        {
          filter: true,
          sort: false,
        },
      ),
      Column.text('quantity', t('entity.account.transportTitle.quantity'), {
        sort: false,
      }),
      Column.dictionary(
        'dateStatusA',
        t('entity.account.transportTitle.dateStatus.A'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`entity.account.transportTitle.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('entity.account.transportTitle.valStaDateA'),
        key: 'valStaDateA',
        dataIndex: 'valStaDateA',
        width: 150,
        sorter: true,
        ...filterDate({
          title: t('entity.account.transportTitle.valStaDateA'),
        }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valStaDateA - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return record.valStaDateA ? moment(date).format('YYYY-MM-DD') : '';
        },
      },
      {
        title: t('entity.account.transportTitle.valEndDateA'),
        key: 'valEndDateA',
        dataIndex: 'valEndDateA',
        width: 150,
        sorter: true,
        ...filterDate({
          title: t('entity.account.transportTitle.valEndDateA'),
        }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valEndDateA - 1);
          // eslint-disable-next-line react/destructuring-assignment

          // eslint-disable-next-line react/destructuring-assignment
          return record.valEndDateA ? moment(date).format('YYYY-MM-DD') : '';
        },
      },
      Column.dictionary(
        'dateStatusB',
        t('entity.account.transportTitle.dateStatus.B'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`entity.account.transportTitle.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('entity.account.transportTitle.valStaDateB'),
        key: 'valStaDateB',
        dataIndex: 'valStaDateB',
        width: 150,
        sorter: true,
        ...filterDate({
          title: t('entity.account.transportTitle.valStaDateB'),
        }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valStaDateB - 1);

          // eslint-disable-next-line react/destructuring-assignment
          return record.valStaDateB ? moment(date).format('YYYY-MM-DD') : '';
        },
      },
      {
        title: t('entity.account.transportTitle.valEndDateB'),
        key: 'valEndDateB',
        dataIndex: 'valEndDateB',
        width: 150,
        sorter: true,
        ...filterDate({
          title: t('entity.account.transportTitle.valEndDateB'),
        }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valEndDateB - 1);
          // eslint-disable-next-line react/destructuring-assignment

          // eslint-disable-next-line react/destructuring-assignment
          return record.valEndDateB ? moment(date).format('YYYY-MM-DD') : '';
        },
      },
      Column.bool('deleted', t('entity.account.transportTitle.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.account.transportTitle.deleted.false'),
          t('entity.account.transportTitle.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );
  const handleChangeHandler = useCallback(
    (first, filters, ...args) => {
      const julianDate = new Date(
        new Date(localStorage.getItem('julianStartDate')).toLocaleDateString(
          'en-US',
          {
            timeZone: 'Europe/Bucharest',
          },
        ),
      );

      const newValues = [
        'valStaDateA',
        'valEndDateA',
        'valStaDateB',
        'valEndDateB',
      ]
        .filter((field) => filters[field])
        .reduce((obj, field) => {
          return {
            ...obj,
            [field]: [
              {
                from: Math.max(
                  -32768,
                  Math.ceil(
                    (new Date(filters[field][0]?.from) -
                      julianDate -
                      (new Date(filters[field][0]?.from).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
                to: Math.min(
                  32767,
                  Math.ceil(
                    (new Date(filters[field][0]?.to) -
                      julianDate -
                      (new Date(filters[field][0]?.to).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
              },
            ],
          };
        }, {});
      return handleChange(first, { ...filters, ...newValues }, ...args);
    },
    [handleChange],
  );

  const contentWithProdType = useMemo(
    () =>
      content.map((title) => ({
        ...title,
        prodTypeName: t(
          `entity.account.transportTitle.product_types.${title.productType}`,
        ),
      })),

    [content, t],
  );

  return (
    <Default>
      <PageHeader
        onBack={() => history.push(`/accounts/${id}`)}
        title={t('entity.account.transactions.accountDetails')}
      />
      <Divider style={{ fontWeight: 500, fontSize: '1.5rem' }}>
        <Button
          icon={<DownloadOutlined />}
          onClick={() =>
            downloadDescendantAbonomentsByAccountId(id, {
              sort,
              pageNumber: pagination.current,
              pageSize: pagination.pageSize,
              criterias,
            })
          }
        >
          {t('actions.download')}
        </Button>
      </Divider>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={contentWithProdType}
        onChange={handleChangeHandler}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};
export default ViewAbonomentsDescendantsList;
