import React from 'react';

import { Row, Col } from 'antd';
import LanguageChooser from '../components/LanguageChooser';
import BackgroundImage from './login-background.jpg';

const Empty = ({ children }) => {
  return (
    <>
      <Row style={{ padding: '1rem 0' }}>
        <Col xs={24} style={{ textAlign: 'right' }}>
          <LanguageChooser />
        </Col>
      </Row>
      <div
        style={{
          height: '100vh',
          minHeight: '400px',
          paddingTop: '64px',
          backgroundImage: `url(${BackgroundImage})`,
          position: 'fixed',
          minWidth: '100%',
          // WebkitFilter: 'blur(20px)',
          // filter: 'blur(5px)',
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          backgroundSize: 'cover',
          backgroundPosition: '0 10%',
          backgroundRepeat: 'no-repeat',
          top: 0,
          right: 0,
          bottom: 0,
          borderRight: 0,
        }}
      />

      <div
        style={{
          margin: 0,
          height: '100%',
        }}
      >
        {children}
      </div>

      {window._env_.VERSION && (
        <div className="app-version">
          {new Date(
            (parseInt(window._env_.VERSION, 10) || 0) * 1000,
          ).toISOString()}
        </div>
      )}
    </>
  );
};

export default Empty;
