import { get, put, post } from '../helpers/api';

export const BACKEND_SERVICE_URI = '/portal';
// export const BACKEND_USER_SERVICE_URI = '/users';
export const VIES_BASE_URI = '/api/v1/vies';
export const ACCOUNTS_BASE_URI = '/api/v1/users';
// export const USERS_SERVICE_URI = '/users';
// export const USERS_BASE_URI = '/api/v1/users';

export const getUserDetails = async () =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/current`);

export const updateUserDetails = async (data) =>
  put(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/current`, data);

export const getUsersWithExistingCards = (emailList) =>
  post(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/check-user-has-account`, {
    emailList,
  });

export const getUserByAccountId = (accountId) =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/byAccountId/${accountId}`);

export const updateUserByAccountId = async (accountId, data) =>
  put(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/byAccountId/${accountId}`,
    data,
  );

export const registerUser = (newUser) =>
  post(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/register`, newUser);

export const forgot = (payload) =>
  post(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/reset`, payload);

export const reset = (token, payload) =>
  post(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/reset/${token}`, payload);

export const validateCui = async (country, vatNUmber) =>
  get(
    `${BACKEND_SERVICE_URI}${VIES_BASE_URI}?country=${country}&vatNumber=${vatNUmber}`,
  );

export default { getUserDetails, updateUserDetails };
