import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
  TimePicker,
  Typography,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import moment from 'moment';

import { getFiles } from '../services/files';

import FileUploader from './FileUploader';
import useDatasource from '../hooks/useDatasource';
import RangePickerLocale from './RangePickerLocale';
import DatePickerLocale from './DatePickerLocale';
import { ORDER_STATUS } from '../pages/orders/constants';

const FormWrapper = ({ children }) => (
  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>{children}</Row>
);

FormWrapper.Single = ({ children }) => (
  <Col xs={24} sm={24} md={10} lg={8} xl={8}>
    {children}
  </Col>
);

FormWrapper.Double = ({ children }) => (
  <Col xs={24} sm={24} md={14} lg={16} xl={16}>
    {children}
  </Col>
);

FormWrapper.ErrorMessage = ({ message }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {message && (
      <Typography.Paragraph
        type="danger"
        style={{ marginBottom: 0, fontStyle: 'italic' }}
      >
        {message}
      </Typography.Paragraph>
    )}
  </>
);

const FieldWrapper = ({ errorMessage, label, children }) => (
  <div className="form-group">
    {label && (
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        {label}
      </Typography.Paragraph>
    )}
    {children}
    <FormWrapper.ErrorMessage message={errorMessage} />
  </div>
);

FormWrapper.Input = ({ label, props }) => {
  const { invalid: errorMessage } = props;
  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Input {...props} />
    </FieldWrapper>
  );
};

const Search = ({ label, props }) => {
  const {
    invalid: errorMessage,
    getValue,
    name,
    fetcher,
    disabled,
    field,
    setValue,
    labelProp = 'name',
  } = props;

  const { loading, content = [], handleChange } = useDatasource(fetcher);
  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Select
        showSearch
        loading={loading}
        disabled={disabled}
        value={getValue(name)}
        style={{ width: '100%' }}
        onChange={(val) => setValue(name, val)}
        onSearch={(filter) => handleChange(null, { [field]: [filter] }, {})}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }
      >
        {content.map((el) => (
          <Select.Option value={el.id} key={el[field]}>
            {typeof labelProp === 'string' ? el[labelProp] : labelProp(el)}
          </Select.Option>
        ))}
      </Select>
    </FieldWrapper>
  );
};

FormWrapper.Password = ({ label, props }) => {
  const { invalid: errorMessage } = props;
  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Input.Password {...props} />
    </FieldWrapper>
  );
};

FormWrapper.TextArea = ({ label, props }) => {
  const { invalid: errorMessage } = props;
  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Input.TextArea {...props} />
    </FieldWrapper>
  );
};

FormWrapper.Number = ({ label, props }) => {
  const { name, invalid: errorMessage, onChange } = props;
  const handleChange = (value) => onChange(value, { currentTarget: { name } });

  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <InputNumber {...props} onChange={handleChange} />
    </FieldWrapper>
  );
};

const DateTimePicker = ({ label, props }) => {
  const {
    name,
    invalid: errorMessage,
    setValue,
    getValue,
    hideTime,
    ...restProps
  } = props;

  const handleChange = (val) => setValue(name, val);

  const res = getValue(name);
  const value = res == null ? null : moment(res);

  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <DatePickerLocale
        format={`DD.MM.YYYY${hideTime ? '' : ' HH:mm'}`}
        showTime={!hideTime}
        onChange={handleChange}
        value={value}
        style={{ width: '100%' }}
        {...restProps}
      />
    </FieldWrapper>
  );
};

const RangePicker = ({ label, props }) => {
  const { start, end, ...restProps } = props;

  const handleChange = ([startDate, endDate]) => {
    start.setValue(start.name, startDate);
    end.setValue(end.name, endDate);
  };
  const value = useMemo(() => {
    const startDate = start.getValue(start.name);
    const endDate = end.getValue(end.name);
    return [
      startDate ? moment(startDate) : null,
      endDate ? moment(endDate) : null,
    ];
  }, [end, start]);

  return (
    <FieldWrapper
      label={label}
      errorMessage={[start.invalid, end.invalid].join(' ')}
    >
      <RangePickerLocale
        format="DD.MM.YYYY HH:mm"
        showTime={{ format: 'HH:mm' }}
        onChange={handleChange}
        value={value}
        style={{ width: '100%' }}
        {...restProps}
      />
    </FieldWrapper>
  );
};

const TimeRangePicker = ({ label, props }) => {
  const { start, end, ...restProps } = props;

  const handleChange = useCallback(
    (range) => {
      start.setValue(start.name, range ? range[0] : null);
      end.setValue(end.name, range ? range[1] : null);
    },
    [end, start],
  );

  const value = useMemo(() => {
    const startDate = start.getValue(start.name);
    const endDate = end.getValue(end.name);
    return [
      startDate ? moment(startDate) : null,
      endDate ? moment(endDate) : null,
    ];
  }, [end, start]);

  return (
    <FieldWrapper
      label={label}
      errorMessage={[start.invalid, end.invalid].join(' ')}
    >
      <TimePicker.RangePicker
        onChange={handleChange}
        value={value}
        style={{ width: '100%' }}
        {...restProps}
      />
    </FieldWrapper>
  );
};

FormWrapper.Switch = ({ label, props }) => {
  const { invalid: errorMessage } = props;
  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        {...props}
      />
    </FieldWrapper>
  );
};

FormWrapper.CheckBox = ({ label, props }) => {
  const { invalid: errorMessage } = props;
  return (
    <>
      <Checkbox {...props}>{label}</Checkbox>
      <FormWrapper.ErrorMessage message={errorMessage} />
    </>
  );
};

FormWrapper.Select = ({ label, props }) => {
  let { invalid: errorMessage } = props;

  const {
    errorMessageStatic,
    options = [],
    keyProp = 'id',
    labelProp = 'name',
    ...restProps
  } = props;

  if (errorMessageStatic) {
    errorMessage = errorMessageStatic;
  }

  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Select {...restProps}>
        {options.map((option) => (
          <Select.Option
            value={option[keyProp]}
            key={option[keyProp]}
            disabled={option.disabled || option.deleted}
          >
            {typeof labelProp === 'string'
              ? option[labelProp]
              : labelProp(option)}
          </Select.Option>
        ))}
      </Select>
    </FieldWrapper>
  );
};

FormWrapper.SelectProduct = ({ label, props }) => {
  const {
    invalid: errorMessage,
    options = [],
    keyProp = 'id',
    lang,
    productName,
    ...restProps
  } = props;

  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Select {...restProps} value={productName}>
        {options.map((option) => (
          <Select.Option
            value={option[keyProp]}
            key={option[keyProp]}
            disabled={option.disabled || option.deleted}
          >
            {option.localizedName[lang]}
          </Select.Option>
        ))}
      </Select>
    </FieldWrapper>
  );
};

FormWrapper.GroupedSelect = ({ label, props }) => {
  const {
    invalid: errorMessage,
    keyProp = 'id',
    labelProp = 'name',
    groupedOptions = [],
    ...restProps
  } = props;
  return (
    <FieldWrapper label={label} errorMessage={errorMessage}>
      <Select {...restProps}>
        {groupedOptions.map((groupedOption) => (
          <Select.OptGroup
            label={groupedOption.label}
            key={groupedOption.label}
          >
            {groupedOption.options.map((option) => (
              <Select.Option
                value={option[keyProp]}
                key={option[keyProp]}
                disabled={option.disabled}
              >
                {typeof labelProp === 'string'
                  ? option[labelProp]
                  : labelProp(option)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    </FieldWrapper>
  );
};

const FileList = ({ props }) => {
  const {
    uploadUrl,
    name,
    entityId,
    setValue,
    getValue,
    defaultFileProps,
    tableProps,
    allowUpload = true,
    type,
    status,
  } = props;

  const notFetchedFileIds = getValue(name)
    .filter((file) => !file.fetched)
    .map((file) => file.id);

  useEffect(() => {
    if (notFetchedFileIds.length > 0)
      getFiles(notFetchedFileIds, entityId).then((fetchedFiles) =>
        setValue(
          name,
          getValue(name).reduce(
            (acc, cur) => [
              ...acc,
              {
                ...cur,
                ...fetchedFiles.find((file) => file.id === cur.id),
                fetched: true,
              },
            ],
            [],
          ),
        ),
      );
  }, [entityId, getValue, name, notFetchedFileIds, setValue]);

  const addFiles = useCallback(
    (files) =>
      setValue(name, [
        ...getValue(name),
        ...files.map((file) => ({
          ...file,
          ...defaultFileProps,
          fetched: true,
        })),
      ]),
    [defaultFileProps, getValue, name, setValue],
  );

  return (
    <>
      {allowUpload && (
        <div className="form-group">
          <FileUploader
            type={type}
            onUploadSuccess={addFiles}
            uploadUrl={uploadUrl}
            disabled={![ORDER_STATUS.SELECT_PRODUCTS].includes(status)}
          />
        </div>
      )}
      <div className="form-group">
        <Table pagination={false} dataSource={getValue(name)} {...tableProps} />
      </div>
    </>
  );
};

FormWrapper.FileUploader = ({ props }) => {
  const {
    invalid: errorMessage,
    name,
    setValue,
    getValue,
    setFieldValue,
    onUploadFailed,
  } = props;
  return (
    <>
      <FileUploader
        {...props}
        dragger={false}
        onUploadSuccess={(files, blob) => {
          setValue(name, files);
          if (getValue !== undefined)
            setFieldValue('docMap', {
              ...getValue('docMap'),
              [files[0].id]: blob,
            });
        }}
        onUploadFailed={onUploadFailed}
      />
      <FormWrapper.ErrorMessage message={errorMessage} />
    </>
  );
};

FormWrapper.RangePicker = RangePicker;
FormWrapper.TimeRangePicker = TimeRangePicker;
FormWrapper.DateTimePicker = DateTimePicker;
FormWrapper.FileList = FileList;
FormWrapper.FieldWrapper = FieldWrapper;
FormWrapper.Search = Search;

export default FormWrapper;
