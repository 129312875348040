import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Button, Divider, Input, Radio, Select, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import RangePickerLocale from '../components/RangePickerLocale';

const ACTION_LOADING = Symbol('ACTION_LOADING');
const ACTION_LOADED = Symbol('ACTION_LOADED');
const ACTION_FAILED = Symbol('ACTION_FAILED');

const initialState = {
  content: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },

  criterias: null,
  sort: null,

  loading: false,
  error: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_LOADING:
      return {
        ...state,

        criterias: action.payload.criterias || state.criterias,
        sort: action.payload.sort || state.sort,
        pagination: {
          ...state.pagination,
          current:
            action.payload.pagination?.current || state.pagination.current,
          pageSize:
            action.payload.pagination?.pageSize || state.pagination.pageSize,
        },

        loading: true,
        error: false,
      };

    case ACTION_LOADED:
      return {
        ...state,
        content: action.payload.content,
        pagination: {
          current: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
          total: action.payload.totalElements,
        },
        error: false,
        loading: false,
      };

    case ACTION_FAILED:
      return {
        ...initialState,
        error: true,
      };

    default:
      return state;
  }
};

const reduceFilters = (filters) =>
  Object.keys(filters)
    .filter((key) => Array.isArray(filters[key]) && filters[key].length > 0)
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: filters[key],
      }),
      {},
    );

const reduceSorter = (sorter) => {
  const { order, field } = sorter;

  if (!order) {
    return null;
  }
  return [field, order === 'ascend' ? 'asc' : 'desc'];
};

const FilterDialog = ({
  title,
  searchTitle,
  resetTitle,
  confirm,
  clearFilters,
  children,
}) => (
  <div style={{ padding: 16, paddingTop: 16 }}>
    <Typography.Paragraph>{title}</Typography.Paragraph>
    {children}
    <Divider />
    <Space>
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 120 }}
      >
        {searchTitle}
      </Button>
      <Button onClick={clearFilters} size="small" style={{ width: 120 }}>
        {resetTitle}
      </Button>
    </Space>
  </div>
);

const FilterIcon = (filtered) => (
  <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
);

export const filterText = ({
  title = 'Filter',
  searchTitle = 'Search',
  resetTitle = 'Reset',
}) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDialog
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      confirm={confirm}
      clearFilters={clearFilters}
    >
      <Input
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
    </FilterDialog>
  ),
  filterIcon: FilterIcon,
});

export const filterBool = ({
  title = 'Filter',
  searchTitle = 'Search',
  resetTitle = 'Reset',
  labels = ['All', 'Yes', 'No'],
}) => {
  const options = [
    { label: labels[0], value: undefined },
    { label: labels[1], value: 'true' },
    { label: labels[2], value: 'false' },
  ];

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <FilterDialog
        title={title}
        searchTitle={searchTitle}
        resetTitle={resetTitle}
        confirm={confirm}
        clearFilters={clearFilters}
      >
        <Radio.Group
          value={selectedKeys[0]}
          options={options}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
      </FilterDialog>
    ),
    filterIcon: FilterIcon,
  };
};

export const filterDictionary = ({
  title = 'Filter',
  searchTitle = 'Search',
  resetTitle = 'Reset',
  dictionary,
  dictKey,
  dictLabel,
}) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDialog
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      confirm={confirm}
      clearFilters={clearFilters}
    >
      <Select
        mode="multiple"
        maxTagCount="responsive"
        optionFilterProp="label"
        style={{ width: '100%' }}
        value={selectedKeys}
        onChange={setSelectedKeys}
        loading={dictionary.loading}
        options={dictionary.content.map((entry) => ({
          value: entry[dictKey],
          label:
            (typeof dictLabel === 'function'
              ? dictLabel(entry)
              : entry[dictLabel]) || entry[dictKey],
        }))}
      />
    </FilterDialog>
  ),
  filterIcon: FilterIcon,
});

const DATE_START = '1900-01-01T00:00:00.000Z';
const DATE_END = '3000-01-01T00:00:00.000Z';
const EMPTY_DATE_RANGE = [null, null];

const FilterDateWrapper = ({
  title,
  searchTitle,
  resetTitle,
  setSelectedKeys,
  confirm,
  clearFilters,
}) => {
  const [value, setValue] = useState(EMPTY_DATE_RANGE);

  const onChange = useCallback(
    ([start, end]) => {
      if (!start && !end) {
        setValue(EMPTY_DATE_RANGE);
        setSelectedKeys(null);
      } else {
        setValue([start, end]);
        setSelectedKeys([
          {
            from: start ? start.toJSON() : DATE_START,
            to: end ? end.toJSON() : DATE_END,
          },
        ]);
      }
    },
    [setSelectedKeys],
  );

  const onClearFilters = useCallback(() => {
    clearFilters();
    setValue(EMPTY_DATE_RANGE);
  }, [clearFilters]);

  return (
    <FilterDialog
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      confirm={confirm}
      clearFilters={onClearFilters}
    >
      <RangePickerLocale
        showTime
        allowClear={false}
        allowEmpty={[true, true]}
        format="DD.MM.YYYY HH:mm"
        style={{ width: '18rem' }}
        value={value}
        onChange={onChange}
      />
    </FilterDialog>
  );
};

export const filterDate = ({
  title = 'Filter',
  searchTitle = 'Search',
  resetTitle = 'Reset',
}) => ({
  filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
    <FilterDateWrapper
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      setSelectedKeys={setSelectedKeys}
      clearFilters={clearFilters}
      confirm={confirm}
    />
  ),
  filterIcon: FilterIcon,
});

export const wrap = (fetcher) => (data) =>
  fetcher(data).then((res) => ({
    content: Array.isArray(res) ? res : [],
    pageNumber: 1,
    pageSize: Array.isArray(res) ? res.length : 0,
    totalElements: Array.isArray(res) ? res.length : 0,
  }));

export default function useDatasource(fetcher, options = { pagination: {} }) {
  const safeguard = useRef(0);

  const { pagination: optPagination } = options;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    pagination: {
      ...initialState.pagination,
      ...optPagination,
    },
  });
  const reload = useCallback(() => {
    dispatch({
      type: ACTION_LOADING,
      payload: {},
    });
  }, []);

  const handleChange = useCallback((pagination, filters, sorter) => {
    dispatch({
      type: ACTION_LOADING,
      payload: {
        criterias: reduceFilters(filters),
        sort: reduceSorter(sorter),
        pagination,
      },
    });
  }, []);

  useEffect(() => {
    dispatch({ type: ACTION_LOADING, payload: {} });
  }, []);

  useEffect(() => {
    if (state.loading) {
      safeguard.current += 1;
      const pointintime = safeguard.current;

      const {
        criterias,
        sort,
        pagination: { current: pageNumber, pageSize },
      } = state;

      fetcher({
        criterias,
        sort,
        pageNumber,
        pageSize,
      })
        .then((res) => {
          if (pointintime === safeguard.current) {
            dispatch({ type: ACTION_LOADED, payload: res });
          }
        })
        .catch(() => {
          if (pointintime === safeguard.current) {
            dispatch({ type: ACTION_FAILED });
          }
        });
    }
    return () => null;
  }, [fetcher, state]);

  return {
    content: state.content,
    loading: state.loading,
    invalid: state.error,
    pagination: state.pagination,

    criterias: state.criterias,
    sort: state.sort,

    handleChange,
    reload,
  };
}
