import React, { useEffect, useMemo } from 'react';
import { Select } from 'antd';
import FormWrapper from '../FormWrapper';
import useDatasource from '../../hooks/useDatasource';
import { getInstitutions } from '../../services/taxonomies';
import { UserTypesEnum } from '../../services/constants';

const SearchInstitutions = ({ label, props }) => {
  const {
    invalid: errorMessage,
    getValue,
    name,
    disabled,
    field,
    searchField,
    setValue,
    labelProp = 'name',
    userTypes,
    institutionId,
    userTypeId,
    resetSubdivisions,
    setUserIdForSpecificUserType,
    setUserTypeId,
    changeProduct,
  } = props;

  const ids = useMemo(() => {
    const isRetiredCategory = (code) =>
      [UserTypesEnum.PENSIONAR, UserTypesEnum.PENSIONAR_CONVENTIE].includes(
        code,
      );
    const retiredUsersTypesConst = userTypes?.content?.filter((type) =>
      isRetiredCategory(type.code),
    );
    const allInstitutions = retiredUsersTypesConst?.flatMap(
      (retiredType) => retiredType.institutions,
    );
    const uniqueInstitutions = allInstitutions?.filter(
      (institution, index) => allInstitutions.indexOf(institution) === index,
    );

    return retiredUsersTypesConst?.filter((ut) => ut.id === userTypeId).length >
      0
      ? uniqueInstitutions
      : userTypes.content?.find((ut) => userTypeId === ut.id)?.institutions;
  }, [userTypeId, userTypes.content]);

  const { loading, content, handleChange, reload } = useDatasource((data) =>
    (ids || []).length > 0
      ? getInstitutions({
          ...data,
          pageSize: 1000,
          criterias: {
            ...data.criterias,
            ids,
          },
        })
      : Promise.resolve({
          content: [],
          loading: false,
          last: true,
          pageNumber: 1,
          pageSize: 10,
          totalElements: 0,
        }),
  );
  useEffect(() => reload(), [userTypeId, ids, reload]);

  useEffect(() => {
    if (institutionId) {
      const institution = content.filter((ins) => ins.id === institutionId);
      const maintainerId =
        institution != null && institution.length > 0
          ? institution[0]?.maintainerId
          : null;
      if (maintainerId != null) {
        setValue('userId', maintainerId);
      }
    }
  }, [institutionId, content, setValue]);

  return (
    <FormWrapper.FieldWrapper label={label} errorMessage={errorMessage}>
      <Select
        allowClear
        showSearch
        loading={loading}
        disabled={disabled}
        onClear={() => handleChange(null, {}, {})}
        value={getValue(name)}
        style={{ width: '100%' }}
        onChange={(val) => {
          resetSubdivisions();
          setValue(name, val);
          setUserIdForSpecificUserType(content, val);
          if (changeProduct) {
            changeProduct();
          }
          const isRetiredCategory = (code) =>
            [
              UserTypesEnum.PENSIONAR,
              UserTypesEnum.PENSIONAR_CONVENTIE,
            ].includes(code);
          const retiredUsersTypesConst = userTypes?.content?.filter((type) =>
            isRetiredCategory(type.code),
          );
          if (
            userTypeId === -1 ||
            retiredUsersTypesConst?.filter((ut) => ut.id === userTypeId)
              .length > 0
          ) {
            const institutions = content.filter((inst) => inst.id === val);
            const institutionName =
              institutions.length === 1 ? institutions[0].name : null;
            setUserTypeId(val, institutionName);
          }
        }}
        onSearch={(filter) =>
          handleChange(null, { [searchField]: [filter] }, {})
        }
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }
      >
        {content.map((el) => (
          <Select.Option value={el.id} key={el[field]}>
            {typeof labelProp === 'string' ? el[labelProp] : labelProp(el)}
          </Select.Option>
        ))}
      </Select>
    </FormWrapper.FieldWrapper>
  );
};
export default SearchInstitutions;
