import { download, get, post, put, remove, search } from '../helpers/api';

export const BACKEND_SERVICE_URI = '/portal';
export const ACCOUNTS_BASE_URI = '/api/v1/accounts';
export const API = '/api/v1';
export const ACCOUNTS_BASE_URI_V2 = '/api/v2/accounts';

export const getRouteGroups = () =>
  get(`${BACKEND_SERVICE_URI}${API}/restrictions/routegroups`, {});

export const getUserAccounts = () =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}`);

export const getUserCurrentAccounts = () =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/current`);

export const getAccountById = (id) =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}`);

export const getTitlesByAccountId = (accountId) =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/titles`);

export const generateIndividualInvoice = (id, invoiceType) =>
  put(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/individualInvoice/${invoiceType}`,
  );

export const generateLegalInvoice = (id, data) =>
  put(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/legalInvoice`, data);

export const getDescendantsByAccountId = (accountId, data) =>
  search(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/children`,
    data,
  );

export const getDescendantsVwByAccountId = (accountId, data) =>
  search(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/children/vw`,
    data,
  );

export const attachDescendentAccount = (accountId, data) =>
  post(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/children/attach`,
    data,
  );
export const detachDescendentAccount = (accountId, data) =>
  post(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/children/detach`,
    data,
  );

export const getAbonomentsByParentId = async (id, data) =>
  search(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/children/titles`,
    data,
  );
export const downloadDescendantsByAccountId = (accountId, data) =>
  download(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/children/vw-download`,
    data,
  );
export const downloadDescendantAbonomentsByAccountId = (accountId, data) =>
  download(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/children/titles`,
    data,
  );

export const getOfferTypes = () =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI_V2}/offer-types`);

export const getProductsByOfferTypeReq = (accountId, offerTypeReq) =>
  put(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI_V2}/${accountId}/products`,
    offerTypeReq,
  );

export const addAccount = (logicId) =>
  put(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/cards/${logicId}/checkVisibility`,
  );

export const getCurrentTitles = () =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/current/titles`);

export const disableCard = (accountId, reasonId) =>
  remove(
    `${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${accountId}/cards/${reasonId}`,
  );

export const getAccountTransactionsById = (id) =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI}/${id}/transactions`);

export const getInactiveCardsByUserId = () =>
  get(`${BACKEND_SERVICE_URI}${ACCOUNTS_BASE_URI_V2}/cards/inactive`);
