import React from 'react';
import { Typography } from 'antd';

const TitleLogo = () => {
  // const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography.Title style={{ color: 'white' }} level={2}>
        UrbanGO! - Portal
      </Typography.Title>
      {/* <Typography.Text style={{ color: 'white' }} type="secondary">
        {t('startPage.titleLogo')}{' '}
      </Typography.Text> */}
    </div>
  );
};

export default TitleLogo;
