import React from 'react';
import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import { create, findOne, update } from '../../services/orders';
import EditOrderForm from '../../components/orders/EditOrderForm';
import { CUSTOMER_TYPE, ORDER_STATUS } from './constants';

const initialOrderData = {
  attachments: [],
  products: [],
  commandType: 'EXTERNAL',
  status: ORDER_STATUS.SELECT_PRODUCTS,
  deleted: false,
  customerType: CUSTOMER_TYPE.LEGAL,
};

const EditOrder = () => {
  const [order, handleSubmit, handleCancel] = useEditPage({
    initial: initialOrderData,
    existent: findOne,
    goBackPath: '/orders',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {order !== undefined && (
        <EditOrderForm
          order={{
            ...order,
            disabled: order.status !== ORDER_STATUS.SELECT_PRODUCTS,
          }}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditOrder;
