import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Tooltip,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import { range } from 'lodash';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18n from 'i18next';
import FormWrapper from '../FormWrapper';
import PageTitle from '../PageTitle';
import './OrderFileList.scss';
import { getProducts } from '../../services/products';
import { FormContext } from '../../hooks/useForm';
import ProductUsersList from './ProductUsersList';
import POSButton from './POSButton';
import { BULK_TYPES, ORDER_STATUS } from '../../pages/orders/constants';
import Restriction from '../Restriction';
import SearchInstitutions from './SearchInstituions';
import { getRolesWithDetails } from '../../services/roles';
import { UserTypesEnum } from '../../services/constants';
import { downloadFiles } from '../../services/files';
import { getInstitutionById } from '../../services/taxonomies';
import { getCurrentTitles } from '../../services/accounts';
import DatePickerLocale from '../DatePickerLocale';

const getDate = (value, julianStartDate) => {
  // eslint-disable-next-line react/destructuring-assignment
  const date = new Date(
    new Date(julianStartDate).toLocaleDateString('en-US', {
      timeZone: 'Europe/Bucharest',
    }),
  );
  // eslint-disable-next-line react/destructuring-assignment
  date.setDate(date.getDate() + value);
  // eslint-disable-next-line react/destructuring-assignment
  return date;
};

const Products = ({ props }) => {
  const {
    userType,
    isTypeChange,
    userTypes,
    setNewUserTypes,
    user,
    isNew,
    userTypeShowColumns,
    setCurrentUserShowColumns,
  } = props;
  const form = useContext(FormContext);
  const [configForm] = Form.useForm();
  const { t } = useTranslation();
  const { language } = i18n;
  const { select, custom, value, setValue } = form;
  const { products = [], disabled, bulkType, status, attachments } = value;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [titles, setTitles] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [newTypeChange, setNewTypeChange] = useState(null);
  const [pensionarUserType, setPensionarUserType] = useState(null);
  const [passengerRoleId, setPassengerRoleId] = useState(null);
  const [inst, setInst] = useState(null);
  const [cardProduct, setCardProduct] = useState(null);
  const notStorableProducts = products.filter(
    (p) => p.productCode !== 'MIFARE_1K',
  );
  const [line, setLine] = useState(null);
  const [whereIsRestriction, setWhereIsRestriction] = useState([]);

  const env = useMemo(() => window._env_.ENVIROMENT, []);
  const enableGratuitiesReportsString = useMemo(
    () => window._env_.ENABLE_GRATUITIES_REPORTS,
    [],
  );
  const previewGratuities =
    enableGratuitiesReportsString === 'true' ? [UserTypesEnum.ELEV] : [];

  dayjs.extend(customParseFormat);

  useEffect(() => {
    if (isTypeChange) {
      setNewTypeChange(isTypeChange);
    } else {
      setNewTypeChange(false);
    }
  }, [isTypeChange]);

  const userTypeConst = useMemo(() => {
    if (
      !isNew &&
      products[0] &&
      products[0].productPersons &&
      products[0].productPersons[0] &&
      products[0].productPersons[0].person
    ) {
      return products[0].productPersons[0].person.userTypeId === -1
        ? pensionarUserType
        : products[0].productPersons[0].person.userTypeId;
    }
    const isRetiredCategory = (code) =>
      [UserTypesEnum.PENSIONAR, UserTypesEnum.PENSIONAR_CONVENTIE].includes(
        code,
      );
    const retiredUsersTypesConst = userTypes?.content?.filter((type) =>
      isRetiredCategory(type.code),
    );
    if (
      userType === -1 &&
      retiredUsersTypesConst?.filter((ut) => ut.id === user?.userTypeId)
        .length > 0
    ) {
      setPensionarUserType(user?.userTypeId);
    }
    return userType === -1 ? pensionarUserType : userType;
  }, [isNew, products, userType, pensionarUserType, user, userTypes]);

  useEffect(() => {
    getCurrentTitles().then((res) => {
      setTitles(res);
    });
  }, []);

  const getProduct = useCallback(
    (productId) => {
      return productTypes?.find((pt) => pt.id === productId);
    },
    [productTypes],
  );

  const getDefaultDate = useCallback(
    (productId) => {
      if (titles && titles.length > 0 && user) {
        const choosenTitles = titles.filter((it) => it.productId === productId);
        if (choosenTitles.length > 0) {
          return moment(
            new Date(
              Math.max.apply(
                null,
                choosenTitles.map((it) => {
                  const endDateA = getDate(it.valEndDateA, it.julianStartDate);
                  const endDateB = getDate(it.valEndDateB, it.julianStartDate);
                  if (
                    it.productType !== 'PASS' ||
                    (new Date() > endDateA && new Date() > endDateB)
                  ) {
                    return new Date().getTime();
                  }
                  return endDateA > endDateB
                    ? endDateA.getTime()
                    : endDateB.getTime();
                }),
              ),
            ),
          );
        }
      }
      return null;
    },
    [titles, user],
  );

  const handleRetiredCategory = useCallback(
    (selectedInstitutionId, selectedInstitutionName) => {
      const isRetiredCategory = (code) =>
        [UserTypesEnum.PENSIONAR, UserTypesEnum.PENSIONAR_CONVENTIE].includes(
          code,
        );
      const retiredUsersTypes = userTypes.content.filter((type) =>
        isRetiredCategory(type.code),
      );
      const types = retiredUsersTypes.filter((type) =>
        type?.institutions.includes(selectedInstitutionId),
      );
      if (types.length > 1) {
        notification.error({
          message: `Nu se poate determina tipul de abonament pentru instituția ${selectedInstitutionName}. Vă rugăm să contactați office@tursib.ro pentru remediere.`,
        });
      } else {
        setPensionarUserType(types[0]?.id);
        if (!isNew) {
          setValue(
            'products[0].productPersons[0].person.userTypeId',
            types[0]?.id,
          );
        }
        setNewUserTypes({
          ...userTypes,
          content: userTypes.content.map((ut) => {
            if (ut.id === -1) {
              const newUt = {
                ...ut,
                showColumns: types[0]?.showColumns,
                docTypes: types[0]?.docTypes,
              };
              return newUt;
            }
            return ut;
          }),
        });
      }
    },
    [isNew, setNewUserTypes, setValue, userTypes],
  );

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current < moment().add(-1, 'hour') ||
      Date.parse(current) > Date.parse(moment().add(90, 'days'))
    );
  };

  const disabledTime = (current) => {
    if (moment().isSame(current, 'day')) {
      // Disable hours before the current hour
      const currentHour = moment().hour();
      const currentMinute = moment().minute();
      const currentSecond = moment().second();

      return {
        disabledHours: () => range(0, currentHour),
        disabledMinutes: (hour) =>
          hour === currentHour ? range(0, currentMinute) : [],
        disabledSeconds: (hour, minute) =>
          hour === currentHour && minute === currentMinute
            ? range(0, currentSecond)
            : [],
      };
    }

    return null; // Enable all hours for other days
  };

  useEffect(() => {
    if (userType) {
      getProducts({
        criterias: {
          userTypeId: userTypeConst,
          dateEnd: moment().toISOString(),
        },
      }).then((data) => {
        setProductTypes(data.content);
      });
    }
    if (
      (newTypeChange !== null && newTypeChange === true) ||
      (isTypeChange === true && pensionarUserType === null)
    ) {
      setNewTypeChange(false);
      setValue('products[0].productId', null);
      setValue('products[0].institutionId', null);
      setValue('products[0].key', uuidv4());
    }
  }, [
    userType,
    setValue,
    userTypeConst,
    isTypeChange,
    newTypeChange,
    pensionarUserType,
  ]);

  const calculatedateFromJulian = (endDateVal, endTimeVal) => {
    if (endDateVal !== null) {
      const endDate = endDateVal || 0;
      // Convert Julian days to milliseconds (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
      let julianMilliseconds = (endDate - 1) * 24 * 60 * 60 * 1000;
      julianMilliseconds += endTimeVal * 60 * 1000;
      // Subtract 1 day before adding to the base date to compensate for the difference in calculation
      const baseDate = new Date(2022, 0, 1);
      return new Date(baseDate.getTime() + julianMilliseconds);
    }
    return null;
  };

  useEffect(() => {
    getProducts({
      criterias: {
        code: 'MIFARE_1K',
        dateEnd: moment().toISOString(),
      },
    }).then((data) => {
      setCardProduct(data.content[0]);
    });
  }, []);

  useEffect(() => {
    if (userTypes?.content?.length > 0) {
      const userTypeObject =
        userTypes.content?.find((ut) => ut.id === userType) || null;
      const { institutions } = userTypeObject;
      const { institutionId } =
        institutions.filter((ins) => ins === user?.institutionId).length > 0
          ? user
          : '';
      if (institutionId) {
        getInstitutionById(institutionId).then((res) => {
          if (products.length === 0) {
            handleRetiredCategory(institutionId, res?.name);
            setValue('products[0].institutionId', institutionId);
            setValue('products[0].key', uuidv4());
          }
        });
      }
    }
  }, [setValue, user, userTypes, userType, products, handleRetiredCategory]);

  useEffect(() => {
    const userTypeObject =
      userTypes.content?.find((ut) => ut.id === userType) || null;
    if (userTypeObject) {
      const { institutions } = userTypeObject;
      const { institutionId } =
        institutions.filter((ins) => ins === user?.institutionId).length > 0
          ? user
          : '';
      if (products.length === 0) {
        setValue('products[0].institutionId', institutionId);
        setValue('products[0].key', uuidv4());
      }
    }
  }, [setValue, user, userTypes, userType, products]);

  useEffect(() => {
    getRolesWithDetails().then((data) => {
      setPassengerRoleId(data);
    });
  }, []);

  const addUser = useCallback(
    (index) => {
      const { productPersons = [] } = products[index];
      if (productPersons.length === 0) {
        setValue(`products[${index}].productPersons`, [
          ...productPersons,
          {
            person: {
              key: uuidv4(),
              userTypeId: userType === -1 ? pensionarUserType : userType,
              email: user.email,
              fullname: user.fullname,
              identityCard: user.identityCard,
              phone: user.phone,
              subdivisionId: user.subdivisionId,
              metaData1: user.metaData1,
              foreignCitizen: user.foreignIdentityCard,
            },
            key: uuidv4(),
          },
        ]);
      }
    },
    [setValue, products, user, userType, pensionarUserType],
  );

  const userTypeCode = userTypes.content?.find(
    (ut) => ut.id === userType,
  )?.code;
  const userTypeDetails = userTypes.content?.find(
    (ut) => ut.id === userType,
  )?.details;

  const configureProduct = useCallback(
    (product, idx) => {
      if (!product.productId) {
        notification.error({ message: t('errors.selectProduct') });
        return;
      }
      const originalProduct = productTypes?.find(
        ({ id }) => id === product?.productId,
      );

      const hasRestriction = originalProduct.productRestrictions.some(
        (item) =>
          item.restriction === 'LINE_GROUPS' &&
          (item.value1 === 1 ||
            item.value3 === 1 ||
            item.value5 === 1 ||
            item.value7 === 1),
      );
      if (hasRestriction) {
        const whereIsRestrictionData = originalProduct.productRestrictions
          .map((item) => {
            if (
              item.restriction === 'LINE_GROUPS' &&
              (item.value1 === 1 ||
                item.value3 === 1 ||
                item.value5 === 1 ||
                item.value7 === 1)
            ) {
              const results = [];
              if (item.value1 === 1) {
                results.push('value1');
              }
              if (item.value3 === 1) {
                results.push('value3');
              }
              if (item.value5 === 1) {
                results.push('value5');
              }
              if (item.value7 === 1) {
                results.push('value7');
              }
              return results;
            }
            return [];
          })
          .filter((list) => list.length > 0);

        const titleFound = titles.find(
          (title) => title.productId === originalProduct.id,
        );
        const ownedTitle = titleFound !== undefined ? titleFound : null;
        const endDateA = calculatedateFromJulian(
          ownedTitle?.valEndDateA,
          ownedTitle?.valEndTimeA,
        );
        const endDateB = calculatedateFromJulian(
          ownedTitle?.valEndDateB,
          ownedTitle?.valEndTimeB,
        );

        const now = new Date();
        const isDateAOutsideInterval =
          endDateA === null || (endDateA != null && endDateA < now);
        const isDateBOutsideInterval =
          endDateB === null || (endDateB != null && endDateB < now);
        const canAddTitleOnEveryLine =
          ownedTitle === null ||
          (isDateBOutsideInterval === true && isDateAOutsideInterval === true);
        if (
          originalProduct?.category === 'PASS' &&
          hasRestriction &&
          canAddTitleOnEveryLine === false
        ) {
          setLine(ownedTitle?.lines);
          setWhereIsRestriction(whereIsRestrictionData);
        }
      }
      configForm.setFieldsValue({
        product: {
          ...product,
          count: product.count || 1,
          lines: [],
          startDate: product.startDate
            ? moment(product.startDate)
            : getDefaultDate(originalProduct.id),
        },
        idx,
        originalProduct,
      });
      setIsModalVisible(true);
    },
    [configForm, productTypes, getDefaultDate, t, titles],
  );

  return (
    <>
      <PageTitle title={t('entity.orders.productList._plural')}>
        {products.length === 0 && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={disabled}
            onClick={() => {
              setValue('products', [...products, { key: uuidv4() }]);
            }}
          >
            {t('entity.orders.add')}
          </Button>
        )}
      </PageTitle>
      {notStorableProducts.map((product, idx) => (
        <div key={product.key || product.id}>
          <FormWrapper>
            {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
              bulkType,
            ) && (
              <FormWrapper.Single>
                <SearchInstitutions
                  label={t('entity.admin.user.institutionId')}
                  props={{
                    disabled,
                    searchField: 'name',
                    field: 'id',
                    resetSubdivisions: () =>
                      setValue(`products[${idx}]`, {
                        ...products[idx],
                        productPersons: products[idx].productPersons?.map(
                          (pp) => ({
                            ...pp,
                            person: {
                              ...pp.person,
                              subdivisionId: null,
                            },
                          }),
                        ),
                      }),
                    setUserIdForSpecificUserType: (
                      institutions,
                      selectedValue,
                    ) => {
                      const userTypeObject =
                        userTypes.content?.find((ut) => ut.id === userType) ||
                        null;
                      if (userTypeObject != null) {
                        const institution = institutions.filter(
                          (ins) => ins.id === selectedValue,
                        );
                        setInst(selectedValue);
                        const maintainerId =
                          institution != null && institution.length > 0
                            ? institution[0]?.maintainerId
                            : null;
                        setValue('userId', maintainerId);
                        if (institution.length > 0 && !maintainerId) {
                          switch (env) {
                            case 'sibiu':
                              notification.error({
                                message: t(
                                  'errors.maintainerDoesNotExistSibiu',
                                ),
                              });
                              break;
                            case 'tgmures':
                              notification.error({
                                message: t(
                                  'errors.maintainerDoesNotExistTgMures',
                                ),
                              });
                              break;
                            case 'slobozia':
                              notification.error({
                                message: t(
                                  'errors.maintainerDoesNotExistSlobozia',
                                ),
                              });
                              break;
                            default:
                              notification.error({
                                message: t(
                                  'errors.maintainerDoesNotExistDefault',
                                ),
                              });
                          }
                        }
                      }
                    },

                    setUserTypeId: (
                      selectedInstitutionId,
                      selectedInstitutionName,
                    ) =>
                      handleRetiredCategory(
                        selectedInstitutionId,
                        selectedInstitutionName,
                      ),
                    userTypes,
                    institutionId: inst,
                    userTypeId: userType,
                    ...custom(`products[${idx}].institutionId`),
                  }}
                />
              </FormWrapper.Single>
            )}
            <FormWrapper.Single>
              <FormWrapper.SelectProduct
                label={t('entity.orders.product')}
                props={{
                  disabled,
                  productName: products[idx].productName,
                  options: productTypes.map((type) => ({
                    ...type,
                    disabled: products.find((p) => p.productId === type.id),
                  })),
                  lang: language,
                  ...select(`products[${idx}].productId`),
                  onChange: (productId) => {
                    productTypes
                      .filter((pt) => pt.id === productId)
                      .map((productType) =>
                        setCurrentProduct(productType.localizedName[language]),
                      );
                    const { name, code } = getProduct(productId);
                    const userTypeObject =
                      userTypes.content.find((ut) => ut.id === userType) ||
                      null;
                    setCurrentUserShowColumns(userTypeObject?.showColumns);
                    const { institutions } = userTypeObject;
                    const { institutionId } =
                      institutions.filter((ins) => ins === user.institutionId)
                        .length > 0
                        ? user
                        : '';
                    if (institutionId) {
                      getInstitutionById(institutionId).then((result) => {
                        if (!result?.maintainerId) {
                          notification.error({
                            message: t('errors.maintainerDoesNotExist'),
                          });
                        }
                      });
                    }
                    setInst(institutionId);
                    const productPersons = value?.products[
                      idx
                    ]?.productPersons?.map((pp) => ({
                      ...pp,
                      person: {
                        subdivisionId: user.subdivisionId,
                        metaData1: user.metaData1,
                        userType,
                      },
                    }));
                    setValue(`products[${idx}]`, {
                      ...products[idx],
                      productId,
                      productPersons,
                      productCode: code,
                      productName: name,
                      startDate: null,
                      roleId:
                        userTypeObject != null &&
                        UserTypesEnum.ELEV === userTypeObject.code
                          ? passengerRoleId
                          : null,
                    });
                    addUser(idx);
                  },
                }}
              />
            </FormWrapper.Single>
            {bulkType === BULK_TYPES.ISSUING_CARDS ? (
              <FormWrapper.Single>
                {cardProduct ? (
                  <FormWrapper.Input
                    label={t('entity.orders.card')}
                    props={{ disabled: true, value: cardProduct.name }}
                  />
                ) : null}
              </FormWrapper.Single>
            ) : null}
          </FormWrapper>
          <Row style={{ paddingBottom: '1rem' }} justify="start">
            {ORDER_STATUS.CONFIRMED === status &&
              previewGratuities.includes(
                userTypes.content?.find((ut) => ut.id === userType)?.code,
              ) && (
                <Col>
                  <div className="ant-typography" />
                  <Tooltip
                    title={t('entity.orders.downloadRaportSolAbonament')}
                  >
                    <Button
                      shape="square"
                      type="link"
                      style={{
                        color: '#fafafa',
                        background: '#1890ff',
                        marginRight: 10,
                      }}
                      // icon={<FileOutlined style={{ fontSize: '150%' }} />}
                      onClick={() => downloadFiles(attachments[0].id, value.id)}
                    >
                      {t('entity.orders.downloadRaportSolAbonament')}
                    </Button>
                  </Tooltip>
                </Col>
              )}
            <Col style={{ verticalAlign: 'top' }}>
              <div className="ant-typography" />
              <Tooltip title={t('entity.orders.configureProduct')}>
                <Button
                  disabled={disabled}
                  shape="square"
                  type="link"
                  style={{
                    color: disabled ? '#ccc' : '#fafafa',
                    background: disabled ? '#f0f0f0' : '#1890ff',
                    border: disabled ? '1px solid #ccc' : 'none',
                    marginRight: 10,
                  }}
                  // icon={<SettingOutlined style={{ fontSize: '150%' }} />}
                  onClick={() => configureProduct(product, idx)}
                >
                  {t('entity.orders.configureProduct')}
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {product.productPersons && product.productPersons.length > 0 && (
                <ProductUsersList
                  index={idx}
                  institutionId={products[idx].institutionId}
                  disabled={disabled}
                  userTypes={userTypes}
                  userTypeCode={userTypeCode}
                  fields={
                    bulkType === BULK_TYPES.ISSUING_CARDS
                      ? [
                          'email',
                          'fullname',
                          'foreignCitizen',
                          'identityCard',
                          'phone',
                          ...userTypeShowColumns,
                        ]
                      : [
                          'cardLogicalId',
                          'fullname',
                          'foreignCitizen',
                          'identityCard',
                          ...userTypeShowColumns,
                        ]
                  }
                />
              )}
            </Col>
          </Row>
          {product.productPersons &&
            product.productPersons.length > 0 &&
            userTypeDetails &&
            userTypeDetails.split('\n').map((dt) => <h3 key={dt}> {dt} </h3>)}
          <Space direction="vertical">
            <Divider dashed />
          </Space>
        </div>
      ))}

      <Modal
        title={t(`entity.orders.configuration`)}
        visible={isModalVisible}
        onOk={() => {
          configForm
            .validateFields()
            .then((val) => {
              const idx = configForm.getFieldValue('idx');
              const { product } = val;

              setValue(`products[${idx}]`, {
                ...products[idx],
                ...product,
              });
              setIsModalVisible(false);
            })
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          configForm.resetFields();
          setIsModalVisible(false);
        }}
      >
        <Form form={configForm} layout="vertical">
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.originalProduct?.category !== next.originalProduct?.category
            }
          >
            {({ getFieldValue }) => {
              const categ = getFieldValue(['originalProduct', 'category']);
              return (
                <>
                  <Form.Item style={{ textAlign: 'center' }}>
                    <h3>{currentProduct}</h3>
                  </Form.Item>

                  {categ && (categ === 'TICKET' || categ === 'PURSE') && (
                    <Row gutter={16}>
                      <Col span={8}>
                        <POSButton
                          title="Scade"
                          onClick={() =>
                            configForm.setFieldsValue({
                              product: {
                                ...configForm.getFieldValue('product'),
                                count: Math.max(
                                  1,
                                  configForm.getFieldValue([
                                    'product',
                                    'count',
                                  ]) - 1,
                                ),
                              },
                            })
                          }
                          color="red"
                          size="xsmall"
                        />
                      </Col>
                      <Col span={8}>
                        <Form.Item noStyle name={['product', 'count']}>
                          <Input
                            style={{ height: '100%', fontSize: '2rem' }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <POSButton
                          title="Adauga"
                          onClick={() =>
                            configForm.setFieldsValue({
                              product: {
                                ...configForm.getFieldValue('product'),
                                count:
                                  configForm.getFieldValue([
                                    'product',
                                    'count',
                                  ]) + 1,
                              },
                            })
                          }
                          color="green"
                          size="xsmall"
                        />
                      </Col>
                    </Row>
                  )}
                </>
              );
            }}
          </Form.Item>

          <Divider dashed />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return prev?.product?.productId !== next?.product?.productId;
            }}
          >
            {({ getFieldValue }) => {
              return (
                getFieldValue(['originalProduct', 'validityStartType']) ===
                  'FUTURE' && (
                  <Form.Item
                    label={t('entity.orders.startDateSubscription')}
                    name={['product', 'startDate']}
                    rules={[
                      {
                        required: true,
                        message: t('errors.selectDate'),
                      },
                    ]}
                  >
                    <DatePickerLocale
                      showTime
                      initialValues={getDefaultDate(
                        products[configForm.getFieldValue('idx')].productId,
                      )}
                      shouldNotBeDisabled={false}
                      format="DD.MM.YYYY HH:mm"
                      disabledDate={disabledDate}
                      disabledTime={disabledTime}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )
              );
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return prev?.originalProduct?.id !== next?.originalProduct?.id;
            }}
          >
            {({ getFieldValue }) => {
              const restrictions = getFieldValue([
                'originalProduct',
                'productRestrictions',
              ])?.find(({ restriction }) => restriction === 'LINE_GROUPS');
              return (
                restrictions && (
                  <Restriction
                    form={configForm}
                    restrictions={restrictions}
                    line={line}
                    whereIsRestriction={whereIsRestriction}
                  />
                )
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Products;
