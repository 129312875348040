import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification, Space } from 'antd';
import { SaveOutlined, TransactionOutlined } from '@ant-design/icons';
import { ORDER_STATUS } from '../../pages/orders/constants';
import { useRouteParams } from '../../hooks/useRouteParams';
import { findOne, remove, update } from '../../services/orders';
import AuthContext from '../auth';
import OPModal from './OPModal';
import DeleteItemIcon from '../buttons/DeleteItemIcon';

const IssuingOrderButtons = ({ form, formRef }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();
  const { user } = useContext(AuthContext);
  const { status, id } = form.value;

  const submitWithAction = useCallback(
    (action, reason) => {
      if (ORDER_STATUS.REJECTED !== action) {
        form.setValue('action', action);
        formRef.current.dispatchEvent(new Event('submit'));
      } else {
        const orderForm = form.value;
        if (reason !== null && reason !== undefined && reason.length > 0) {
          orderForm.cancelReason = reason;
          form.setValue('action', action);
          update({ ...form.value, action })
            .then((order) => {
              form.setFormValue({
                ...order,
                disabled: order.status !== ORDER_STATUS.SELECT_PRODUCTS,
              });
              notification.success({
                message: t('actions.saveSuccessful'),
              });
            })
            .catch((msg) => {
              console.error(msg);
              const { inner } = msg || {};
              const { _: messageCode } = inner || {};

              notification.error({
                message: messageCode
                  ? t(messageCode, inner)
                  : t('actions.saveFailed'),
              });
              return Promise.reject(msg);
            });
        }
      }
    },
    [form, formRef, t],
  );

  return (
    <Space>
      {id && status === ORDER_STATUS.SELECT_PRODUCTS && (
        <Button
          className="ant-btn-success"
          type="primary"
          icon={<SaveOutlined />}
          htmlType="submit"
          onClick={() => submitWithAction(ORDER_STATUS.DOC_VALIDATION)}
          disabled={form.isLoading}
        >
          {t('actions.orders.docValidation')}
        </Button>
      )}
      {[ORDER_STATUS.PAYMENT].includes(status) && (
        <>
          <form
            style={{ width: '120%', display: 'inline-flex' }}
            method="POST"
            action={`${window._env_.API_BACKEND_URL}/portal/api/v1/orders/bulk/checkout/${id}`}
          >
            <input
              type="hidden"
              name="x-tenant-key"
              value={window._env_.API_TENANT_KEY}
            />
            <input type="hidden" name="email" value={user?.email} />
            <input type="hidden" name="userId" value={user?.id} />
            <input type="hidden" name="fromUrl" value="orders" />
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="submit"
              icon={<TransactionOutlined />}
              style={{ marginLeft: '1.5%' }}
              disabled={form.isLoading}
            >
              {t('actions.orders.paid')}
            </Button>
          </form>
          <OPModal props={{ orderId: id, setFormValue: form.setFormValue }} />
        </>
      )}
      {[ORDER_STATUS.SELECT_PRODUCTS].includes(status) && !isNew && (
        <DeleteItemIcon
          title={t('entity.orders._cancel', form.value)}
          message={t('entity.orders._canceled', form.value)}
          actionName="actions.cancellation"
          okText="actions.cancellation"
          type="danger"
          item={form.value}
          action={remove}
          showIcon={false}
          style={null}
          disabled={form.isLoading}
          reload={() =>
            findOne(id)
              .then((order) => {
                form.setFormValue({
                  ...order,
                  disabled: order.status !== ORDER_STATUS.SELECT_PRODUCTS,
                });
              })
              .catch((msg) => {
                console.error(msg);
                const { inner } = msg || {};
                const { _: messageCode } = inner || {};

                notification.error({
                  message: messageCode
                    ? t(messageCode, inner)
                    : t('actions.getFailed'),
                });
                return Promise.reject(msg);
              })
          }
        />
      )}
    </Space>
  );
};

export default IssuingOrderButtons;
