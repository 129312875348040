import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Button, Input, notification, Space } from 'antd';

import { AUTH_SERVICE_URI, AUTH_BASE_URI } from '../../services/auth';

const LoginForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const disableReCAPTCHA = window._env_.DISABLE_ReCAPTCHA;

  const [reCaptchaChecked, setReCaptchaChecked] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleForgotPassword = () => {
    history.push('/forgot');
  };

  useEffect(() => {
    const { search } = window.location;
    let updatedSearch = '';

    const processNotifications = () => {
      if (search.includes('?invalid')) {
        notification.error({
          message: t('errors.invalidCredentials'),
        });
        updatedSearch = search.replace(/[?&]invalid/, '');
      }

      if (search.includes('?accountBlocked')) {
        notification.error({
          message: t('errors.accountBlocked'),
        });
        updatedSearch = search.replace(/[?&]accountBlocked/, '');
      }

      history.replace({
        ...history.location,
        search: updatedSearch,
      });
    };

    const delay = 500;
    const timeoutId = setTimeout(processNotifications, delay);
    return () => clearTimeout(timeoutId);
  }, [t, history]);

  const handleCaptchaChange = (value) => {
    setReCaptchaChecked(!!value);
  };

  const isSubmitDisabled =
    disableReCAPTCHA === 'true'
      ? !username || !password
      : !(reCaptchaChecked && username && password);

  return (
    <form
      method="POST"
      action={`${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/local`}
    >
      <input
        type="hidden"
        name="x-tenant-key"
        value={window._env_.API_TENANT_KEY}
      />

      <div className="form-group">
        <Input
          name="username"
          placeholder="Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <div className="form-group">
        <Input.Password
          name="password"
          placeholder="Parola"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      {disableReCAPTCHA !== 'true' && (
        <div className="form-group">
          <ReCAPTCHA
            name="recaptcha"
            sitekey={window._env_.RECAPTCHA_SITE_KEY}
            hl="ro"
            onChange={handleCaptchaChange}
          />
        </div>
      )}

      <Space direction="vertical" style={{ display: 'flex' }}>
        <Button
          type="primary"
          block
          htmlType="submit"
          disabled={isSubmitDisabled}
        >
          {t('startPage.signIn')}
        </Button>
        <Button type="button" onClick={handleForgotPassword}>
          {t('startPage.forgotPassword')}
        </Button>
      </Space>
    </form>
  );
};

export default LoginForm;
