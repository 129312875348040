import { search, get } from '../helpers/api';

export const AUTH_SERVICE_URI = '/portal';
export const AUTH_BASE_URI = '/api/v1/taxonomies';

export const getTaxonomyData = async (data, taxonomy) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${taxonomy}`, data);

export const getDocTypes = async (data) => getTaxonomyData(data, 'doc-types');

export const getUserTypes = async (data) => getTaxonomyData(data, 'user-types');

export const getPosesByRole = async () => {
  return search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/poses`, {
    criterias: { byrole: '', deleted: false },
  });
};

export const getSubdivisions = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/institution-subdivisions`, data);

export const getInstitutions = async (data) =>
  getTaxonomyData(data, 'institutions');

export const getCountries = async (data) => getTaxonomyData(data, 'countries');

export const getInstitutionById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/institution/${id}`);
