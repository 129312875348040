import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { AuthContextWrapper, PrivateRoute } from './components/auth';
import ForgotPassword from './components/auth/ForgotPassword';
import { CartContextWrapper } from './components/CartContextWrapper';

import {
  Checkout,
  EditOrder,
  Home,
  Login,
  NotFound,
  OrdersList,
  Profile,
} from './pages';
import ViewAccount from './pages/accounts/ViewAccount';
import ViewAccountProducts from './pages/accounts/ViewAccountProducts';
import ViewAccountTransactions from './pages/accounts/ViewAccountTransactions';
import EditIndividulaOrder from './pages/orders/EditIndividualOrder';
import IndividualOrdersList from './pages/orders/IndividualOrdersList';
import ViewAbonomentsDescendantsList from './pages/accounts/ViewAbonomentsDescendantsList';
import ReceiptsList from './pages/receipts/ReceiptsList';
import LoginSocial from './pages/LoginSocial';

const App = () => (
  <AuthContextWrapper>
    <CartContextWrapper>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route exact path="/loginSocial" component={LoginSocial} />
          <Route exact path="/reset/:token" component={ForgotPassword} />

          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/profile/:id" component={Profile} />
          <PrivateRoute exact path="/checkout" component={Checkout} />
          <PrivateRoute exact path="/accounts/:id" component={ViewAccount} />
          <PrivateRoute
            exact
            path="/individual-orders/:id"
            component={EditIndividulaOrder}
            permissions={['SYS_SUPERUSER', 'ADD_INDIVIDUAL_ORDER']}
          />
          <PrivateRoute
            exact
            path="/individual-orders"
            component={IndividualOrdersList}
            permissions={['SYS_SUPERUSER', 'ADD_INDIVIDUAL_ORDER']}
          />
          <PrivateRoute exact path="/receipts" component={ReceiptsList} />
          <PrivateRoute
            exact
            path="/orders/:id"
            component={EditOrder}
            permissions={['SYS_SUPERUSER', 'ADD_COMPANY_ORDER']}
          />
          <PrivateRoute
            exact
            path="/orders"
            component={OrdersList}
            permissions={['SYS_SUPERUSER', 'ADD_COMPANY_ORDER']}
          />
          <PrivateRoute
            exact
            path="/accounts/:id/products"
            component={ViewAccountProducts}
          />
          <PrivateRoute
            exact
            path="/accounts/:id/transactions"
            component={ViewAccountTransactions}
          />
          <PrivateRoute
            exact
            path="/accounts/:id/abonoments-descendants"
            component={ViewAbonomentsDescendantsList}
          />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </CartContextWrapper>
  </AuthContextWrapper>
);

export default App;
