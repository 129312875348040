import React, {
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import Column from '../../helpers/columns';

import {
  attachDescendentAccount,
  detachDescendentAccount,
  downloadDescendantsByAccountId,
  getDescendantsVwByAccountId,
} from '../../services/accounts';
import AuthContext from '../auth';
import useDatasource, { filterText } from '../../hooks/useDatasource';
import { CUSTOMER_TYPE } from '../../pages/orders/constants';
import PopupConfirmButton from '../buttons/PopupConfirmButton';

const CustomerTypes = {
  content: [{ id: 'LEGAL' }, { id: 'INDIVIDUAL' }],
  loading: false,
  error: false,
};

const AccountDescendantsList = ({ id, customerType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const fetcher = useCallback(
    (arg) => getDescendantsVwByAccountId(id, arg),
    [id],
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addDescendentForm] = Form.useForm();
  const [enablePFDescendentsString] = useState(
    window._env_.ENABLE_PF_DESCENDENTS,
  );
  const enablePFDescendents = enablePFDescendentsString === 'true';

  const {
    sort,
    pagination,
    criterias,
    content,
    loading,
    handleChange,
    reload,
  } = useDatasource(fetcher);

  useEffect(() => {
    if (typeof reload === 'function') {
      reload();
    }
  }, [id, reload]);

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleAttachNewDescendent = () => {
    addDescendentForm
      .validateFields()
      .then((val) => {
        attachDescendentAccount(id, val)
          .then(() => {
            setIsModalVisible(false);
            reload();
            notification.success({
              message: t('actions.saveSuccessful'),
            });
          })
          .catch((msg) => {
            console.error(msg);
            const { inner } = msg || {};
            const { _: messageCode } = inner || {};

            notification.error({
              message: messageCode
                ? t(messageCode, inner)
                : t('actions.saveFailed'),
            });
          });
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const handleDetachDescendent = useCallback(
    (record) => {
      detachDescendentAccount(record.id).then(() => {
        notification.success({
          message: t('actions.saveSuccessful'),
        });
        reload();
      });
    },
    [reload, t],
  );

  const columns = useMemo(
    () => [
      Column.text('userName', t('entity.account.descendants.name'), {
        filter: true,
        sort: false,
      }),
      Column.dictionary(
        'customerType',
        t('entity.account.descendants.customerType._'),
        CustomerTypes,
        {
          filter: true,
          width: 150,
          dictLabel: (entry) =>
            t(`entity.account.descendants.customerType.${entry.id}`),
        },
      ),
      Column.text(
        'userIdentifier',
        t('entity.account.descendants.userIdentifier'),
        {
          filter: true,
          sort: false,
        },
      ),
      {
        title: t('entity.account.descendants.logicIdSerial'),
        dataIndex: 'logicIdSerial',
        key: 'logicIdSerial',
        width: 300,
        sorter: true,
        render: (logicIdSerial) => Number(logicIdSerial),
        ...filterText(t('entity.account.descendants.logicIdSerial')),
      },
      {
        title: t('entity.account.descendants.status._'),
        key: 'cardsDeleted',
        dataIndex: 'cardsDeleted',
        width: 200,
        sorter: true,
        render: (cardsDeleted) => (
          <Badge
            color={cardsDeleted === false ? 'green' : 'red'}
            text={
              cardsDeleted === false
                ? t('entity.account.descendants.status.active')
                : t('entity.account.descendants.status.blocked')
            }
          />
        ),
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title={t('actions.view')} key="view">
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={() => {
                history.push({
                  pathname: `/accounts/${record.id}`,
                  state: {
                    enabled: record?.cardsDeleted,
                    isDescendent: true,
                    fromCustomerType: customerType,
                  },
                });
              }}
              size="middle"
            />
          </Tooltip>
          {CUSTOMER_TYPE.INDIVIDUAL === customerType && enablePFDescendents && (
            <PopupConfirmButton
              buttonName=""
              title="pages.descendents.modal.message"
              item={record}
              okText="actions.detach"
              cancelText="actions.cancel"
              icon={<DeleteOutlined />}
              action={handleDetachDescendent}
              reload={() => null}
              tooltip="actions.detach"
              buttonStyle={{
                background: 'transparent',
                color: 'red',
                border: 'transparent',
                boxShadow: 'none',
              }}
            />
          )}
        </span>
      )),
    ],
    [history, t, enablePFDescendents, handleDetachDescendent, customerType],
  );

  return (
    <>
      {user.customerType === 'LEGAL' && (
        <Divider style={{ fontWeight: 500, fontSize: '1.5rem' }}>
          <Button
            icon={<DownloadOutlined />}
            onClick={() =>
              downloadDescendantsByAccountId(id, {
                sort,
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                criterias,
              })
            }
          >
            {t('actions.download')}
          </Button>
        </Divider>
      )}

      <div>
        {CUSTOMER_TYPE.INDIVIDUAL === customerType && enablePFDescendents && (
          <Button
            onClick={handleModalOpen}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            {t('actions.addNewDescendent')}
          </Button>
        )}
        <Table
          style={{ padding: '2rem' }}
          columns={columns}
          rowKey="id"
          loading={loading}
          dataSource={content}
          onChange={handleChange}
          pagination={pagination}
        />
      </div>

      <Modal
        title={t('pages.descendents.modal.title')}
        visible={isModalVisible}
        closable={false}
        footer={
          <Space>
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              onClick={() => handleAttachNewDescendent()}
            >
              {t('actions.accept')}
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={addDescendentForm} layout="vertical">
          <Form.Item
            name={['identityCard']}
            label={t('pages.descendents.modal.form.identityCard')}
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['cardNo']}
            label={t('pages.descendents.modal.form.cardNo')}
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AccountDescendantsList;
