import React from 'react';

import { getI18n } from 'react-i18next';
import moment from 'moment';
import { range } from 'lodash';
import DatePickerEnglish from './DatePickerEnglish';
import DatePickerRomanian from './DatePickerRomanian';
import DatePickerGerman from './DatePickerGerman';

const DatePickerLocale = (params) => {
  const { language } = getI18n();
  const { shouldNotBeDisabled } = params;
  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    if (shouldNotBeDisabled) {
      return current.isBefore(moment(), 'day');
    }
    return (
      current.isBefore(moment(), 'day') ||
      Date.parse(current) > Date.parse(moment().add(90, 'days'))
    );
  };

  const disabledTime = (current) => {
    if (moment().isSame(current, 'day')) {
      // Disable hours before the current hour
      const currentHour = moment().hour();
      const currentMinute = moment().minute();
      const currentSecond = moment().second();

      return {
        disabledHours: () => range(0, currentHour),
        disabledMinutes: (hour) =>
          hour === currentHour ? range(0, currentMinute) : [],
        disabledSeconds: (hour, minute) =>
          hour === currentHour && minute === currentMinute
            ? range(0, currentSecond)
            : [],
      };
    }

    return null; // Enable all hours for other days
  };

  const props = {
    ...params,
    showNow: false,
    disabledDate,
    disabledTime,
    inputReadOnly: true,
    dateRender: (current) => {
      if (!moment().isSame(current, 'day')) {
        current.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      } else {
        current.set({
          hour: moment().hour(),
          minute: moment().minute(),
          second: moment().second(),
          millisecond: moment().millisecond(),
        });
      }
      return current.date();
    },
  };

  switch (language) {
    case 'en':
      return <DatePickerEnglish {...props} />;
    case 'de':
      return <DatePickerGerman {...props} />;
    default:
      return <DatePickerRomanian {...props} />;
  }
};
export default DatePickerLocale;
