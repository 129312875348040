import React from 'react';
import { BULK_TYPES } from '../../pages/orders/constants';
import FormWrapper from '../FormWrapper';

function BulkSelector({ props }) {
  const { t, isNew, select } = props;
  return (
    <FormWrapper.Select
      label={t('entity.orders.bulkTypes')}
      props={{
        disabled: !isNew,
        options: Object.keys(BULK_TYPES).map((o) => ({
          id: o,
        })),
        allowClear: false,
        labelProp: (obj) => t(`entity.orders.bulkType.${obj.id}`),
        ...select('bulkType'),
      }}
    />
  );
}

export default BulkSelector;
