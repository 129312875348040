import { genericCRUDService } from '../helpers/api';

export const BACKEND_SERVICE_URI = '/portal';
export const RECEIPTS_BASE_URI = '/api/v1/receipts';

// eslint-disable-next-line
export const { findAll, findOne, create, remove } = genericCRUDService(
  BACKEND_SERVICE_URI,
  RECEIPTS_BASE_URI,
);

export const downloadReceipe = (reportUri, object, filename) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${BACKEND_SERVICE_URI}${RECEIPTS_BASE_URI}${reportUri}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
        'X-Tenant-Key': window._env_.API_TENANT_KEY,
      },
      body: JSON.stringify(object),
    },
  )
    .then((res) =>
      res.blob().then((val) => {
        return res.ok ? Promise.resolve(val) : Promise.reject(val);
      }),
    )
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
