import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { BULK_TYPES, ORDER_STATUS } from '../../pages/orders/constants';
import AuthContext from '../auth';
import PopupConfirmButton from '../buttons/PopupConfirmButton';
import {
  addStocksLegalFields,
  generateRaportGratuitati,
} from '../../services/orders';
import { getUserDetails } from '../../services/users';
import { UserTypesEnum } from '../../services/constants';
import ChoseBillingModal from '../ChoseBillingModal';

const IssuingOrderButtons = ({
  form,
  formRef,
  bulkType,
  userType,
  userTypes,
  totalPrice,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { status, id, products } = form.value;
  const [userDetails, setUserDetails] = useState(null);
  const euPlatescFormRef = useRef(null);
  const product = products[0];
  const person =
    product &&
    product.productPersons &&
    product.productPersons[0] &&
    product.productPersons[0].person
      ? product.productPersons[0].person
      : undefined;
  const enableGratuitiesReportsString = useMemo(
    () => window._env_.ENABLE_GRATUITIES_REPORTS,
    [],
  );
  const enableGratuitiesReports = enableGratuitiesReportsString === 'true';

  useEffect(() => {
    getUserDetails()
      .then((o) => {
        setUserDetails(o);
      })
      .catch((err) => console.log('err', err));
  }, []);

  const handleSubmit = useCallback(
    (value) => {
      if (value !== null) {
        addStocksLegalFields(value, id).then(() => {
          if (euPlatescFormRef.current) {
            euPlatescFormRef.current.submit();
          }
        });
      } else if (euPlatescFormRef.current) {
        euPlatescFormRef.current.submit();
      }
    },
    [id],
  );

  const userTypeCode = userTypes.content?.find(
    (ut) => ut.id === userType,
  )?.code;

  const gratuitatiReportData = useMemo(
    () => ({
      requesterName: person?.fullname,
      identityCard: person?.identityCard,
      email: person?.email,
      phone: person?.phone,
      cardNo: person?.cardLogicalId,
      institutionId: product?.institutionId,
      subdivisionId: person?.subdivisionId,
      address: {
        locality: userDetails?.address2?.locality,
        street: userDetails?.address2?.street,
        apartment: userDetails?.address2?.apartment,
        building: userDetails?.address2?.building,
        number: userDetails?.address2?.number,
      },
      month: new Date(product?.startDate),
      issuingCards: BULK_TYPES.ISSUING_CARDS === bulkType,
      reportFileName: `raport_gratuitati_${person?.fullname}.pdf`,
      userType: userTypeCode,
    }),
    [product, person, bulkType, userDetails, userTypeCode],
  );

  const submitWithAction = useCallback(
    (action) => {
      if (
        ORDER_STATUS.DOC_VALIDATION === action &&
        enableGratuitiesReports &&
        [UserTypesEnum.ELEV].includes(userTypeCode)
      ) {
        generateRaportGratuitati(gratuitatiReportData)
          .then((files) => {
            form.setValue('attachments', files);
            form.setValue('action', action);
            if (typeof formRef.current.requestSubmit === 'function') {
              formRef.current.requestSubmit();
            } else {
              formRef.current.dispatchEvent(new Event('submit'));
            }
          })
          .catch((err) => {
            console.log('eroare', err);
          });
      } else {
        form.setValue('action', action);
        if (typeof formRef.current.requestSubmit === 'function') {
          formRef.current.requestSubmit();
        } else {
          formRef.current.dispatchEvent(new Event('submit'));
        }
      }
    },
    [
      enableGratuitiesReports,
      form,
      formRef,
      gratuitatiReportData,
      userTypeCode,
    ],
  );

  return (
    <Space>
      {status === ORDER_STATUS.SELECT_PRODUCTS && (
        <PopupConfirmButton
          title={t('entity.orders.productList._raportGeneration')}
          message={t('entity.orders.productList._raportGenerationDone')}
          item={ORDER_STATUS.DOC_VALIDATION}
          action={submitWithAction}
          reload={() => null}
          size="medium"
        />
      )}
      {totalPrice > 0 && status === ORDER_STATUS.PAYMENT && (
        <>
          <form
            method="POST"
            ref={euPlatescFormRef}
            action={`${window._env_.API_BACKEND_URL}/portal/api/v1/orders/bulk/checkout/${id}`}
          >
            <input
              type="hidden"
              name="x-tenant-key"
              value={window._env_.API_TENANT_KEY}
            />
            <input type="hidden" name="email" value={user?.email} />
            <input type="hidden" name="userId" value={user?.id} />
            <input type="hidden" name="fromUrl" value="individual-orders" />
          </form>
          <ChoseBillingModal onSubmit={handleSubmit} />
        </>
      )}
    </Space>
  );
};

export default IssuingOrderButtons;
