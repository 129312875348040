import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { getUserTypes } from '../../services/taxonomies';
import FormWrapper from '../FormWrapper';
import { UserTypesEnum } from '../../services/constants';

function UserTypeSelector({ props }) {
  const {
    t,
    setValue,
    isNew,
    disabled,
    handleUserTypeChange,
    userType,
    products,
  } = props;
  const [options, setOptions] = useState([]);
  const [retiredUserTypes, setRetiredUserTypes] = useState([]);

  const userTypeId = useMemo(() => {
    if (
      !isNew &&
      products[0] &&
      products[0].persons &&
      products[0]?.persons[0]?.userTypeId
    ) {
      if (
        retiredUserTypes.filter(
          (ut) => ut.id === products[0].persons[0].userTypeId,
        ).length > 0
      ) {
        return -1;
      }
      return products[0].persons[0].userTypeId;
    }
    return userType;
  }, [isNew, products, userType, retiredUserTypes]);

  useEffect(() => {
    getUserTypes()
      .then((data) => {
        return data.content.filter(
          (type) => type.code !== null && type.hasDiscountOrFree,
        );
      })
      .then((data) => {
        const isRetiredCategory = (code) =>
          [UserTypesEnum.PENSIONAR, UserTypesEnum.PENSIONAR_CONVENTIE].includes(
            code,
          );
        const retiredUsersTypesConst = data?.filter((type) =>
          isRetiredCategory(type.code),
        );
        setRetiredUserTypes(retiredUsersTypesConst);
        const genericRetiredPerson = {
          name: 'Pensionar',
          code: 'PENSIONAR_GENERIC',
          details: null,
          docTypes: retiredUsersTypesConst[0]?.docTypes,
          id: -1,
          institutions: new Set(
            retiredUsersTypesConst.flatMap(
              (retiredType) => retiredType.institutions,
            ),
          ),
        };
        const returnedUserTypes = data?.filter(
          (type) => !isRetiredCategory(type.code),
        );
        return [...returnedUserTypes, genericRetiredPerson];
      })
      .then((data) => setOptions(data));
  }, []);

  const handleChangeUserType = useCallback(
    (value) => {
      handleUserTypeChange(options.filter((opt) => opt.id === value));
    },
    [handleUserTypeChange, options],
  );

  return (
    <FormWrapper.FieldWrapper label={t('entity.orders.userTypes')}>
      <Select
        value={userTypeId}
        onSelect={handleChangeUserType}
        onChange={(val) => setValue('products[0].persons[0].userTypeId', val)}
        allowClear={false}
        disabled={!isNew && disabled}
      >
        {options
          .map((o) => ({
            id: o.id,
            name: o.name,
            hasOrganizations: o.institutions.length > 0,
            code: o.code,
          }))
          .map((opt) => (
            <Select.Option key={opt.id} value={opt.id}>
              {t(`entity.orders.userType.${opt.code}`)}
            </Select.Option>
          ))}
      </Select>
    </FormWrapper.FieldWrapper>
  );
}

export default UserTypeSelector;
