import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Tooltip,
} from 'antd';
import {
  UploadOutlined,
  RollbackOutlined,
  FileTextOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import moment from 'moment/moment';
import Uploader from '../Uploader';
import {
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
  downloadFiles,
} from '../../services/files';
import { postFiles } from '../../helpers/api';
import DatePickerLocale from '../DatePickerLocale';

const ProductModalDocUpload = ({ props }) => {
  const { t } = useTranslation();
  const [uploadForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    dt,
    orderId,
    disabled,
    name,
    setValue,
    setFormValue,
    getValue,
    currentDoc,
    docRequired,
  } = props;

  useEffect(() => {
    uploadForm.setFieldsValue({
      uploadFile: {
        expirationDate: currentDoc
          ? currentDoc.expirationDate && moment(currentDoc.expirationDate)
          : dt.expirationDate && moment(dt.expirationDate),
      },
    });
  }, [uploadForm, dt, currentDoc]);

  const [file, setFile] = useState(null);
  const [requiredFileUploaded, setRequiredFileUploaded] = useState(false);

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment();
  };

  return (
    <Space>
      {docRequired ? (
        <Button
          type={
            requiredFileUploaded || currentDoc != null ? 'primary' : 'danger'
          }
          htmlType="button"
          disabled={disabled}
          onClick={() => setIsModalVisible(true)}
          icon={<UploadOutlined />}
        />
      ) : (
        <Button
          htmlType="button"
          disabled={disabled}
          onClick={() => setIsModalVisible(true)}
          icon={<UploadOutlined />}
        />
      )}

      <Modal
        title={dt.name}
        visible={isModalVisible}
        closable={false}
        width={400}
        footer={
          <Space>
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="button"
              icon={<CheckOutlined />}
              onClick={() =>
                uploadForm
                  .validateFields()
                  .then(() => {
                    setIsModalVisible(false);
                    const { expirationDate } =
                      uploadForm.getFieldValue('uploadFile');
                    const files = [];
                    files.push(file);
                    postFiles(
                      `${FILE_SERVICE_URI}${FILE_BASE_URI}/${orderId}/files`,
                      files,
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        setValue(name, res, expirationDate);
                        setFormValue('docMap', {
                          ...getValue('docMap'),
                          [res[0].id]: file,
                        });
                      })
                      .then(() => {
                        setRequiredFileUploaded(true);
                      })
                      .catch((msg) => {
                        const { inner } = msg || {};
                        const { _: messageCode } = inner || {};

                        notification.error({
                          message: messageCode
                            ? t(messageCode, inner)
                            : t('errors.fileSizeLimitExceeded'),
                        });
                      });
                  })
                  .catch(() =>
                    notification.error({
                      message:
                        'Datele introduse nu corespund criteriilor de validare',
                    }),
                  )
              }
            >
              {t('actions.accept')}
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={uploadForm} layout="vertical">
          <Form.Item
            name={['uploadFile', 'expirationDate']}
            rules={[
              {
                required: true,
                message: t('errors.selectDate'),
              },
            ]}
          >
            <DatePickerLocale
              format="DD.MM.YYYY HH:mm"
              shouldNotBeDisabled
              disabled={dt.durationType != null && !dt.dateChangeable}
              showTime
              disabledDate={disabledDate}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Row justify="space-around" align="top">
            <Col span={22} push={2}>
              <Form.Item
                name={['uploadFile', 'file']}
                rules={[
                  {
                    required: true,
                    message: t('entity.orders.selectFile'),
                  },
                ]}
              >
                <Uploader
                  props={{
                    names: ['uploadFile', 'file'],
                    currentFile: file,
                    docTypeCode: dt.code,
                    handleFileUploaded: (selectedFile) => {
                      setFile(selectedFile);
                      const formVal = uploadForm.getFieldsValue();
                      uploadForm.setFieldsValue({
                        ...formVal,
                        uploadFile: {
                          file: selectedFile,
                        },
                      });
                    },
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2} pull={20} style={{ top: 10 }}>
              {orderId !== null && orderId !== undefined && (
                <Space style={{ paddingLeft: '1rem' }}>
                  <Tooltip title={t('actions.orders.viewDoc')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<FileTextOutlined />}
                      style={{ color: '#24ad09' }}
                      onClick={() => downloadFiles(currentDoc.fileId, orderId)}
                    />
                  </Tooltip>
                </Space>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </Space>
  );
};
export default ProductModalDocUpload;
