export const UserTypesEnum = {
  ELEV: 'ELEV',
  STUDENT_ORFAN: 'STUDENT_ORFAN',
  STUDENT: 'STUDENT',
  PERSOANE_HANDICAP: 'PERSOANE_HANDICAP',
  PENSIONAR_CONVENTIE: 'PENSIONAR_CONVENTIE',
  PENSIONAR_GENERIC: 'PENSIONAR_GENERIC',
  PENSIONAR: 'PENSIONAR',
  EROI: 'EROI',
  CALATOR: 'CALATOR',
  DONATORI: 'DONATORI',
  COPIL_PANA_IN_6_ANI: 'COPIL_PANA_IN_6_ANI',
  ANGAJAT: 'ANGAJAT',
  ELEV_ORFAN: 'ELEV_ORFAN',
  ELEV_POSTLICEALA: 'ELEV_POSTLICEALA',
  ELEV_FARA_CI: 'ELEV_FARA_CI',
  ELEV_CI: 'ELEV_CI',
};

export const DocTypesEnum = {
  CERTIFICAT_NASTERE: 'CERTIFICAT_NASTERE',
  CARNET_STUDENT: 'CARNET_STUDENT',
  CARNET_ELEV: 'CARNET_ELEV',
  ADEVERINTA_DONATOR_SANGE: 'ADEVERINTA_DONATOR_SANGE',
  CUPON_PENSIE: 'CUPON_PENSIE',
  CERTIFICAT_DECES_PARINTE: 'CERTIFICAT_DECES_PARINTE',
  ADEVERINTA_PERS_DIZABILITATI: 'ADEVERINTA_PERS_DIZABILITATI',
  EROI_LEGITIMATIE_BENEF_LEGI_SPECIALE: 'EROI_LEGITIMATIE_BENEF_LEGI_SPECIALE',
  ADEVERINTA_ELEV_ORFAN: 'ADEVERINTA_ELEV_ORFAN',
  AD_CARNET_STUDENT_VIZAT: 'AD_CARNET_STUDENT_VIZAT',
  CI: 'CI',
  OP: 'OP',
  FACTURA_FISCALA: 'FACTURA_FISCALA',
  FACTURA_PROFORMA: 'FACTURA_PROFORMA',
};

export const ShowColumns = {
  SUB_INSTITUTION_ID: 'SUB_INSTITUTION_ID',
  META_DATA_1: 'META_DATA_1',
};

export const PosTypesEnum = {
  POS: 'POS',
  TERMINAL: 'TERMINAL',
  OPERATOR: 'OPERATOR',
  PORTAL: 'PORTAL',
  PORTAL_PJ: 'PORTAL_PJ',
  MOBILE: 'MOBILE',
  ENROLLMENT: 'ENROLLMENT',
  TVM_EXTERN: 'TVM_EXTERN',
};
