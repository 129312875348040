import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { Col, notification, PageHeader, Row, Space, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import useForm, { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import CancelButton from '../buttons/CancelButton';
import { BULK_TYPES, ORDER_STATUS } from '../../pages/orders/constants';
import OrderFileList from './OrderFileList';
import Products from './Products';
import { useRouteParams } from '../../hooks/useRouteParams';
import IssuingOrderButtons from './IssuingOrderButtons';
import CustomSaveButton from '../buttons/CustomSaveButton';
import { getUserAccounts } from '../../services/accounts';
import TotalPrice from './TotalPrice';

const EditOrderForm = ({ order, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const status = new URLSearchParams(search).get('status');
    // eslint-disable-next-line no-unused-expressions
    status &&
      notification[status]({
        message:
          status === 'success'
            ? 'Achitarea a avut loc cu succes'
            : 'Achitarea a esuat',
      });
  }, [search]);

  const formRef = useRef(0);

  const schema = useMemo(
    () =>
      yup
        .object()
        .shape({
          bulkType: yup.string().required(t('errors.mustSelected')),
          commandType: yup.string().required(t('errors.mustSelected')),
          products: yup.array().of(
            yup.object().shape({
              productId: yup
                .number()
                .nullable()
                .when('$bulkType', {
                  is: (value) =>
                    [
                      BULK_TYPES.ISSUING_CARDS,
                      BULK_TYPES.RECHARGE_CARDS,
                    ].includes(value),
                  then: yup.number().required(t('errors.notEmpty')),
                }),
              count: yup.number().nullable(),
              productPersons: yup.array().of(
                yup.object().shape({
                  person: yup.object().shape({
                    fullname: yup
                      .string()
                      .nullable()
                      .when('$bulkType', {
                        is: BULK_TYPES.ISSUING_CARDS,
                        then: yup.string().required(t('errors.notEmpty')),
                      }),
                    email: yup
                      .string()
                      .email(t('errors.incorrectField'))
                      .nullable(),
                    identityCard: yup
                      .string()
                      .nullable()
                      .when('$bulkType', {
                        is: BULK_TYPES.ISSUING_CARDS,
                        then: yup
                          .string()
                          .required(`${t('errors.notEmpty')}\n`)
                          .test(
                            'isNumber',
                            `${t('errors.number')}\n`,
                            (value) => {
                              if (!value || value.trim().length === 0)
                                return false;
                              return !Number.isNaN(+value);
                            },
                          ),
                      }),
                    cardLogicalId: yup
                      .string()
                      .nullable()
                      .when('$bulkType', {
                        is: BULK_TYPES.RECHARGE_CARDS,
                        then: yup
                          .string()
                          .required(`${t('errors.logicalIdNotEmpty')}\n`)
                          .test(
                            'isNumber',
                            `${t('errors.number')}\n`,
                            (value) => {
                              if (!value || value.trim().length === 0)
                                return false;
                              return !Number.isNaN(+value);
                            },
                          ),
                      }),
                    employeeNumber: yup
                      .string()
                      .nullable()
                      .matches(/^(\d+)?$/, t('errors.incorrectField')),
                    phone: yup
                      .string()
                      .nullable()
                      .matches(/^(\+?\d+)?$/, t('errors.incorrectField')),
                    documents: yup.array().of(
                      yup.object().shape({
                        documentTypeId: yup
                          .number()
                          .required(t('errors.mustSelected')),
                        fileId: yup.number().required(t('errors.mustSelected')),
                      }),
                    ),
                  }),
                }),
              ),
            }),
          ),
        })
        .test('noPersonAdded', t('errors.noPersonAdded'), (o) => {
          return (
            o?.products.find((p) => p.productPersons.length === 0) === undefined
          );
        }),
    [t],
  );

  const form = useForm({
    initialValue: order,
    schema,
    onSubmit,
    onCancel,
  });

  const {
    handleSubmit,
    setFormValue,
    custom,
    select,
    reload,
    value,
    setValue,
    input,
  } = form;
  const { status, bulkType, id, products } = value;

  useEffect(() => {
    getUserAccounts()
      .then((data) => data.filter((a) => a.customerType === 'LEGAL'))
      .then((res) => setValue('userId', res[0]?.id));
  }, [setValue]);

  useEffect(() => {
    setFormValue(order);
  }, [setFormValue, order]);

  const handleChangeBulkType = useCallback(
    (currentBulkType) => {
      setValue('bulkType', currentBulkType);
      setValue('products[0].productPersons', []);
      setValue('products[0].key', uuidv4());
    },
    [setValue],
  );

  return (
    <FormContext.Provider value={form}>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/home')}
        title={t('actions.backToHomePage')}
      >
        <form
          onSubmit={handleSubmit}
          ref={(r) => {
            formRef.current = r;
          }}
        >
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Select
                label={t('entity.orders.bulkTypes')}
                props={{
                  disabled: !isNew,
                  options: Object.keys(BULK_TYPES).map((o) => ({ id: o })),
                  allowClear: false,
                  labelProp: (obj) => t(`entity.orders.bulkType.${obj.id}`),
                  ...select('bulkType'),
                  onChange: handleChangeBulkType,
                }}
              />
              <FormWrapper.Input
                label={t('entity.orders.status.title')}
                props={{
                  disabled: true,
                  value: t(`entity.orders.status.${status}`),
                }}
              />
              {[ORDER_STATUS.REJECTED].includes(status) && (
                <FormWrapper.Input
                  label={t('entity.orders.cancelReason')}
                  props={{ ...input('cancelReason'), disabled: true }}
                />
              )}
              {products[0]?.startDate != null && (
                <FormWrapper.DateTimePicker
                  label={t('entity.orders.subscriptionStartDate')}
                  props={{ ...custom('products[0].startDate'), disabled: true }}
                />
              )}
            </FormWrapper.Single>

            <FormWrapper.Double>
              <Tabs>
                <Tabs.TabPane
                  tab={t('entity.admin.user.tabs.files.uploadDocuments')}
                  key="files"
                >
                  <OrderFileList
                    props={{
                      ...custom('attachments'),
                      select,
                      custom,
                      id,
                      status,
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>

          {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
            bulkType,
          ) && (
            <Row>
              <Col span={24}>
                <Products status={status} />
              </Col>
            </Row>
          )}
          {/* {bulkType === BULK_TYPES.DISABLE_CARDS && (
              <Row>
                <Col span={24}>
                  <CardsList />
                </Col>
              </Row>
            )} */}

          {[
            ORDER_STATUS.PAYMENT,
            ORDER_STATUS.PAID,
            ORDER_STATUS.OP_VALIDATION,
          ].includes(status) && <TotalPrice />}
          <Space>
            <CancelButton onCancel={onCancel} />
            {[ORDER_STATUS.SELECT_PRODUCTS].includes(status) && (
              <CustomSaveButton form={form} formRef={formRef} />
            )}
            {[BULK_TYPES.ISSUING_CARDS, BULK_TYPES.RECHARGE_CARDS].includes(
              bulkType,
            ) && (
              <IssuingOrderButtons
                form={form}
                formRef={formRef}
                reload={reload}
              />
            )}
            {/* {bulkType === BULK_TYPES.DISABLE_CARDS && (
            <DisablingOrderButtons form={form} formRef={formRef} />
          )} */}
          </Space>
        </form>
      </PageHeader>
    </FormContext.Provider>
  );
};

export default EditOrderForm;
