import React from 'react';
import AccountsForm from '../components/accounts/AccountsForm';
import { Default } from '../layouts';

const Home = () => (
  <Default>
    <AccountsForm />
  </Default>
);

export default Home;
