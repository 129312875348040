import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import FormItem from 'antd/lib/form/FormItem';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { SaveOutlined } from '@ant-design/icons';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { useRouteParams } from '../../hooks/useRouteParams';
import { Default } from '../../layouts';

import AuthContext from '../../components/auth';
import { downloadFilesById, downloadInvoiceById } from '../../services/files';
import { getUserDetails, validateCui } from '../../services/users';
import useDictionaries from '../../hooks/useDictionaries';
import { getCountries } from '../../services/taxonomies';
import {
  generateIndividualInvoice,
  generateLegalInvoice,
  getAccountTransactionsById,
} from '../../services/accounts';
import { ERP_INVOICE_STATUSES } from '../orders/constants';
import { getOrderInvoiceByOrderId } from '../../services/orders';

const EvenTypes = {
  content: [{ id: 'ADD_NEW' }, { id: 'RECHARGE' }],
  loading: false,
  error: false,
};

const Sources = {
  content: [{ id: 'POS' }, { id: 'PORTAL' }, { id: 'BULK' }],
  loading: false,
  error: false,
};
const dictionaries = {
  countries: () =>
    getCountries({
      pageSize: 200,
      sort: ['name', 'asc'],
      criterias: { deleted: 'false' },
    }),
};

const ViewAccountTransactions = () => {
  const { id } = useRouteParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [showLegalModal, setShowLegalModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [activeKeys, setActiveKey] = useState(['address2']);
  const [vatEntity, setVatEntity] = useState(null);
  const [identityCard, setIdentityCard] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [{ countries }] = useDictionaries(dictionaries);
  const { Text } = Typography;
  const [isInvoiceEnabledString] = useState(window._env_.ENABLE_ERP);
  const isInvoiceEnabled = isInvoiceEnabledString === 'true';

  const fetcher = useCallback(
    (arg) => getAccountTransactionsById(id, arg),
    [id],
  );
  const { content, loading, handleChange, reload } = useDatasource(fetcher);

  const [form] = Form.useForm();

  const isDescendent = useMemo(() => {
    return location?.state?.isDescendent;
  }, [location.state]);

  const fromCustomerType = useMemo(() => {
    return location?.state?.fromCustomerType;
  }, [location.state]);

  const countryOptions = useMemo(
    () =>
      (countries?.content || []).map((ety) => ({
        ...ety,
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries],
  );
  const { setFieldsValue, resetFields } = form;

  const districtOptions = useMemo(
    () =>
      (
        (countries?.content || []).find((c) => c.id === country)?.districts ||
        []
      ).map((ety) => ({
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries?.content, country],
  );

  const columns = useMemo(
    () => [
      Column.dictionary(
        'eventType',
        t('entity.account.transactions.eventType._'),
        EvenTypes,
        {
          filter: false,
          width: 150,
          dictLabel: (entry) => {
            return t(`entity.account.transactions.eventType.${entry.id}`);
          },
        },
      ),
      Column.dictionary(
        'saleChanel',
        t('entity.account.transactions.source._'),
        Sources,
        {
          filter: false,
          width: 150,
          dictLabel: (entry) => {
            return t(`entity.account.transactions.source.${entry.id}`);
          },
        },
      ),
      Column.date('createdAt', t('entity.orders.createdAt'), {
        width: 250,
      }),
      Column.text(
        'parentProdName',
        t('entity.account.transactions.productName'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.text('parentProdQty', t('entity.account.transactions.qty'), {
        filter: false,
        sort: false,
      }),
      {
        title: `${t('entity.account.transactions.price')} (RON)`,
        key: 'id',
        width: 100,
        sorter: false,
        render: (v) => {
          const { parentProdPrice } = v;
          return (parentProdPrice / 100).toFixed(2);
        },
      },
      {
        title: `${t('entity.account.transactions.total')} (RON)`,
        key: 'id',
        width: 100,
        sorter: false,
        render: (v) => {
          const { parentProdQty, parentProdPrice } = v;
          return ((parentProdQty * parentProdPrice) / 100).toFixed(2);
        },
      },
      //   FIXME change this after fiscal bill si solved
      ...(user.customerType === 'INDIVIDUAL'
        ? [
            {
              title: '',
              width: 390,
              sorter: false,
              render: (transaction) => {
                const {
                  parentProdPrice,
                  saleChanel,
                  customerType,
                  erpInvoiceStatus,
                } = transaction;
                return (
                  isInvoiceEnabled && (
                    <span
                      style={{
                        textAlign: 'center',
                        display: 'inline',
                      }}
                    >
                      {parentProdPrice > 0 &&
                        (saleChanel === 'PORTAL' ||
                          (saleChanel === 'BULK' &&
                            customerType === 'INDIVIDUAL')) &&
                        ERP_INVOICE_STATUSES.CANCELED !== erpInvoiceStatus &&
                        !transaction.invoiceId && (
                          <>
                            {(transaction.invoiceType === 'PF' ||
                              transaction.invoiceType === 'PFI') && (
                              <Popconfirm
                                key="generateIndividualInvoice"
                                placement="topLeft"
                                title={t(
                                  'entity.orders.generateIndividualInvoiceQuestion',
                                )}
                                okText={t('actions.confirm')}
                                cancelText={t('actions.cancel')}
                                okButtonProps={{
                                  htmlType: 'submit',
                                  type: 'primary',
                                }}
                                onConfirm={() => {
                                  getUserDetails().then((result) => {
                                    if (
                                      result.address2 &&
                                      result.address2.countryId &&
                                      (result.address2.districtId ||
                                        result.address2.district) &&
                                      result.address2.locality
                                    ) {
                                      setLoadingInvoice(true);
                                      generateIndividualInvoice(
                                        transaction.id,
                                        transaction.invoiceType,
                                      )
                                        .then(() => {
                                          reload();
                                          setLoadingInvoice(false);
                                        })
                                        .catch((msg) => {
                                          console.error(msg);
                                          const { inner } = msg || {};
                                          const { _: messageCode } =
                                            inner || {};

                                          notification.error({
                                            message: messageCode
                                              ? t(messageCode, inner)
                                              : t('actions.errorOccurred'),
                                          });
                                          setLoadingInvoice(false);
                                        });
                                    } else {
                                      message.error({
                                        content: (
                                          <>
                                            <p>{t('errors.requiredAddress')}</p>
                                            <a href="/profile">
                                              {t('entity.users.userProfile')}
                                            </a>
                                          </>
                                        ),
                                        duration: 5,
                                        className: 'card-message',
                                      });
                                    }
                                  });
                                }}
                              >
                                <Tooltip
                                  title={t(
                                    'entity.orders.generateIndividualInvoiceInfo',
                                  )}
                                >
                                  <Button
                                    style={{
                                      display: 'inline',
                                      textAlign: 'center',
                                      marginRight: '2%',
                                    }}
                                    className="ant-btn-warning"
                                    type="primary"
                                    disabled={loadingInvoice}
                                    htmlType="button"
                                  >
                                    {transaction.invoiceType === 'PF'
                                      ? t(
                                          'entity.stocks.invoice.individualInvoice.pf',
                                        )
                                      : t(
                                          'entity.stocks.invoice.individualInvoice.pfi',
                                        )}
                                  </Button>
                                </Tooltip>
                              </Popconfirm>
                            )}
                            {transaction.invoiceType === 'PJ' && (
                              <Popconfirm
                                key="generateIndividualInvoice"
                                placement="topLeft"
                                title={t(
                                  'entity.orders.generateIndividualInvoiceQuestion',
                                )}
                                okText={t('actions.confirm')}
                                cancelText={t('actions.cancel')}
                                okButtonProps={{
                                  htmlType: 'submit',
                                  type: 'primary',
                                }}
                                onConfirm={() => {
                                  setTransactionId(transaction.id);
                                  getOrderInvoiceByOrderId(
                                    transaction.orderId,
                                    transaction.saleChanel,
                                  ).then((orderInvoice) => {
                                    if (
                                      orderInvoice &&
                                      orderInvoice.identityCard &&
                                      orderInvoice.countryId &&
                                      (orderInvoice.districtId ||
                                        orderInvoice.district) &&
                                      orderInvoice.locality
                                    ) {
                                      setLoadingInvoice(true);
                                      generateLegalInvoice(
                                        transaction.id,
                                        orderInvoice,
                                      )
                                        .then(() => {
                                          reload();
                                          setLoadingInvoice(false);
                                        })
                                        .catch(() => setLoadingInvoice(false));
                                    } else {
                                      setTransactionId(transaction.id);
                                      setShowLegalModal(true);
                                    }
                                  });
                                }}
                              >
                                <Tooltip
                                  title={t(
                                    'entity.orders.generateIndividualInvoiceInfo',
                                  )}
                                >
                                  <Button
                                    style={{
                                      display: 'inline',
                                      textAlign: 'center',
                                      marginRight: '2%',
                                    }}
                                    className="ant-btn-warning"
                                    type="primary"
                                    disabled={loadingInvoice}
                                    htmlType="button"
                                  >
                                    {t('entity.stocks.invoice.legalInvoice')}
                                  </Button>
                                </Tooltip>
                              </Popconfirm>
                            )}
                          </>
                        )}
                      {transaction.invoiceId && (
                        <Button
                          style={{
                            display: 'inline',
                            textAlign: 'center',
                            marginRight: '2%',
                          }}
                          className="ant-btn-warning"
                          type="primary"
                          htmlType="button"
                          onClick={() => {
                            if (transaction?.fileId) {
                              downloadFilesById(transaction?.fileId).then(() =>
                                reload(),
                              );
                            } else {
                              downloadInvoiceById(
                                transaction?.invoiceId,
                                transaction?.id,
                              )
                                .then(() => reload())
                                .catch((msg) => {
                                  console.error(msg);
                                  const { inner } = msg || {};
                                  const { _: messageCode } = inner || {};

                                  notification.error({
                                    message: messageCode
                                      ? t(messageCode, inner)
                                      : t('errors.errorOccurred'),
                                  });
                                });
                            }
                          }}
                        >
                          {t('entity.stocks.invoice.downloadInvoice')}
                        </Button>
                      )}
                    </span>
                  )
                );
              },
            },
          ]
        : []),
    ],
    [t, user.customerType, isInvoiceEnabled, loadingInvoice, reload],
  );

  const validateIdentityCard = useCallback(() => {
    const controlNumber = identityCard % 10;
    let cif = Math.floor(identityCard / 10);
    let key = 753217532;
    let sum = 0;
    while (key > 0) {
      sum += (key % 10) * (cif % 10);
      cif = Math.floor(cif / 10);
      key = Math.floor(key / 10);
    }
    const currentControlNumber = ((sum * 10) % 11) % 10;
    return currentControlNumber === controlNumber;
  }, [identityCard]);

  const validate = useCallback(() => {
    if (identityCard) {
      if (
        (countryCode === 'RO' || countryCode === '') &&
        !validateIdentityCard()
      ) {
        message.error({
          content: t('errors.invalidIdentityCard'),
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
        setVatEntity(null);
        resetFields();
        return;
      }
      if (countryCode !== '') {
        validateCui(countryCode, identityCard)
          .then((resp) => {
            const { isValid, companyName } = resp;
            console.log(isValid, companyName, countryCode);
            if (isValid) {
              setFieldsValue({ fullname: companyName });
              setVatEntity(resp);
            } else {
              notification.error({
                message: t(`errors.connectivityIssues`),
              });
            }
          })
          .catch((err) => {
            setVatEntity(null);
            resetFields();
            if (err?.inner) {
              if (err?.inner?.status === 400) {
                notification.error({
                  message: t(`errors.identificationCodeNotFound`),
                });
              }
            } else {
              err
                .json(err)
                .then((res) => notification.error({ message: res?.message }));
            }
          });
      } else {
        setVatEntity({ isValid: false });
      }
    }
  }, [
    identityCard,
    countryCode,
    validateIdentityCard,
    t,
    setFieldsValue,
    resetFields,
  ]);

  return (
    <Default>
      <>
        <PageHeader
          onBack={() =>
            history.push({
              pathname: `/accounts/${id}`,
              state: {
                isDescendent,
                fromCustomerType,
              },
            })
          }
          title={t('entity.account.transactions.accountDetails')}
        />
        <Divider style={{ fontWeight: 500, fontSize: '1.5rem' }}>
          {t('entity.account.transactions._')}
        </Divider>

        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          onChange={handleChange}
          dataSource={content}
          pagination={false}
        />
        <Modal
          title={t('entity.stocks.invoice.enterCui')}
          visible={showLegalModal}
          width={500}
          closable={false}
          footer={[
            <Popconfirm
              key="generateInvoice"
              placement="topLeft"
              title={t('entity.orders.generateInvoiceInfo')}
              okText={t('actions.confirm')}
              cancelText={t('actions.cancel')}
              okButtonProps={{ htmlType: 'submit', type: 'primary' }}
              onConfirm={() => {
                form.validateFields().then((r) => {
                  setLoadingInvoice(true);
                  generateLegalInvoice(transactionId, r)
                    .then(() => {
                      reload();
                      setLoadingInvoice(false);
                    })
                    .catch((msg) => {
                      setLoadingInvoice(false);

                      console.error(msg);
                      const { inner } = msg || {};
                      const { _: messageCode } = inner || {};

                      notification.error({
                        message: messageCode
                          ? t(messageCode, inner)
                          : t('errors.errorOccurred'),
                      });
                    });
                  setVatEntity(null);
                  resetFields();
                  setShowLegalModal(false);
                });
              }}
            >
              <Tooltip title={t('entity.orders.generateInvoiceInfo')}>
                <Button
                  className="ant-btn-success"
                  type="primary"
                  icon={<SaveOutlined />}
                  disabled={loadingInvoice}
                  hidden={vatEntity == null}
                >
                  OK
                </Button>
              </Tooltip>
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setShowLegalModal(false);
                setVatEntity(null);
                resetFields();
              }}
            >
              {t(`actions.close`)}
            </Button>,
          ]}
        >
          {showLegalModal && (
            <Form form={form}>
              <Row>
                <Col span={7}>
                  <FormItem name="countryCode">
                    <Select
                      onChange={(val) => {
                        setVatEntity(null);
                        setCountryCode(val);
                      }}
                    >
                      {[{ code: 'Neplatitor TVA' }, ...countryOptions].map(
                        ({ code }) => (
                          <Select.Option
                            key={code}
                            value={code !== 'Neplatitor TVA' ? code : ''}
                          >
                            {code}
                          </Select.Option>
                        ),
                      )}{' '}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={11}>
                  <FormItem
                    name="identityCard"
                    rules={[
                      {
                        required: true,
                        message: t('errors.requiredField'),
                      },
                      {
                        type: 'number',
                        min: 1,
                        message: t('errors.invalidField'),
                      },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      type="number"
                      onChange={(v) => {
                        setIdentityCard(v);
                        setFieldsValue({ identityCard: v });
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Button
                    style={{
                      width: '100%',
                    }}
                    type="primary"
                    onClick={validate}
                  >
                    {t('actions.validate')}
                  </Button>
                </Col>
              </Row>

              {vatEntity && (
                <>
                  {
                    (vatEntity.isValid && (
                      <Row>
                        <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                          {vatEntity.companyName}
                        </Text>
                      </Row>
                    ),
                    (
                      <Row>
                        <Text type={vatEntity.isValid ? 'success' : 'danger'}>
                          {vatEntity.companyAddress}
                        </Text>
                      </Row>
                    ))
                  }
                  <Form.Item
                    name="fullname"
                    label={t('entity.users.fullName')}
                    rules={[
                      {
                        required: true,
                        message: t('errors.notEmpty'),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setFieldsValue({
                          fullname: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Item>
                  <Collapse
                    activeKey={activeKeys}
                    onChange={() =>
                      setActiveKey(activeKeys ? null : 'address2')
                    }
                  >
                    <CollapsePanel key="address2">
                      <Form.Item
                        name="countryId"
                        label={t('entity.users.address.country')}
                        rules={[
                          {
                            required: true,
                            message: t('errors.mustSelected'),
                          },
                        ]}
                      >
                        <Select
                          options={countryOptions}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(v1) => {
                            setCountry(v1);
                            const value = countries?.content?.filter(
                              (c) => c.id === country,
                            )[0]?.code;
                            if (value === 'RO') {
                              form.setFieldsValue({
                                district: null,
                              });
                            } else {
                              form.setFieldsValue({
                                districtId: null,
                              });
                            }
                          }}
                        >
                          {countryOptions.map((opt) => (
                            <Select.Option key={opt.id} value={opt.id}>
                              {opt.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {countries?.content?.filter((c) => c.id === country)[0]
                        ?.code === 'RO' ? (
                        <Form.Item
                          name="districtId"
                          label={t('entity.users.address.districtId')}
                          rules={[
                            {
                              required: true,
                              message: t('errors.mustSelected'),
                            },
                          ]}
                        >
                          <Select
                            options={districtOptions}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="district"
                          label={t('entity.users.address.district')}
                          rules={[
                            {
                              required: true,
                              message: t('errors.mustSelected'),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                      <Form.Item
                        name="locality"
                        label={t('entity.users.address.locality')}
                        rules={[
                          {
                            required: true,
                            message: t('errors.notEmpty'),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="street"
                        label={t('entity.users.address.street')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="number"
                        label={t('entity.users.address.number')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="building"
                        label={t('entity.users.address.building')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="apartment"
                        label={t('entity.users.address.apartment')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="details"
                        label={t('entity.users.address.details')}
                      >
                        <Input />
                      </Form.Item>
                    </CollapsePanel>
                  </Collapse>
                </>
              )}
            </Form>
          )}
        </Modal>
      </>
    </Default>
  );
};

export default ViewAccountTransactions;
