import React, { useMemo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { AUTH_BASE_URI, AUTH_SERVICE_URI } from '../../services/auth';

const LoginSocialForm = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const { email, username, channel } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      email: params.get('email') || null,
      username: params.get('username') || null,
      channel: params.get('channel') || null,
    };
  }, [search]);

  return (
    <form
      method="POST"
      action={`${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/oauth/social/${channel}`}
      encType="application/x-www-form-urlencoded"
    >
      <Input
        type="hidden"
        name="client_id"
        value={window._env_.API_TENANT_KEY}
      />
      <Input
        type="hidden"
        name="callback_url"
        value={`${window._env_.API_BACKEND_URL}/portal/auth/callback?client_id=${window._env_.API_TENANT_KEY}&back_url=${window.location.origin}`}
      />
      <Input
        placeholder="Nume Prenume"
        value={username}
        name="fullName"
        disabled
        required
      />
      <br />
      <br />
      <Input
        placeholder="Posta electronica"
        value={email}
        name="username"
        disabled
      />
      <br />
      <br />
      <Input placeholder="CNP" name="identityCard" />
      <br />
      <br />
      <ReCAPTCHA
        name="recaptcha"
        sitekey={window._env_.RECAPTCHA_SITE_KEY}
        hl="ro"
      />
      <div>
        <p /> Prin crearea contului sunt de acord cu{' '}
        <a href="https://tursib.ro/page/nota-info-gdpr">
          Termenii si Conditiile privind prelucrarea datelor cu caracter
          personal
        </a>
      </div>
      <Button type="primary" block htmlType="submit">
        {t('startPage.signUp')}
      </Button>
    </form>
  );
};

export default LoginSocialForm;
