import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, message, Tooltip, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { postFiles } from '../helpers/api';

import {
  BASE_URI as FILE_BASE_URI,
  SERVICE_URI as FILE_SERVICE_URI,
} from '../services/files';
import './FileUploader.scss';

const FileUploader = ({
  uploadUrl = `${FILE_SERVICE_URI}${FILE_BASE_URI}`,
  text = '',
  multiple = true,
  dragger = true,
  tooltip,
  accept,
  disabled,
  onUploadSuccess = () => null,
  onUploadFailed = () => null,
  type,
}) => {
  const [files, setFiles] = useState({});
  const { t } = useTranslation();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const convertBytesToMB = (bytes) => bytes / (1024 * 1024);
    const maxSize = process.env.MAX_FILE_SIZE || 5;
    const invalidFilesForUpload = [];

    Object.keys(files).forEach((uid) => {
      const { file } = files[uid];
      if (!files[uid].uploaded && convertBytesToMB(file.size) > maxSize) {
        invalidFilesForUpload.push(uid);
        message.error({
          content: t('errors.maxFileSizeReached', {
            fileName: file.name,
            maxSize,
          }),
          duration: 2.5,
          className: 'card-message',
        });
      }
    });

    if (invalidFilesForUpload.length > 0) {
      setFiles((prevFiles) => {
        const newFiles = { ...prevFiles };
        invalidFilesForUpload.forEach((uid) => {
          delete newFiles[uid];
        });
        return newFiles;
      });
      return;
    }

    const validFilesForUpload = Object.keys(files)
      .filter((uid) => !files[uid].uploaded)
      .map((uid) => files[uid].file);

    if (validFilesForUpload.length > 0) {
      setFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        validFilesForUpload.forEach((file) => {
          const uid = Object.keys(files).find(
            (key) => files[key].file === file,
          );
          updatedFiles[uid] = { ...files[uid], uploaded: true };
        });
        return updatedFiles;
      });

      postFiles(uploadUrl, validFilesForUpload)
        .then((res) => res.json())
        .then((res) => {
          onUploadSuccess(res, validFilesForUpload[0]);
        })
        .catch(onUploadFailed);
    }
  }, [files, t, onUploadFailed, onUploadSuccess, uploadUrl]);

  const props = useMemo(
    () => ({
      multiple,
      disabled,
      accept,
      showUploadList: false,
      customRequest: () => null,
      beforeUpload: (...[, filesBeforeUpload]) =>
        setFiles((existingFiles) => ({
          ...existingFiles,
          ...filesBeforeUpload.reduce(
            (acc, cur) => ({
              ...acc,
              ...(cur.uid && !(cur.uid in existingFiles)
                ? { [cur.uid]: { file: cur, uploaded: false } }
                : null),
            }),
            {},
          ),
        })),
    }),
    [accept, disabled, multiple],
  );

  return dragger ? (
    <div style={{ height: '80px' }}>
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">{text}</p>
      </Upload.Dragger>
    </div>
  ) : (
    <Tooltip title={tooltip}>
      <Upload {...props}>
        <Button type={type} icon={<UploadOutlined />}>
          {text}
        </Button>
      </Upload>
    </Tooltip>
  );
};

export default FileUploader;
