import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageHeader, Divider, Button } from 'antd';

import { Default } from '../../layouts';
import { getAccountById } from '../../services/accounts';
import { useRouteParams } from '../../hooks/useRouteParams';
import TransportTitles from '../../components/accounts/TransportTitlesList';
import AccountDescendantsList from '../../components/accounts/AccountDescendantsList';
import { CUSTOMER_TYPE } from '../orders/constants';
import { getAccountType } from '../../helpers/util';

const ViewAccount = () => {
  const { id } = useRouteParams();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const [enablePFDescendentsString] = useState(
    window._env_.ENABLE_PF_DESCENDENTS,
  );
  const enablePFDescendents = enablePFDescendentsString === 'true';
  const [account, setAccount] = useState(null);
  const cardBlocked = useMemo(() => {
    return location?.state?.enabled || false;
  }, [location.state]);

  const isDescendent = useMemo(() => {
    return location?.state?.isDescendent;
  }, [location.state]);

  const fromCustomerType = useMemo(() => {
    return location?.state?.fromCustomerType;
  }, [location.state]);

  useEffect(() => {
    getAccountById(id).then(setAccount);
  }, [id]);

  return (
    <Default>
      {account && (
        <>
          <PageHeader
            className="site-page-header"
            onBack={() =>
              !isDescendent
                ? history.push('/home')
                : history.push(`/accounts/${account?.parentAccountId}`)
            }
            title={t('navigation.admin.accounts')}
            subTitle={`${getAccountType(account.customerType, t)} - ${
              account?.userName
            }`}
            extra={
              <>
                {isDescendent && (
                  <Button
                    onClick={() => history.push({ pathname: `/profile/${id}` })}
                  >
                    {t('entity.users.userProfile')}
                  </Button>
                )}
                <Button
                  onClick={() =>
                    history.push({
                      pathname: `/accounts/${id}/transactions`,
                      state: {
                        isDescendent,
                        fromCustomerType,
                      },
                    })
                  }
                >
                  {t('navigation.admin.transactions')}
                </Button>
                {CUSTOMER_TYPE.LEGAL === account.customerType && (
                  <Button
                    key="1"
                    onClick={() =>
                      history.push(`/accounts/${id}/abonoments-descendants`)
                    }
                  >
                    {t('navigation.admin.abonomentsDescendants')}
                  </Button>
                )}
              </>
            }
          />

          {CUSTOMER_TYPE.LEGAL !== account.customerType && (
            <TransportTitles
              id={account.id}
              cardBlocked={cardBlocked}
              isDescendent={isDescendent}
              canAddTitles={
                CUSTOMER_TYPE.INDIVIDUAL === fromCustomerType &&
                enablePFDescendents
              }
              fromCustomerType={fromCustomerType}
            />
          )}
          {((CUSTOMER_TYPE.INDIVIDUAL === account.customerType &&
            enablePFDescendents) ||
            CUSTOMER_TYPE.LEGAL === account.customerType) &&
            !isDescendent && (
              <>
                <Divider style={{ fontWeight: 500, fontSize: '1.5rem' }}>
                  {t('entity.account.descendants._')}
                </Divider>
                <AccountDescendantsList
                  id={account.id}
                  customerType={account.customerType}
                />
              </>
            )}
        </>
      )}
    </Default>
  );
};

export default ViewAccount;
