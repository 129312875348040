import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUserDetails } from '../../services/auth';
import { getUserDetails } from '../../services/users';
import AppLoader from './AppLoader';

const AuthContext = React.createContext(null);

export const AuthContextWrapper = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setLoaded(false);
    getCurrentUserDetails()
      .then((res) => {
        setUser(res);
        getUserDetails().then(({ customerType }) =>
          setUser({ ...res, customerType }),
        );
      })
      .catch((err) => {
        console.log(err);
        setUser(null);
      })
      .finally(() => setLoaded(true));
  }, []);

  const contextValue = useMemo(() => ({ user }), [user]);

  return (
    <AuthContext.Provider value={contextValue}>
      {loaded ? children : <AppLoader />}
    </AuthContext.Provider>
  );
};

const arrayIsEmpty = (array) =>
  // eslint-disable-next-line no-nested-ternary
  Array.isArray(array)
    ? array.length === 0
    : typeof array === 'string'
    ? array.length === 0
    : true;

export const hasPermission = (userPerm, allowedPerm) => {
  if (arrayIsEmpty(allowedPerm)) {
    return true;
  }

  if (arrayIsEmpty(userPerm)) {
    return false;
  }

  const perms = Array.isArray(allowedPerm) ? allowedPerm : [allowedPerm];

  for (let i = 0; i < perms.length; i += 1) {
    if (userPerm.includes(perms[i])) {
      return true;
    }
  }

  return false;
};

export const IsAuthenticated = ({ children }) => {
  const { user } = useContext(AuthContext);

  return user ? children : <Redirect to="/" />;
};

export const HasPermission = ({ permissions, children }) => {
  if (!Array.isArray(permissions)) {
    throw new Error('Permissions should be an array');
  }

  const { user } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  const allow = hasPermission(user.permissions, permissions);
  return allow ? children : null;
};

export const PrivateRoute = ({
  component: Component,
  permissions,
  ...rest
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        // eslint-disable-next-line no-nested-ternary
        user ? (
          hasPermission(user.permissions, permissions) ? (
            <Component />
          ) : (
            <Redirect to={{ pathname: '/forbidden' }} />
          )
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
};

export default AuthContext;
